@use "foundation" as *;

//ページ内タイトル
.c-contents_ttl {
    text-align: center;
    .mark {
        width: 1.5rem;
    }
    .english {
        transform: rotate(0.05deg);
        display: inline-block;
        color: #65C100;
        font-size: 1.2rem;
        padding: 0 0 0 1rem;
    }
    .txt {
        transform: rotate(0.05deg);
        display: inline-block;
        padding: 1.5rem 0 0;
        font-weight: 500;
        font-size: 3.4rem;
    }
    @include pc {
        .mark {
            width: 1.43vw;
        }
        .english {
            font-size: 1vw;
            padding: 0 0 0 0.71vw;
        }
        .txt {
            padding: 0.71vw 0 0;
            font-size: 3.29vw;
        }
    }
    @include pc_ls {
        .mark {
            width: 20px;
        }
        .english {
            font-size: 14px;
            padding: 0 0 0 10px;
        }
        .txt {
            padding: 10px 0 0;
            font-size: 46px;
        }
    }
}

//グリーンボタン
.c-green_btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 6rem;
    background: #65C100;
    color: #fff !important;
    text-decoration: none;
    border-radius: 50px;
    font-size: 1.4rem;
    transform: rotate(0.05deg);
    &::after {
        content: "";
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 1px;
        border: 2px solid;
        border-color: #fff #fff transparent transparent;
        transform: rotate(45deg);
        position: absolute;
        right: 3rem;
    }

    @include pc {
        width: 25.43vw;
        height: 65px;
        border-radius: 50px;
        font-size: 1.29vw;
        margin: 0 auto;
        transition: 0.3s;
        &::after {
            width: 0.57vw;
            height: 0.57vw;
            right: 2vw;
        }
        &:hover {
            background: #fff;
            border: solid 1px #65C100; 
            color: #65C100 !important;
            &::after {
                border-color: #65C100 #65C100 transparent transparent;
            }
        }
    }
    @include pc_ls {
        width: 356px;
        height: 65px;
        font-size: 18px;
        &::after {
            width: 8px;
            height: 8px;
            right: 25px;
        }
    }
}
//グリーン（深緑）ボタン
.c-d_green_btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 6rem;
    background: #0D7100;
    color: #fff !important;
    text-decoration: none;
    border-radius: 50px;
    font-size: 1.4rem;
    transform: rotate(0.05deg);
    &::after {
        content: "";
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 1px;
        border: 2px solid;
        border-color: #fff #fff transparent transparent;
        transform: rotate(45deg);
        position: absolute;
        right: 3rem;
    }

    @include pc {
        width: 25.43vw;
        height: 65px;
        border-radius: 50px;
        font-size: 1.29vw;
        margin: 0 auto;
        transition: 0.3s;
        &::after {
            width: 0.57vw;
            height: 0.57vw;
            right: 2vw;
        }
        &:hover {
            background: #fff;
            border: solid 1px #0D7100; 
            color: #0D7100 !important;
            &::after {
                border-color: #0D7100 #0D7100 transparent transparent;
            }
        }
    }
    @include pc_ls {
        width: 356px;
        height: 65px;
        font-size: 18px;
        &::after {
            width: 8px;
            height: 8px;
            right: 25px;
        }
    }
}

//ページタイトル（全体）
.c-page__ttl {
    margin: 5rem calc(50% - 50vw) 0;
	//padding: 0 calc(50vw - 50%);
    @include pc {
        margin: 6.36vw calc(50% - 50vw) 0;
    }
    @include pc_ls {
        margin: 89px calc(50% - 50vw) 0;
    }
    &__bg {
        position: relative;
        background: #EEF8E2;
        @include pc {
            background: linear-gradient(0deg,rgba(255,255,255,1) 80%, rgba(238,248,226,1) 80%);
        }
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 6rem;
            background: #EEF8E2;
            border-radius: 0 0 100% 100%;
            position: absolute;
            bottom: -3rem;
            z-index: 0;
            @include pc {
                height: 19.29vw;
                border-radius: 0 0 50% 50%;
                bottom: -7.14vw;
            }
            @include pc_ls {
                height: 270px;
                bottom: -100px;
            }
        }
    }
    &__txt {
        transform: rotate(0.05deg);
        position: relative;
        text-align: center;
        padding: 6rem 2rem 0;
        @include pc {
            padding: 5.71vw 0 0;
        }
        @include pc_ls {
            padding: 80px 0 0;
        }
        .mark {
            width: 1.5rem;
        }
        .english {
            display: inline-block;
            color: #65C100;
            font-size: 1.2rem;
            padding: 0 0 0 1rem;
        }
        .txt {
            display: inline-block;
            padding: 0.5rem 0 0;
            font-weight: 500;
            line-height: 1.5;
            font-size: 3.3rem;
        }
        @include pc {
            .mark {
                width: 1.43vw;
            }
            .english {
                font-size: 1vw;
                padding: 0 0 0 10px;
            }
            .txt {
                padding: 10px 0 0;
                font-size: 3.29vw;
            }
        }
        @include pc_ls {
            .mark {
                width: 20px;
            }
            .english {
                font-size: 14px;
                padding: 0 0 0 10px;
            }
            .txt {
                padding: 10px 0 0;
                font-size: 46px;
            }
        }
    }
}

//ページタイトル（メンバーページ）
.c-page__dg_ttl {
    margin: 5rem calc(50% - 50vw) 0;
	//padding: 0 calc(50vw - 50%);
    @include pc {
        margin: 6.36vw calc(50% - 50vw) 0;
    }
    @include pc_ls {
        margin: 89px calc(50% - 50vw) 0;
    }
    &__bg {
        position: relative;
        background: #0D7100;
        @include pc {
            background: linear-gradient(0deg,rgba(255,255,255,1) 80%, rgba(13,113,0,1) 80%);
        }
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 6rem;
            background: #0D7100;
            border-radius: 0 0 100% 100%;
            position: absolute;
            bottom: -3rem;
            z-index: 0;
            @include pc {
                height: 270px;
                border-radius: 0 0 50% 50%;
                bottom: -100px;
            }
            @include pc_ls {
                height: 270px;
                bottom: -100px;
            }
        }
    }
    &__txt {
        transform: rotate(0.05deg);
        position: relative;
        text-align: center;
        padding: 6rem 2rem 0;
        @include pc {
            padding: 5.71vw 0 0;
        }
        @include pc_ls {
            padding: 80px 0 0;
        }
        .mark {
            width: 1.5rem;
        }
        .english {
            display: inline-block;
            color: #fff;
            font-size: 1.2rem;
            padding: 0 0 0 1rem;
        }
        .txt {
            display: inline-block;
            color: #fff;
            padding: 0.5rem 0 0;
            font-weight: 500;
            line-height: 1.5;
            font-size: 3.4rem;
        }
        @include pc {
            .mark {
                width: 1.43vw;
            }
            .english {
                font-size: 1vw;
                padding: 0 0 0 10px;
            }
            .txt {
                padding: 10px 0 0;
                font-size: 3.29vw;
            }
        }
        @include pc_ls {
            .mark {
                width: 20px;
            }
            .english {
                font-size: 14px;
                padding: 0 0 0 10px;
            }
            .txt {
                padding: 10px 0 0;
                font-size: 46px;
            }
        }
    }
}

//フォーム下層ページ設定
.form {
    &__contents {
        margin: 11rem 0 0;
        @include pc {
            margin: 15vw auto 0;
            max-width: 53.43vw;
        }
        @include pc_ls {
            margin: 210px auto 0;
            max-width: 748px;
        }

        //確認ページ設定
        &__ttl {
            transform: rotate(0.05deg);
            text-align: center;
            font-size: 2.6rem;
            @include pc {
                font-size: 2.57vw;
            }
            @include pc_ls {
                font-size: 36px;
            }
        }
        &__txt {
            transform: rotate(0.05deg);
            line-height: 2;
            font-size: 1.3rem;
            margin: 3rem 0 0;
            @include pc {
                font-size: 1.14vw;
                margin: 2.86vw 0 0;
                text-align: center;
            }
            @include pc_ls {
                font-size: 16px;
                margin: 40px 0 0;
            }
        }

        //完了ページ設定
        &__complete_ttl {
            transform: rotate(0.05deg);
            text-align: center;
            font-size: 2.6rem;
            color: #65C100;
            @include pc {
                font-size: 2.57vw;
            }
            @include pc_ls {
                font-size: 36px;
            }
        }
        &__complete_txt {
            transform: rotate(0.05deg);
            line-height: 2;
            font-size: 1.3rem;
            margin: 3rem 0 0;
            @include pc {
                text-align: center;
                font-size: 1.14vw;
                margin: 2.86vw 0 0;
            }
            @include pc_ls {
                font-size: 16px;
                margin: 40px 0 0;
            }
        }


        //確認ページ コンテンツ調整
        &__content_check {
            margin: 3rem 0 0;
            @include pc {
                margin: 8.57vw 0 0;
            }
            @include pc_ls {
                margin: 120px 0 0;
            }

            //リクルート
            .recruit__contents__child {
                padding: 2rem 0;
                margin: 0;
                border-bottom: solid 1px #D3D3D3;
                @include pc {
                    padding: 2.14vw 0;
                }
                @include pc_ls {
                    padding: 30px 0;
                }
            }
            .recruit__contents__child .input_field.flex_column {
                padding: 0;
            }
            .recruit__contents__child .ttl .icon {
                display: none;
            }
            .recruit__contents__child .ttl {
                color: #AAAAAA;
                padding: 0;
            }
            .recruit__contents__child .input_field {
                font-size: 1.3rem;
                @include pc {
                    font-size: 1.14vw;
                }
                @include pc_ls {
                    font-size: 16px;
                }
            }
            .recruit__contents__child .input_field .annotation {
                display: none;
            }


            //お問い合わせ
            .contact__contents__child {
                padding: 2rem 0;
                margin: 0;
                border-bottom: solid 1px #D3D3D3;
                @include pc {
                    padding: 2.14vw 0;
                }
                @include pc_ls {
                    padding: 30px 0;
                }
            }
            .contact__contents__child .ttl .icon {
                display: none;
            }
            .contact__contents__child .ttl {
                color: #AAAAAA;
                padding: 0;
            }
            .contact__contents__child .input_field {
                font-size: 1.3rem;
                @include pc {
                    font-size: 1.14vw;
                }
                @include pc_ls {
                    font-size: 16px;
                }
            }
        }


        input[type="text"] {
            width: 100%;
            font-size: 1.3rem;
            background: #EEF8E2;
            border: solid 1px rgba($color: #65C100, $alpha: .2);
            border-radius: 2rem;
            padding: 0.5rem 1.3rem;
            @include pc {
                font-size: 1.14vw;
                border-radius: 2.14vw;
                padding: 0.36vw 1.43vw;
            }
            @include pc_ls {
                font-size: 16px;
                border-radius: 30px;
                padding: 5px 20px;
            }
        }
        input[type="email"] {
            width: 100%;
            font-size: 1.3rem;
            background: #EEF8E2;
            border: solid 1px rgba($color: #65C100, $alpha: .2);
            border-radius: 2rem;
            padding: 0.5rem 1.3rem;
            @include pc {
                font-size: 1.14vw;
                border-radius: 2.14vw;
                padding: 0.36vw 1.43vw;
            }
            @include pc_ls {
                font-size: 16px;
                border-radius: 30px;
                padding: 5px 20px;
            }
        }
        textarea {
            width: 100%;
            font-size: 1.3rem;
            background: #EEF8E2;
            border: solid 1px rgba($color: #65C100, $alpha: .2);
            border-radius: 2rem;
            padding: 0.5rem 1.3rem;
            @include pc {
                font-size: 1.14vw;
                border-radius: 1.43vw;
                padding: 0.71vw 1.43vw;
            }
            @include pc_ls {
                font-size: 16px;
                border-radius: 20px;
                padding: 10px 20px;
            }
        }
        span {
            line-height: 1;
        }
        input[type="email"] {
            width: 100%;
        }
        input[type="submit"] {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6rem;
            background: #65C100;
            color: #fff !important;
            text-decoration: none;
            border-radius: 50px;
            font-size: 1.4rem;
            border: none;
            &::after {
                content: "";
                display: block;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 1px;
                border: 2px solid;
                border-color: #fff #fff transparent transparent;
                transform: rotate(45deg);
                position: absolute;
                right: 3rem;
            }
            @include pc {
                width: 25.43vw;
                height: 65px;
                border-radius: 50px;
                font-size: 1.29vw;
                margin: 0 auto;
                transition: 0.3s;
                &::after {
                    width: 0.57vw;
                    height: 0.57vw;
                    right: 2vw;
                }
                &:hover {
                    background: #fff;
                    border: solid 1px #65C100; 
                    color: #65C100 !important;
                    &::after {
                        border-color: #65C100 #65C100 transparent transparent;
                    }
                }
            }
        }
        .mwform-checkbox-field label, .mwform-radio-field label {
            display: block;
        }
    }
} 

//ページャー
.pager_list {
    display: flex;
    justify-content: center;
    margin: 5rem auto 0;
    @include pc {
        margin: 5vw auto 0;
    }
    @include pc_ls {
        margin: 70px auto 0;
    }
    .wp-pagenavi {
        display: flex;
        align-items: center;
        gap: 0 1rem;
        font-size: 1.3rem;
        font-weight: 400;
        @include pc {
            gap: 0 0.71vw;
            font-size: 1.14vw;
        }
        @include pc_ls {
            gap: 0 10px;
            font-size: 16px;
        }

        .current {
            transform: rotate(0.05deg);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: #65C100;
            color: #fff;
            border: none;
            font-weight: 400;
            @include pc {
                width: 3.57vw;
                height: 3.57vw;
            }
            @include pc_ls {
                width: 50px;
                height: 50px;
            }
        }
        .page , .nextpostslink , .previouspostslink {
            transform: rotate(0.05deg);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: #EEF8E2;
            color: #65C100;
            border: none;
            @include pc {
                width: 3.57vw;
                height: 3.57vw;
                transition: .3s;
                &:hover {
                    background: #65C100;
                    color: #fff;
                    .previous_arrow {
                        &::before {
                            border-color: transparent transparent #fff #fff;
                        }
                    }
                    .next_arrow {
                        &::before {
                            border-color: #fff #fff transparent transparent;
                        }
                    }
                }
            }
            @include pc_ls {
                width: 50px;
                height: 50px;
            }
        }
        .previouspostslink {
            .previous_arrow {
                position: relative;
                padding: 0;
                margin: 0;
                border: none;
                &::before {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 2px solid;
                    border-radius: 2px;
                    border-color: transparent transparent #65C100 #65C100;
                    transform: rotate(45deg);
                    @include pc {
                        width: 0.57vw;
                        height: 0.57vw;
                    }
                    @include pc_ls {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
        .nextpostslink {
            .next_arrow {
                position: relative;
                padding: 0;
                margin: 0;
                border: none;
                &::before {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 2px solid;
                    border-radius: 2px;
                    border-color: #65C100 #65C100 transparent transparent;
                    transform: rotate(45deg);
                    @include pc {
                        width: 0.57vw;
                        height: 0.57vw;
                    }
                    @include pc_ls {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
        .extend {
            transform: rotate(0.05deg);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 4rem;
            border-radius: 50%;
            background: #fff;
            color: #AAAAAA;
            border: none;
            @include pc {
                width: 1.79vw;
                height: 3.57vw;
            }
            @include pc_ls {
                width: 25px;
                height: 50px;
            }
        }
    }
}

//デフォルトテンプレートコンテンツ
.common {
    &__contents {
        margin: 8rem 0 0;
        @include pc {
            margin: 10.71vw auto 0;
            max-width: 53.43vw;
        }
        @include pc_ls {
            margin: 150px auto 0;
            max-width: 748px;
        }
    }
}

//プライバシーポリシー
.privacypolicy {
    &__txt {
        line-height: 1.5;
        font-feature-settings: "palt";
        margin: 5rem 0 ;
        font-size: 1.4rem;
        .ttl {
            font-weight: bold;
        }
        .txt01 {
            text-indent: -2.5em;
            padding-left: 3em;
            display: inline-block;
        }
        .txt02 {
            text-indent: -1.8em;
            padding-left: 6em;
            display: inline-block;
        }
        @include pc {
            line-height: 2;
            font-size: 1.14vw;
        }
        @include pc_ls {
            font-size: 16px;
        }
    }
}