@use "foundation" as *;

.about {

    &__lead {
        margin: 8rem 0 0;
        @include pc {
            margin: 10.71vw 0 0;
        }
        @include pc_ls {
            margin: 150px 0 0;
        }
        &__txt {
            transform: rotate(0.05deg);
            line-height: 2;
            font-size: 1.4rem;
            @include pc {
                text-align: center;
                font-size: 1.14vw;
            }
            @include pc_ls {
                font-size: 16px;
            }
        }
        &__pic {
            margin: 4rem calc(50% - 50vw) 0;
            padding: 0 calc(50vw - 55%);
            @include pc {
                margin: 4.29vw auto 0;
                padding: 0;
                width: 44.5vw;
            }
            @include pc_ls {
                margin: 60px auto 0;
                width: 623px;
            }
        }
    }

    &__history {
        margin: 6rem 0 0;
        @include pc {
            margin: 8.57vw 0 0;
        }
        @include pc_ls {
            margin: 120px 0 0;
        }
        &__ttl {
            transform: rotate(0.05deg);
            text-align: center;
            line-height: 1.5;
            font-size: 2.6rem;
            @include pc {
                font-size: 2.57vw;
            }
            @include pc_ls {
                font-size: 36px;
            }
        }
        &__graph {
            display: flex;
            flex-direction: column;
            margin: 4rem 0 0;
            @include pc {
                margin: 4.29vw auto 0;
                max-width: 53.43vw;
            }
            @include pc_ls {
                margin: 60px auto 0;
                max-width: 748px;
            }
            &__child {
                display: flex;
                flex-direction: column;
                padding: 2rem;
                border-bottom: solid 1px #D3D3D3;
                &:first-child { border-top: solid 1px #D3D3D3;}
                @include pc {
                    flex-direction: row;
                    align-items: center;
                    padding: 1.21vw 0;
                }
                @include pc_ls {
                    padding: 17px 0;
                }
                .year {
                    transform: rotate(0.05deg);
                    color: #AAAAAA;
                    font-size: 1.4rem;
                    @include pc {
                        padding:  0 0 0 2.86vw;
                        font-size: 1.14vw;
                        width: 25%;
                    }
                    @include pc_ls {
                        padding: 0 0 0 40px;
                        font-size: 16px;
                    }
                }
                .detail {
                    transform: rotate(0.05deg);
                    line-height: 1.5;
                    font-size: 1.4rem;
                    margin: 1.3rem 0 0;
                    @include pc {
                        margin: 0;
                        border-left: solid 1px #D3D3D3;
                        padding: 0.71vw 0 0.71vw 2.86vw;
                        font-size: 1.14vw;
                    }
                    @include pc_ls {
                        padding: 10px 0 10px 40px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &__organization {
        margin: 6rem 0 0;
        @include pc {
            margin: 8.57vw 0 0;
        }
        @include pc_ls {
            margin: 120px 0 0;
        }
        &__ttl {
            transform: rotate(0.05deg);
            text-align: center;
            line-height: 1.5;
            font-size: 2.6rem;
            @include pc {
                font-size: 2.57vw;
            }
            @include pc_ls {
                font-size: 36px;
            }
        }
        &__graph {
            display: flex;
            flex-direction: column;
            margin: 4rem 0 0;
            @include pc {
                margin: 4.29vw auto 0;
                max-width: 53.43vw;
            }
            @include pc_ls {
                margin: 60px auto 0;
                max-width: 748px;
            }
            &__child {
                display: flex;
                flex-direction: column;
                padding: 2rem;
                border-bottom: solid 1px #D3D3D3;
                &:first-child { border-top: solid 1px #D3D3D3;}
                @include pc {
                    flex-direction: row;
                    align-items: center;
                    padding: 1.21vw 0;
                }
                @include pc_ls {
                    padding: 17px 0;
                }
                .about_post {
                    transform: rotate(0.05deg);
                    color: #AAAAAA;
                    font-size: 1.4rem;
                    @include pc {
                        padding:  0 0 0 2.86vw;
                        font-size: 1.14vw;
                        width: 25%;
                    }
                    @include pc_ls {
                        padding: 0 0 0 40px;
                        font-size: 16px;
                    }
                }
                .name {
                    transform: rotate(0.05deg);
                    line-height: 1.5;
                    font-size: 1.4rem;
                    margin: 1.3rem 0 0;
                    a {
                        display: inline-block;
                        transform: rotate(0.05deg);
                        color: #65C100 !important;
                        @include pc {
                            transition: .3s;
                            &:hover {
                                opacity: .6;
                            }
                        }
                    }
                    @include pc {
                        margin: 0;
                        border-left: solid 1px #D3D3D3;
                        padding: 0.71vw 0 0.71vw 2.86vw;
                        font-size: 1.14vw;
                    }
                    @include pc_ls {
                        padding: 10px 0 10px 40px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}