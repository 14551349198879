@use "foundation" as *;

.l-header {
    margin-bottom: 2rem;

    &__bg {
        background: white;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }

    &__content {
        display: flex;
        align-items: center;
        height: 5rem;
    }

    &__logo {
    }

    &__description {
        margin-left: auto;
    }

    &__menuButton {
        margin-right: -1rem;

        @include pc {
            display: none;
        }
    }

}
