@use "foundation" as *;

//お知らせ一覧
.information {

    &__category_list {
        margin: 8rem 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2rem;
        @include pc {
            margin: 10.71vw 0 0;
            gap: 0 2.57vw;
        }
        @include pc_ls {
            margin: 150px 0 0;
            gap: 0 36px;
        }
        &__child {
            width: 14.7rem;
            @include pc {
                width: 18.43vw;
            }
            @include pc_ls {
                width: 258px;
            }
            &__link {
                transform: rotate(0.05deg);
                display: flex;
                align-items: center;
                justify-content: center;
                background: #EEF8E2;
                color: #65C100;
                text-decoration: none;
                font-size: 1.3rem;
                height: 4rem;
                border-radius: 50px;
                &:visited {
                    color: #65C100;
                }
                &.is-current {
                    background: #65C100;
                    color: #fff;
                }
                @include pc {
                    font-size: 1.14vw;
                    height: 3.57vw;
                    transition: .3s;
                    &:hover {
                        color: #fff;
                        background: #65C100;
                    }
                    &.is-current {
                        &:hover {
                            color: #fff;
                        }
                    }
                }
                @include pc_ls {
                    font-size: 16px;
                    height: 50px;
                }
            }
        }
    }

    &__list {
        margin: 4rem 0 0;
        display: flex;
        flex-direction: column;
        @include pc {
            margin: 4.29vw 0 0;
        }
        @include pc_ls {
            margin: 60px 0 0;
        }
        &__child {
            padding: 2rem 0;
            border-bottom: solid 1px #D3D3D3;
            &:first-child { border-top: solid 1px #D3D3D3;}
            @include pc {
                padding: 2.14vw 0;
            }
            @include pc_ls {
                padding: 30px 0;
            }
            &__link {
                @include pc {
                    display: flex;
                    align-items: center;
                }
                .list_wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .date {
                        transform: rotate(0.05deg);
                        font-size: 1.3rem;
                        @include pc {
                            margin: 0 0 0 2.86vw;
                            font-size: 1.14vw;
                        }
                        @include pc_ls {
                            margin: 0 0 0 40px;
                            font-size: 16px;
                        }
                    }
                    .term {
                        transform: rotate(0.05deg);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #EEF8E2;
                        color: #65C100;
                        border-radius: 50px;
                        width: 14rem;
                        height: 2rem;
                        font-size: 1.2rem;
                        @include pc {
                            margin:  0 0 0 4.29vw;
                            width: 11.43vw;
                            height: 2.07vw;
                            font-size: 1vw;
                        }
                        @include pc_ls {
                            margin:  0 0 0 60px;
                            width: 160px;
                            height: 29px;
                            font-size: 14px;
                        }
                    }
                }
                .link {
                    transform: rotate(0.05deg);
                    line-height: 1.5;
                    display: inline-block;
                    margin: 1.4rem 0 0;
                    font-size: 1.3rem;
                    &:visited {
                        color: #65C100 !important;
                    }
                    @include pc {
                        transition: .3s;
                        margin: 0 0 0 2.5vw;
                        font-size: 1.14vw;
                        &:hover {
                            opacity: .6;
                            color: #65C100;
                        }
                    }
                    @include pc_ls {
                        margin: 0 0 0 35px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}


//お知らせ詳細
.information_detail {
    margin: 5rem 0 0;
    @include pc {
        margin: 6.36vw 0 0;
    }
    @include pc_ls {
        margin: 89px 0 0;
    }

    &__ttl {
        transform: rotate(0.05deg);
        margin: 6rem 0 0;
        line-height: 1.5;
        font-size: 2.6rem;
        padding: 0 0 2rem;
        border-bottom: solid 1px #D3D3D3;
        @include pc {
            max-width: 53.43vw;
            margin: 8.57vw auto 0;
            font-size: 2.57vw;
            padding: 0 0 2.5vw;
        }
        @include pc_ls {
            max-width: 748px;
            margin: 120px auto 0;
            font-size: 36px;
            padding: 0 0 35px;
        }
    }
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0 0;
        @include pc {
            max-width: 53.43vw;
            margin: 1.43vw auto 0;
            justify-content: flex-start;
            gap: 0 3.93vw;
        }
        @include pc_ls {
            max-width: 748px;
            margin: 20px auto 0;
            gap: 0 55px;
        }
        .date {
            transform: rotate(0.05deg);
            font-size: 1.3rem;
            @include pc {
                font-size: 1.14vw;
            }
            @include pc_ls {
                font-size: 16px;
            }
        }
        .category {
            transform: rotate(0.05deg);
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            background: #EEF8E2;
            color: #65C100;
            border-radius: 50px;
            width: 14rem;
            height: 2rem;
            font-size: 1.2rem;
            @include sp {
                margin: 0 0 0 0.5rem;
            }
            @include pc {
                width: 11.43vw;
                height: 2.07vw;
                font-size: 1vw;
                transition: .3s;
                &:hover {
                    background: #65C100;
                    color: #fff;
                }
            }
            @include pc_ls {
                width: 160px;
                height: 29px;
                font-size: 14px;
            }
        }
    }

    &__contents {
        margin: 6rem 0 0;
        padding: 0 0 6rem;
        border-bottom: solid 1px #D3D3D3;
        @include pc {
            max-width: 53.43vw;
            margin: 8.57vw auto 0;
        }
        @include pc_ls {
            max-width: 748px;
            margin: 120px auto 0;
            padding: 0 0 120px;
        }

        h1 {
            transform: rotate(0.05deg);
            line-height: 1.5;
            margin: 4rem 0 0;
            font-size: 2rem;
            &:first-child { margin: 0;}
            @include pc {
                margin: 4.29vw 0 0;
                font-size: 2vw;
            }
            @include pc_ls {
                margin: 60px 0 0;
                font-size: 28px;
            }
        }
        h2 {
            transform: rotate(0.05deg);
            line-height: 1.5;
            margin: 4rem 0 0;
            font-size: 1.8rem;
            &:first-child { margin: 0;}
            @include pc {
                margin: 1.79vw 0 0;
                font-size: 2vw;
            }
            @include pc_ls {
                margin: 60px 0 0;
                font-size: 25px;
            }
        }
        h3 {
            transform: rotate(0.05deg);
            line-height: 1.5;
            margin: 4rem 0 0;
            font-size: 1.5rem;
            &:first-child { margin: 0;}
            @include pc {
                margin: 1.64vw 0 0;
                font-size: 2vw;
            }
            @include pc_ls {
                margin: 60px 0 0;
                font-size: 23px;
            }
        }

        p {
            transform: rotate(0.05deg);
            line-height: 2;
            margin: 1rem 0 0;
            font-size: 1.3rem;
            @include pc {
                margin: 1.43vw 0 0;
                font-size: 1.14vw;
            }
            @include pc_ls {
                margin: 20px 0 0;
                font-size: 16px;
            }
        }
        .annotation {
            transform: rotate(0.05deg);
            position: relative;
            color: #AAAAAA;
            line-height: 2;
            margin: 1rem 0 0;
            font-size: 1.1rem;
            padding: 0 0 0 1.2em;
            &::before {
                content: "※";
                display: block;
                position: absolute;
                left: 0;
            }
            @include pc {
                margin: 0.86vw 0 0;
                font-size: 1.14vw;
            }
            @include pc_ls {
                margin: 20px 0 0;
                font-size: 12px;
            }
        }

        img {
            display: block;
            margin: 4rem 0 0;
            &:first-child { margin: 0 auto;}
            @include pc {
                width: 39.43vw;
                margin: 1.43vw auto 0;
            }
            @include pc_ls {
                width: 552px;
                margin: 20px auto 0;
            }
        }

        a {
            transform: rotate(0.05deg);
            position: relative;
            display: inline-block;
            color: #65C100;
            padding: 0 0 0 1.6rem;
            font-size: 1.4rem;
            &::before {
                content: "";
                display: block;
                width: .8rem;
                height: .8rem;
                border: 2px solid;
                border-color: #65C100 #65C100 transparent transparent;
                transform: rotate(45deg);
                position: absolute;
                left: 0;
                top: 35%;
            }
            &:visited {
                color: #65C100;
            }
            @include pc {
                padding: 0 0 0 1.43vw;
                font-size: 1.29vw;
                &::before {
                    width: 0.57vw;
                    height: 0.57vw;
                    top: 40%;
                }
            }
            @include pc_ls {
                padding: 0 0 0 20px;
                font-size: 18px;
                &::before {
                    width: 8px;
                    height: 8px;
                    top: 40%;
                }
            }
        }

        ul {
            margin: 4rem 0 0;
            &:first-child { margin: 0;}
            @include pc {
                margin: 4.29vw 0 0;
            }
            @include pc_ls {
                margin: 60px 0 0;
            }
            li {
                transform: rotate(0.05deg);
                position: relative;
                line-height: 2;
                font-size: 1.3rem;
                margin: 0 0 1rem;
                padding-left: 1.3em;
                &:last-child { margin: 0; }
                &::before {
                    content: "";
                    display: block;
                    width: .8rem;
                    height: .8rem;
                    background: #65C100;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 20%;
                }
                @include pc {
                    font-size: 1.14vw;
                    margin: 0 0 0.71vw;
                    padding-left: 1.21vw;
                    &::before {
                        width: 0.71vw;
                        height: 0.71vw;
                        top: 35%;
                    }
                }
                @include pc_ls {
                    font-size: 16px;
                    margin: 0 0 10px;
                    padding-left: 17px;
                    &::before {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        ol {
            margin: 4rem 0 0;
            &:first-child { margin: 0;}
            counter-reset: item;
            list-style-type: none;
            @include pc {
                margin: 4.29vw 0 0;
            }
            @include pc_ls {
                margin: 60px 0 0;
            }
            li {
                transform: rotate(0.05deg);
                line-height: 2;
                font-size: 1.3rem;
                margin: 0 0 1rem;
                text-indent: -1.3em;
                padding-left: 1.3em;
                &:last-child { margin: 0; }
                &:before {
                    counter-increment: item;
                    content: counter(item)'.';
                    padding-right: .5em;
                    font-weight: bold;
                    color: #65C100;
                }
                @include pc {
                    font-size: 1.14vw;
                    margin: 0 0 0.71vw;
                }
                @include pc_ls {
                    font-size: 16px;
                    margin: 0 0 10px;
                }
            }
        }
    }

    &__next_prev_btn {
        display: flex;
        justify-content: center;
        gap: 0 6rem;
        margin: 2rem 0 0;
        @include pc {
            gap: 0 8.57vw;
            margin: 2.14vw 0 0;
        }
        @include pc_ls {
            gap: 0 120px;
            margin: 30px 0 0;
        }
        &__child {
            position: relative;
            a {
                text-decoration: none;
                display: flex;
                text-decoration: none;
                align-items: center;
                gap: 0 1rem;
                transition: .3s;
                @include pc {
                    gap: 0 0.71vw;
                }
                @include pc_ls {
                    gap: 0 10px;
                }
                &:visited {
                    color: #65C100 !important;
                }
                &:hover {
                    .circle_next {
                        background: #fff;
                        border: solid 1px #65C100;
                        &::before {
                            border-color: transparent transparent #65C100 #65C100;
                        }
                    }
                    .circle_prev {
                        background: #fff;
                        border: solid 1px #65C100;
                        &::before {
                            border-color: #65C100 #65C100 transparent transparent;
                        }
                    }
                }
            }
            &:first-child {
                a {
                    &::after {
                        content: "";
                        display: block;
                        width: 1px;
                        height: 2rem;
                        background: #D3D3D3;
                        position: absolute;
                        right: -3rem;
                        @include pc {
                            height: 2.14vw;
                            right: -4.29vw;
                        }
                        @include pc_ls {
                            height: 30px;
                            right: -60px;
                        }
                    }
                }
            }
            .txt {
                transform: rotate(0.05deg);
                font-size: 1.3rem;
                @include pc {
                    font-size: 1.29vw;
                }
                @include pc_ls {
                    font-size: 18px;
                }
            }
            .pic {
                width: 4rem;
                @include pc {
                    width: 3.57vw;
                }
                @include pc_ls {
                    width: 50px;
                }
            }
            .circle_next {
                position: relative;
                transition: .3s;
                display: block;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background: #65C100;
                &::before {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 2px solid;
                    border-radius: 2px;
                    border-color: transparent transparent #fff #fff;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 40%;
                    right: 35%;
                }
                @include pc {
                    width: 3.57vw;
                    height: 3.57vw;
                    &::before {
                        width: 0.57vw;
                        height: 0.57vw;
                    }
                }
                @include pc_ls {
                    width: 50px;
                    height: 50px;
                    &::before {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
            .circle_prev {
                position: relative;
                display: block;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background: #65C100;
                &::before {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 2px solid;
                    border-radius: 2px;
                    border-color: #fff #fff transparent transparent;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 40%;
                    right: 43%;
                }
                @include pc {
                    width: 3.57vw;
                    height: 3.57vw;
                    &::before {
                        width: 0.57vw;
                        height: 0.57vw;
                    }
                }
                @include pc_ls {
                    width: 50px;
                    height: 50px;
                    &::before {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    &__btn {
        display: flex;
        flex-direction: column;
        margin: 6rem 0 0;
        gap: 2rem 0;
        @include pc {
            margin: 8.57vw 0 0;
            flex-direction: row;
            justify-content: center;
            gap: 0 2.14vw;
        }
        @include pc {
            margin: 120px 0 0;
            gap: 0 30px;
        }
        &__child {
            @include pc {
                margin: 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}


//カテゴリーページ
.information_category {
    &__ttl {
        margin: 5rem0 0;
        @include pc {
            margin: 89px 0 0;
        }
    &__bg {}
    &__txt {
        text-align: center;
        padding: 6rem 2rem 0;
        @include pc {
            padding: 80px 0 0;
        }
        .txt {
            transform: rotate(0.05deg);
            display: inline-block;
            padding: 0.5rem 0 0;
            font-weight: 500;
            line-height: 1.5;
            font-size: 3.2rem;
        }
        @include pc {
            .txt {
                padding: 10px 0 0;
                font-size: 3.29vw;
            }
        }
    }
    }

    &__category_list {
        margin: 4rem 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2rem;
        @include pc {
            margin: 4.29vw 0 0;
            gap: 0 2.57vw;
        }
        @include pc_ls {
            margin: 60px 0 0;
            gap: 0 36px;
        }
        &__child {
            width: 14.7rem;
            @include pc {
                width: 18.43vw;
            }
            @include pc_ls {
                width: 258px;
            }
            &__link {
                transform: rotate(0.05deg);
                display: flex;
                align-items: center;
                justify-content: center;
                background: #EEF8E2;
                color: #65C100;
                text-decoration: none;
                font-size: 1.3rem;
                height: 4rem;
                border-radius: 50px;
                &:visited {
                    color: #65C100;
                }
                &.is-current {
                    background: #65C100;
                    color: #fff;
                }
                &.current {
                    background: #65C100;
                    color: #fff;
                }
                @include pc {
                    font-size: 1.14vw;
                    height: 3.57vw;
                    transition: .3s;
                    &:hover {
                        color: #fff;
                        background: #65C100;
                    }
                    &.is-current {
                        &:hover {
                            color: #fff;
                        }
                    }
                }
                @include pc_ls {
                    font-size: 16px;
                    height: 50px;
                }
            }
        }
    }

    &__list {
        margin: 4rem 0 0;
        display: flex;
        flex-direction: column;
        @include pc {
            margin: 4.29vw 0 0;
        }
        @include pc_ls {
            margin: 60px 0 0;
        }
        &__child {
            padding: 2rem 0;
            border-bottom: solid 1px #D3D3D3;
            &:first-child { border-top: solid 1px #D3D3D3;}
            @include pc {
                padding: 2.14vw 0;
            }
            @include pc_ls {
                padding: 30px 0;
            }
            &__link {
                @include pc {
                    display: flex;
                    align-items: center;
                }
                .list_wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .date {
                        transform: rotate(0.05deg);
                        font-size: 1.3rem;
                        @include pc {
                            margin: 0 0 0 2.86vw;
                            font-size: 1.14vw;
                        }
                        @include pc_ls {
                            margin: 0 0 0 40px;
                            font-size: 16px;
                        }
                    }
                    .term {
                        transform: rotate(0.05deg);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #EEF8E2;
                        color: #65C100;
                        border-radius: 50px;
                        width: 14rem;
                        height: 2rem;
                        font-size: 1.2rem;
                        @include pc {
                            margin:  0 0 0 4.29vw;
                            width: 11.43vw;
                            height: 2.07vw;
                            font-size: 1vw;
                        }
                        @include pc_ls {
                            margin:  0 0 0 60px;
                            width: 160px;
                            height: 29px;
                            font-size: 14px;
                        }
                    }
                }
                .link {
                    transform: rotate(0.05deg);
                    line-height: 1.5;
                    display: inline-block;
                    margin: 1.4rem 0 0;
                    font-size: 1.3rem;
                    &:visited {
                        color: #65C100 !important;
                    }
                    @include pc {
                        transition: .3s;
                        margin: 0 0 0 2.5vw;
                        font-size: 1.14vw;
                        &:hover {
                            opacity: .6;
                            color: #65C100;
                        }
                    }
                    @include pc_ls {
                        margin: 0 0 0 35px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &__btn {
        display: flex;
        flex-direction: column;
        margin: 6rem 0 0;
        @include pc {
            margin: 8.57vw 0 0;
            flex-direction: row;
            justify-content: center;
            gap: 0 2.14vw;
        }
        @include pc {
            margin: 120px 0 0;
            gap: 0 30px;
        }
        &__child {
            &:last-child {
                margin: 2rem 0 0;
            }
            @include pc {
                margin: 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}