@use "foundation" as *;

.recruit {
    &__contents {
        margin: 8rem 0 0;
        @include pc {
            margin: 10.71vw auto 0;
            max-width: 53.43vw;
        }
        @include pc_ls {
            margin: 150px auto 0;
            max-width: 748px;
        }


        &__ttl {
            transform: rotate(0.05deg);
            line-height: 1.5;
            font-size: 2.6rem;
            text-align: center;
            @include pc {
                font-size: 2.57vw;
            }
            @include pc_ls {
                font-size: 36px;
            }
        }
        &__txt {
            transform: rotate(0.05deg);
            line-height: 2;
            font-size: 1.3rem;
            margin: 3rem 0 0;
            @include pc {
                text-align: center;
                margin: 2.86vw 0 0;
                font-size: 1.14vw;
            }
            @include pc_ls {
                margin: 40px 0 0;
                font-size: 16px;
            }
        }
        &__contact_ttl {
            transform: rotate(0.05deg);
            text-align: center;
            font-size: 2rem;
            border-bottom: solid 1px #D3D3D3;
            margin: 6rem 0 0;
            padding: 0 0 1rem;
            @include pc {
                margin: 8.57vw 0 0;
                font-size: 2vw;
                padding: 0 0 1.43vw;
            }
            @include pc_ls {
                margin: 120px 0 0;
                font-size: 28px;
                padding: 0 0 20px;
            }
        }


        &__wrap {
            display: flex;
            flex-direction: column;
            margin: 4rem 0 0;
            @include pc {
                margin: 4.29vw 0 0;
            }
            @include pc_ls {
                margin: 60px 0 0;
            }
        }

        &__child {
            display: flex;
            flex-direction: column;
            margin: 2.5rem 0 0;
            &:first-child {
                margin: 0;
            }
            @include pc {
                flex-direction: row;
                align-items: flex-start;
                margin: 2.14vw 0 0;
            }
            @include pc_ls {
                margin: 30px 0 0;
            }
            .ttl {
                transform: rotate(0.05deg);
                font-size: 1.3rem;
                @include pc {
                    width: 40%;
                    font-size: 1.14vw;
                    padding: 0.71vw 0 0;
                }
                @include pc_ls {
                    font-size: 16px;
                    padding: 10px 0 0;
                }
                .icon {
                    transform: rotate(0.05deg);
                    display: inline-block;
                    color: #D60000;
                    margin: 0 0 0 1rem;
                    font-size: 1.2rem;
                    @include pc {
                        margin: 0 0 0 1.07vw;
                        font-size: 1vw;
                    }
                    @include pc_ls {
                        margin: 0 0 0 15px;
                        font-size: 14px;
                    }
                }
            }
            .input_field {
                margin: 1.5rem 0 0;
                font-size: 1.3rem;
                @include pc {
                    margin: 0;
                    width: 60%;
                    font-size: 1.14vw;
                    &.flex_column {
                        display: flex;
                        gap: 0 5vw;
                        padding: 0.71vw 0 0;
                    }
                }
                @include pc_ls {
                    font-size: 16px;
                    &.flex_column {
                        gap: 0 70px;
                        padding: 10px 0 0;
                    }
                }

                .annotation {
                    transform: rotate(0.05deg);
                    text-indent: -1em;
                    padding: 0 0 0 1em;
                    color: #AAAAAA;
                    line-height: 1.5;
                    font-size: 1.1rem;
                    &.top {
                        margin: 1rem 0 0;
                    }
                    @include pc {
                        font-size: 12px;
                        &.top {
                            margin: 10px 0 0;
                        }
                    }
                }
            }
        }

        &__consent {
            margin: 2rem auto 0;
            display: flex;
            justify-content: center;
            @include pc{
                margin: 3.57vw auto 0;
            }
            @include pc_ls{
                margin: 50px auto 0;
            }
            a {
                color: #65C100 !important;
            }
        }

        &__submit {
            margin: 4rem 0 0;
            @include pc {
                margin: 4.29vw auto 0;
            }
            @include pc_ls {
                margin: 60px auto 0;
            }
        }

        input[type="text"] {
            width: 100%;
            background: #EEF8E2;
            border: solid 1px rgba($color: #65C100, $alpha: .2);
            border-radius: 2rem;
            padding: 0.5rem 1.3rem;
            font-size: medium;
            &::placeholder {
                color: #D3D3D3;
            }
            @include pc {
                font-size: 1.14vw;
                border-radius: 2.14vw;
                padding: 0.36vw 1.43vw;
            }
            @include pc_ls {
                font-size: 16px;
                border-radius: 30px;
                padding: 5px 20px;
            }
        }
        input[type="email"] {
            width: 100%;
            font-size: medium;
            background: #EEF8E2;
            border: solid 1px rgba($color: #65C100, $alpha: .2);
            border-radius: 2rem;
            padding: 0.5rem 1.3rem;
            &::placeholder {
                color: #D3D3D3;
            }
            @include pc {
                font-size: 1.14vw;
                border-radius: 2.14vw;
                padding: 0.36vw 1.43vw;
            }
            @include pc_ls {
                font-size: 16px;
                border-radius: 30px;
                padding: 5px 20px;
            }
        }
        textarea {
            width: 100%;
            font-size: medium;
            background: #EEF8E2;
            border: solid 1px rgba($color: #65C100, $alpha: .2);
            border-radius: 2rem;
            padding: 0.5rem 1.3rem;
            &::placeholder {
                color: #D3D3D3;
            }
            @include pc {
                font-size: 1.14vw;
                border-radius: 1.43vw;
                padding: 0.71vw 1.43vw;
            }
            @include pc_ls {
                font-size: 16px;
                border-radius: 20px;
                padding: 10px 20px;
            }
        }
        span {
            line-height: 1;
        }
        input[type="email"] {
            width: 100%;
        }
        input[type="submit"] {
            transform: rotate(0.05deg);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6rem;
            background: #65C100;
            color: #fff !important;
            text-decoration: none;
            border-radius: 50px;
            font-size: 1.4rem;
            border: none;
            &::after {
                content: "";
                display: block;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 1px;
                border: 2px solid;
                border-color: #fff #fff transparent transparent;
                transform: rotate(45deg);
                position: absolute;
                right: 3rem;
            }
            @include pc {
                width: 25.43vw;
                height: 65px;
                border-radius: 50px;
                font-size: 1.29vw;
                margin: 0 auto;
                transition: 0.3s;
                &::after {
                    width: 0.57vw;
                    height: 0.57vw;
                    right: 2vw;
                }
                &:hover {
                    background: #fff;
                    border: solid 1px #65C100; 
                    color: #65C100 !important;
                    &::after {
                        border-color: #65C100 #65C100 transparent transparent;
                    }
                }
            }
            @include pc_ls {
                width: 356px;
                height: 65px;
                font-size: 18px;
                &::after {
                    width: 8px;
                    height: 8px;
                    right: 25px;
                }
            }
        }
        .mwform-checkbox-field label, .mwform-radio-field label {
            display: block;
        }
    }
}