@use "foundation" as *;

.nursery_list {

    &__map {
        margin: 8rem calc(50% - 50vw) 0;
        @include pc {
            margin: 10.71vw auto 0;
            max-width: 53.43vw;
        }
        @include pc_ls {
            margin: 150px auto 0;
            max-width: 748px;
        }
        &__wrap {
            margin: 0 calc(50% - 50vw) 0;
            //padding: 0 calc(50vw - 55%);
            width: 100%;
            //height: 19.4rem;
            height: 25rem;
            @include pc {
                margin: 0 auto;
            }
        }
    }

    &__list {
        margin: 6rem 0 0;
        @include pc {
            margin: 8.57vw 0 0;
        }
        @include pc_ls {
            margin: 120px 0 0 ;
        }
        &__child {
            padding: 2rem 0;
            border-bottom: solid 1px #D3D3D3;
            &:first-child { border-top: solid 1px #D3D3D3;}
            @include pc {
                display: flex;
                padding: 2.86vw 0;
            }
            @include pc_ls {
                padding: 40px 0;
            }
            &__pic {
                img {
                    width: 100%;
                    height: 21rem;
                    object-fit: cover;
                }
                @include pc {
                    width: 25.43vw;
                    img {
                        width: 25.43vw;
                        height: 16.93vw;
                    }
                }
                @include pc_ls {
                    width: 356px;
                    img {
                        width: 356px;
                        height: 237px;
                    }
                }
            }
            &__detail {
                padding: 2rem 0 0;
                @include pc {
                    padding: 0 0 0 2.5vw;
                }
                @include pc_ls {
                    padding: 0 0 0 35px;
                }

                .nursery_name {
                    transform: rotate(0.05deg);
                    line-height: 1.5;
                    font-size: 2rem;
                    a {
                        color: #65C100 !important;
                    }
                    @include pc {
                        font-size: 2vw;
                        a {
                            display: inline-block;
                            transform: rotate(0.05deg);
                            transition: .3s;
                            &:hover {
                                opacity: .6;
                            }
                        }
                    }
                    @include pc_ls {
                        font-size: 28px;
                    }
                }
                .nursery_address {
                    transform: rotate(0.05deg);
                    font-size: 1.3rem;
                    margin: 1.3rem 0;
                    @include pc {
                        margin: 2.5vw 0 1.79vw;
                        font-size: 1.14vw;
                    }
                    @include pc_ls {
                        margin: 35px 0 25px;
                        font-size: 16px;
                    }
                }
                .nursery_tel {
                    display: flex;
                    .txt {
                        transform: rotate(0.05deg);
                        color: #AAAAAA;
                        font-size: 1.3rem;
                        @include pc {
                            font-size: 1.14vw;
                        }
                        @include pc_ls {
                            font-size: 16px;
                        }
                    }
                    .detail {
                        transform: rotate(0.05deg);
                        font-size: 1.3rem;
                        margin: 0 0 0 1.5rem;
                        @include pc {
                            font-size: 1.14vw;
                            margin: 0 0 0 2.14vw;
                        }
                        @include pc_ls {
                            margin: 0 0 0 35px;
                            font-size: 16px;
                        }
                    }
                }
                .nursery_fax {
                    display: flex;
                    margin: .5rem 0 0;
                    @include pc {
                        margin: 0.71vw 0 0;
                    }
                    @include pc_ls {
                        margin: 10px 0 0;
                    }
                    .txt {
                        transform: rotate(0.05deg);
                        color: #AAAAAA;
                        font-size: 1.3rem;
                        @include pc {
                            font-size: 1.14vw;
                        }
                        @include pc_ls {
                            font-size: 16px;
                        }
                    }
                    .detail {
                        transform: rotate(0.05deg);
                        font-size: 1.3rem;
                        margin: 0 0 0 1.5rem;
                        @include pc {
                            font-size: 1.14vw;
                            margin: 0 0 0 2.14vw;
                        }
                        @include pc_ls {
                            margin: 0 0 0 35px;
                            font-size: 16px;
                        }
                    }
                }
                .nursery_icon_list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1rem 0;
                    padding: 0 2rem 0 0;
                    margin: 2rem 0 0;
                    @include pc {
                        gap: 0 2.14vw;
                        padding: 0;
                        margin: 2.86vw 0 0;
                    }
                    @include pc_ls {
                        gap: 0 30px;
                        margin: 40px 0 0;
                    }
                    .nursery_icon_list_child {
                        width: 50%;
                        @include pc {
                            width: auto;
                        }
                        &.icon01 {
                            img {
                                width: 7.7rem;
                                @include pc {
                                    width: 6.36vw;
                                }
                                @include pc_ls {
                                    width: 89px;
                                }
                            }
                        }
                        &.icon02 {
                            img {
                                width: 12.7rem;
                                @include pc {
                                    width: 10.5vw;
                                }
                                @include pc_ls {
                                    width: 147px;
                                }
                            }
                        }
                        &.icon03 {
                            img {
                                width: 8.9rem;
                                @include pc {
                                    width: 7.43vw;
                                }
                                @include pc_ls {
                                    width: 104px;
                                }
                            }
                        }
                        &.icon04 {
                            img {
                                width: 11.4rem;
                                @include pc {
                                    width: 9.5vw;
                                }
                                @include pc_ls {
                                    width: 133px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}