@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  text-decoration: none;
}

ins, mark {
  background-color: #ff9;
  color: #000;
}

mark {
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

table {
  font-size: inherit;
}

pre, code, kbd, samp, tt {
  font-family: monospace;
  *font-size: 100%;
  font: 115% monospace;
  line-height: 150%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

html {
  font-size: 2.666vw;
}
@media (min-width: 900px) {
  html {
    font-size: 125%;
  }
}

body {
  overflow-x: hidden;
  font-family: "M PLUS 1p", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
  color: #464646;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0px;
  -webkit-text-size-adjust: 100%;
}
@media screen and (min-width: 0\0 ) and (min-resolution: 0.001dpcm) {
  body {
    font-family: Meiryo, sans-serif;
  }
}
@media (min-width: 900px) {
  body {
    font-size: 16px;
  }
}

*, *::after, *::before {
  box-sizing: border-box;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  image-rendering: -webkit-optimize-contrast;
}

button,
input[type=button],
input[type=submit] {
  -webkit-appearance: none;
  appearance: none;
}

a {
  color: #65C100;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
main {
  display: block;
}

.l-footer {
  margin-top: 14rem;
}
@media (min-width: 900px) {
  .l-footer {
    margin-top: 20vw;
  }
}
@media (min-width: 1400px) {
  .l-footer {
    margin-top: 280px;
  }
}
.l-footer__bg {
  position: relative;
  background: #EEF8E2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 900px) {
  .l-footer__bg {
    background: linear-gradient(0deg, rgb(238, 248, 226) 80%, rgb(255, 255, 255) 80%);
  }
}
.l-footer__bg::before {
  content: "";
  display: block;
  width: 100%;
  height: 6rem;
  background: #EEF8E2;
  border-radius: 100% 100% 0 0;
  position: absolute;
  top: -3rem;
  z-index: 0;
}
@media (min-width: 900px) {
  .l-footer__bg::before {
    height: 17.14vw;
    border-radius: 50% 50% 0 0;
    top: -3.57vw;
  }
}
@media (min-width: 1400px) {
  .l-footer__bg::before {
    height: 240px;
    top: -50px;
  }
}
.l-footer__logo {
  position: relative;
  width: 19rem;
}
@media (min-width: 900px) {
  .l-footer__logo {
    width: 20.36vw;
    transition: 0.3s;
  }
  .l-footer__logo:hover {
    opacity: 0.5;
  }
}
@media (min-width: 1400px) {
  .l-footer__logo {
    width: 285px;
  }
}
.l-footer__link_list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .l-footer__link_list {
    flex-direction: row;
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .l-footer__link_list {
    margin: 60px 0 0;
  }
}
.l-footer__link_list__link {
  margin: 0 0 2.5rem;
}
@media (min-width: 900px) {
  .l-footer__link_list__link {
    margin: 0 30px 0 0;
  }
}
.l-footer__link_list__link:last-child {
  margin: 0;
}
.l-footer__link_list__link a {
  transform: rotate(0.05deg);
  display: inline-block;
  color: #464646 !important;
  font-size: 1.2rem;
  text-decoration: none;
  text-align: center;
}
@media (min-width: 900px) {
  .l-footer__link_list__link a {
    font-size: 1vw;
    transition: 0.3s;
  }
  .l-footer__link_list__link a:hover {
    color: #65C100 !important;
  }
}
@media (min-width: 1400px) {
  .l-footer__link_list__link a {
    font-size: 14px;
  }
}
.l-footer__link_btn {
  position: relative;
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .l-footer__link_btn {
    margin: 2.86vw 0 0;
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1400px) {
  .l-footer__link_btn {
    margin: 40px 0 0;
  }
}
.l-footer__link_btn__link {
  margin: 0 0 2rem;
}
.l-footer__link_btn__link:last-child {
  margin: 0;
}
@media (min-width: 900px) {
  .l-footer__link_btn__link {
    margin: 0;
  }
}
.l-footer__link_btn__link a {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 31.5rem;
  height: 6rem;
  border-radius: 50px;
  font-size: 1.4rem;
}
@media (min-width: 900px) {
  .l-footer__link_btn__link a {
    width: 18.43vw;
    height: 65px;
    font-size: 1vw;
    transition: 0.3s;
  }
  .l-footer__link_btn__link a:hover {
    opacity: 0.5;
  }
}
@media (min-width: 1400px) {
  .l-footer__link_btn__link a {
    width: 258px;
    font-size: 14px;
  }
}
.l-footer__link_btn__link .contact {
  background: #fff;
  color: #65C100;
  border: solid 1px #65C100;
}
.l-footer__link_btn__link .contact .mail_icon {
  display: inline-block;
  width: 2rem;
  margin: 0 1rem 0 0;
}
@media (min-width: 900px) {
  .l-footer__link_btn__link .contact {
    margin: 0 2.14vw 0 0;
  }
  .l-footer__link_btn__link .contact .mail_icon {
    width: 1.43vw;
    margin: 0 0.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .l-footer__link_btn__link .contact {
    margin: 0 30px 0 0;
  }
  .l-footer__link_btn__link .contact .mail_icon {
    width: 20px;
    margin: 0 10px 0 0;
  }
}
.l-footer__link_btn__link .member {
  background: #0D7100;
  color: #fff;
}
.l-footer__copyright {
  margin: 8rem calc(50% - 50vw) 0;
  padding: 1.2rem calc(50vw - 50%);
  background: #0D7100;
  display: block;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #fff;
}
@media (min-width: 900px) {
  .l-footer__copyright {
    margin: 80px calc(50% - 50vw) 0;
    padding: 20px calc(50vw - 50%);
    font-size: 0.86vw;
  }
}
@media (min-width: 1400px) {
  .l-footer__copyright {
    font-size: 12px;
  }
}
.l-footer__copyright__txt {
  transform: rotate(0.05deg);
}

.l-header {
  margin-bottom: 2rem;
}
.l-header__bg {
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.l-header__content {
  display: flex;
  align-items: center;
  height: 5rem;
}
.l-header__description {
  margin-left: auto;
}
.l-header__menuButton {
  margin-right: -1rem;
}
@media (min-width: 900px) {
  .l-header__menuButton {
    display: none;
  }
}

.all_wrapper {
  overflow: hidden;
}

.l-container {
  margin-right: 3rem;
  margin-left: 3rem;
}
@media (min-width: 900px) {
  .l-container {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.c-scroll-top {
  position: fixed;
  right: 1rem;
  bottom: 5rem;
}
.c-scroll-top a {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
}

/*-----------------------------------------------------------
    pc menu
-----------------------------------------------------------*/
@media (min-width: 900px) {
  .sp-tab_menu {
    display: none;
  }
  .pc_menu {
    display: block;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .pc_menu__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .pc_menu__content__logo {
    width: 17.14vw;
  }
  .pc_menu__content__logo a {
    transition: opacity 0.3s;
  }
  .pc_menu__content__logo a:hover {
    opacity: 0.5;
  }
  .pc_menu__content__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .pc_menu__content__menu__top__list {
    display: flex;
    align-items: center;
  }
  .pc_menu__content__menu__top__list__link {
    margin: 0 30px 0 0;
  }
  .pc_menu__content__menu__top__list__link:last-child {
    margin: 0;
  }
  .pc_menu__content__menu__top__list__link .link {
    display: inline-block;
    transform: rotate(0.05deg);
    text-decoration: none;
    transition: opacity 0.3s;
  }
  .pc_menu__content__menu__top__list__link .link:hover {
    opacity: 0.5;
  }
  .pc_menu__content__menu__top__list__link .link.contact {
    color: #69BA00;
    font-size: 1vw;
  }
  .pc_menu__content__menu__top__list__link .link.contact.icon_link {
    display: flex;
    align-items: center;
  }
  .pc_menu__content__menu__top__list__link .link.contact .mail_icon {
    width: 1.43vw;
    display: inline-block;
    margin: 0 0.71vw 0 0;
  }
  .pc_menu__content__menu__top__list__link .link.logout {
    color: #69BA00;
    font-size: 1vw;
  }
  .pc_menu__content__menu__top__list__link .link.member {
    background: #0D7100;
    color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
    font-size: 0.93vw;
  }
  .pc_menu__content__menu__bottom {
    margin: 20px 0 0;
  }
  .pc_menu__content__menu__bottom__list {
    display: flex;
    padding: 0 0 20px;
  }
  .pc_menu__content__menu__bottom__list__link {
    margin: 0 30px 0 0;
  }
  .pc_menu__content__menu__bottom__list__link:last-child {
    margin: 0;
  }
  .pc_menu__content__menu__bottom__list__link .link {
    display: inline-block;
    transform: rotate(0.05deg);
    color: #464646;
    text-decoration: none;
    transition: color 0.3s;
    font-size: 1vw;
  }
  .pc_menu__content__menu__bottom__list__link .link:hover {
    color: #65C100;
  }
}
@media (min-width: 1400px) {
  .pc_menu__content__logo {
    width: 240px;
  }
  .pc_menu__content__menu__top__list__link .link.contact {
    font-size: 14px;
  }
  .pc_menu__content__menu__top__list__link .link.contact .mail_icon {
    width: 20px;
    margin: 0 10px 0 0;
  }
  .pc_menu__content__menu__top__list__link .link.member {
    padding: 10px 20px;
    font-size: 13px;
  }
  .pc_menu__content__menu__top__list__link .link.logout {
    font-size: 14px;
  }
  .pc_menu__content__menu__bottom__list__link .link {
    font-size: 14px;
  }
}
/*-----------------------------------------------------------
    sp-tab menu
-----------------------------------------------------------*/
@media (max-width: 1100px) {
  .pc_menu {
    display: none;
  }
  .sp-tab_menu {
    display: block;
  }
  .sp-tab_menu__top {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    background: #fff;
    height: 5rem;
    width: 100%;
    z-index: 100;
    padding: 0 3rem;
  }
  .sp-tab_menu__top__logo {
    width: 19rem;
  }
  .menu-checkbox {
    display: none;
  }
  .drawer-menu {
    position: fixed;
    padding: 5rem 0 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transform: translateX(100%);
    transition: all 0.5s ease-in-out 0s;
    background-color: #65C100;
    z-index: 20;
    overflow: auto;
    visibility: hidden;
  }
  .drawer-menu-list {
    display: flex;
    flex-direction: column;
  }
  .drawer-menu-item {
    padding: 2rem;
    border-bottom: solid 1px #fff;
  }
  .drawer-menu__link_btn {
    margin: 2rem auto 0;
    padding: 0 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .drawer-menu__link_btn__link {
    margin: 0 0 2rem;
  }
  .drawer-menu__link_btn__link:last-child {
    margin: 0;
  }
  .drawer-menu__link_btn__link a {
    transform: rotate(0.05deg);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 31.5rem;
    height: 6rem;
    border-radius: 50px;
    font-size: 1.4rem;
  }
  .drawer-menu__link_btn__link .contact {
    background: #fff;
    color: #65C100;
    border: solid 1px #65C100;
  }
  .drawer-menu__link_btn__link .contact .mail_icon {
    display: inline-block;
    width: 2rem;
    margin: 0 1rem 0 0;
  }
  .drawer-menu__link_btn__link .member {
    background: #0D7100;
    color: #fff;
  }
  .drawer-menu-item-link {
    transform: rotate(0.05deg);
    display: block;
    text-align: center;
    font-size: 1.8rem;
    color: #fff;
    text-decoration: none;
  }
  .drawer-menu-item-link:visited {
    color: #fff;
  }
  .drawer-menu-item-link .english_ttl {
    transform: rotate(0.05deg);
    color: #0D7100;
    padding: 0 0 0.7rem;
    display: inline-block;
    font-size: 1.3rem;
  }
  .menu-checkbox:checked ~ .drawer-menu {
    transform: translateX(0);
    visibility: visible;
  }
  .drawer-icon {
    cursor: pointer;
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    width: 4rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    z-index: 101;
    background-color: #fff;
  }
  .drawer-icon span,
.drawer-icon span:before,
.drawer-icon span:after {
    content: "";
    display: block;
    height: 2px;
    width: 4rem;
    border-radius: 3px;
    background-color: #65C100;
    position: absolute;
    transition: all 0.5s ease-in-out 0s;
  }
  .drawer-icon span {
    background-color: rgba(255, 255, 255, 0);
  }
  .drawer-icon span::before {
    bottom: -0.5rem;
  }
  .drawer-icon span::after {
    top: 1.5rem;
  }
  .menu-checkbox:checked ~ .drawer-icon {
    background-color: transparent;
  }
  .menu-checkbox:checked ~ .drawer-icon span {
    background-color: rgba(255, 255, 255, 0);
  }
  .menu-checkbox:checked ~ .drawer-icon span::before {
    bottom: -0.7rem;
    transform: rotate(-25deg);
    background-color: #65C100;
  }
  .menu-checkbox:checked ~ .drawer-icon span::after {
    top: 0.7rem;
    transform: rotate(25deg);
    background-color: #65C100;
  }
}
@media (min-width: 900px) and (max-width: 1100px) {
  .sp-tab_menu__top {
    height: 60px;
    padding: 0 9vw;
  }
  .sp-tab_menu__top__logo {
    width: 19.1vw;
  }
  .drawer-menu {
    padding: 60px 0 0;
  }
  .drawer-menu-item {
    padding: 0 0 4vw;
    border-bottom: none;
    width: 50%;
  }
  .drawer-menu-item:last-child {
    padding: 0;
  }
  .drawer-menu-list {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5vw 9vw 0;
  }
  .drawer-menu-item-link {
    text-align: left;
    font-size: 1.8vw;
  }
  .drawer-menu-item-link .english_ttl {
    padding: 0 0 0.7vw;
    font-size: 1.2vw;
  }
  .drawer-menu__link_btn {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px auto 0;
    padding: 0 9vw;
  }
  .drawer-menu__link_btn__link {
    margin: 0 0 0 0;
    width: 50%;
  }
  .drawer-menu__link_btn__link a {
    width: 25.2vw;
    height: 46px;
    border-radius: 50px;
    font-size: 1.3vw;
  }
  .drawer-menu__link_btn__link .contact .mail_icon {
    display: inline-block;
    width: 1.43vw;
    margin: 0 0.71vw 0 0;
  }
  .drawer-icon {
    width: 4vw;
    height: 2vw;
    top: 20px;
    right: 70px;
  }
  .drawer-icon span, .drawer-icon span:before, .drawer-icon span:after {
    height: 2px;
    width: 4vw;
  }
  .drawer-icon span::before {
    bottom: -0.5vw;
  }
  .drawer-icon span::after {
    top: 1.5vw;
  }
}
@media (max-width: 899px) {
  .pc_menu {
    display: none;
  }
  .sp-tab_menu {
    display: block;
  }
  .sp-tab_menu__top {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    background: #fff;
    height: 5rem;
    width: 100%;
    z-index: 100;
    padding: 0 3rem;
  }
  .sp-tab_menu__top__logo {
    width: 19rem;
  }
  .menu-checkbox {
    display: none;
  }
  .drawer-menu {
    position: fixed;
    padding: 5rem 0 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transform: translateX(100%);
    transition: all 0.5s ease-in-out 0s;
    background-color: #65C100;
    z-index: 20;
    overflow: auto;
    visibility: hidden;
  }
  .drawer-menu-list {
    display: flex;
    flex-direction: column;
  }
  .drawer-menu-item {
    padding: 2rem;
    border-bottom: solid 1px #fff;
  }
  .drawer-menu__link_btn {
    margin: 2rem auto 0;
    padding: 0 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .drawer-menu__link_btn__link {
    margin: 0 0 2rem;
  }
  .drawer-menu__link_btn__link:last-child {
    margin: 0;
  }
  .drawer-menu__link_btn__link a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 31.5rem;
    height: 6rem;
    border-radius: 50px;
    font-size: 1.4rem;
  }
  .drawer-menu__link_btn__link .contact {
    background: #fff;
    color: #65C100;
    border: solid 1px #65C100;
  }
  .drawer-menu__link_btn__link .contact .mail_icon {
    display: inline-block;
    width: 2rem;
    margin: 0 1rem 0 0;
  }
  .drawer-menu__link_btn__link .member {
    background: #0D7100;
    color: #fff;
  }
  .drawer-menu-item-link {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    color: #fff;
    text-decoration: none;
  }
  .drawer-menu-item-link:visited {
    color: #fff;
  }
  .drawer-menu-item-link .english_ttl {
    color: #0D7100;
    padding: 0 0 0.7rem;
    display: inline-block;
    font-size: 1.3rem;
  }
  .menu-checkbox:checked ~ .drawer-menu {
    transform: translateX(0);
    visibility: visible;
  }
  .drawer-icon {
    cursor: pointer;
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    width: 4rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    z-index: 101;
    background-color: #fff;
  }
  .drawer-icon span,
.drawer-icon span:before,
.drawer-icon span:after {
    content: "";
    display: block;
    height: 2px;
    width: 4rem;
    border-radius: 3px;
    background-color: #65C100;
    position: absolute;
    transition: all 0.5s ease-in-out 0s;
  }
  .drawer-icon span {
    background-color: rgba(255, 255, 255, 0);
  }
  .drawer-icon span::before {
    bottom: -0.5rem;
  }
  .drawer-icon span::after {
    top: 1.5rem;
  }
  .menu-checkbox:checked ~ .drawer-icon {
    background-color: transparent;
  }
  .menu-checkbox:checked ~ .drawer-icon span {
    background-color: rgba(255, 255, 255, 0);
  }
  .menu-checkbox:checked ~ .drawer-icon span::before {
    bottom: -0.7rem;
    transform: rotate(-25deg);
    background-color: #65C100;
  }
  .menu-checkbox:checked ~ .drawer-icon span::after {
    top: 0.7rem;
    transform: rotate(25deg);
    background-color: #65C100;
  }
}
.about__lead {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .about__lead {
    margin: 10.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .about__lead {
    margin: 150px 0 0;
  }
}
.about__lead__txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.4rem;
}
@media (min-width: 900px) {
  .about__lead__txt {
    text-align: center;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .about__lead__txt {
    font-size: 16px;
  }
}
.about__lead__pic {
  margin: 4rem calc(50% - 50vw) 0;
  padding: 0 calc(50vw - 55%);
}
@media (min-width: 900px) {
  .about__lead__pic {
    margin: 4.29vw auto 0;
    padding: 0;
    width: 44.5vw;
  }
}
@media (min-width: 1400px) {
  .about__lead__pic {
    margin: 60px auto 0;
    width: 623px;
  }
}
.about__history {
  margin: 6rem 0 0;
}
@media (min-width: 900px) {
  .about__history {
    margin: 8.57vw 0 0;
  }
}
@media (min-width: 1400px) {
  .about__history {
    margin: 120px 0 0;
  }
}
.about__history__ttl {
  transform: rotate(0.05deg);
  text-align: center;
  line-height: 1.5;
  font-size: 2.6rem;
}
@media (min-width: 900px) {
  .about__history__ttl {
    font-size: 2.57vw;
  }
}
@media (min-width: 1400px) {
  .about__history__ttl {
    font-size: 36px;
  }
}
.about__history__graph {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .about__history__graph {
    margin: 4.29vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .about__history__graph {
    margin: 60px auto 0;
    max-width: 748px;
  }
}
.about__history__graph__child {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-bottom: solid 1px #D3D3D3;
}
.about__history__graph__child:first-child {
  border-top: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .about__history__graph__child {
    flex-direction: row;
    align-items: center;
    padding: 1.21vw 0;
  }
}
@media (min-width: 1400px) {
  .about__history__graph__child {
    padding: 17px 0;
  }
}
.about__history__graph__child .year {
  transform: rotate(0.05deg);
  color: #AAAAAA;
  font-size: 1.4rem;
}
@media (min-width: 900px) {
  .about__history__graph__child .year {
    padding: 0 0 0 2.86vw;
    font-size: 1.14vw;
    width: 25%;
  }
}
@media (min-width: 1400px) {
  .about__history__graph__child .year {
    padding: 0 0 0 40px;
    font-size: 16px;
  }
}
.about__history__graph__child .detail {
  transform: rotate(0.05deg);
  line-height: 1.5;
  font-size: 1.4rem;
  margin: 1.3rem 0 0;
}
@media (min-width: 900px) {
  .about__history__graph__child .detail {
    margin: 0;
    border-left: solid 1px #D3D3D3;
    padding: 0.71vw 0 0.71vw 2.86vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .about__history__graph__child .detail {
    padding: 10px 0 10px 40px;
    font-size: 16px;
  }
}
.about__organization {
  margin: 6rem 0 0;
}
@media (min-width: 900px) {
  .about__organization {
    margin: 8.57vw 0 0;
  }
}
@media (min-width: 1400px) {
  .about__organization {
    margin: 120px 0 0;
  }
}
.about__organization__ttl {
  transform: rotate(0.05deg);
  text-align: center;
  line-height: 1.5;
  font-size: 2.6rem;
}
@media (min-width: 900px) {
  .about__organization__ttl {
    font-size: 2.57vw;
  }
}
@media (min-width: 1400px) {
  .about__organization__ttl {
    font-size: 36px;
  }
}
.about__organization__graph {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .about__organization__graph {
    margin: 4.29vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .about__organization__graph {
    margin: 60px auto 0;
    max-width: 748px;
  }
}
.about__organization__graph__child {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-bottom: solid 1px #D3D3D3;
}
.about__organization__graph__child:first-child {
  border-top: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .about__organization__graph__child {
    flex-direction: row;
    align-items: center;
    padding: 1.21vw 0;
  }
}
@media (min-width: 1400px) {
  .about__organization__graph__child {
    padding: 17px 0;
  }
}
.about__organization__graph__child .about_post {
  transform: rotate(0.05deg);
  color: #AAAAAA;
  font-size: 1.4rem;
}
@media (min-width: 900px) {
  .about__organization__graph__child .about_post {
    padding: 0 0 0 2.86vw;
    font-size: 1.14vw;
    width: 25%;
  }
}
@media (min-width: 1400px) {
  .about__organization__graph__child .about_post {
    padding: 0 0 0 40px;
    font-size: 16px;
  }
}
.about__organization__graph__child .name {
  transform: rotate(0.05deg);
  line-height: 1.5;
  font-size: 1.4rem;
  margin: 1.3rem 0 0;
}
.about__organization__graph__child .name a {
  display: inline-block;
  transform: rotate(0.05deg);
  color: #65C100 !important;
}
@media (min-width: 900px) {
  .about__organization__graph__child .name a {
    transition: 0.3s;
  }
  .about__organization__graph__child .name a:hover {
    opacity: 0.6;
  }
}
@media (min-width: 900px) {
  .about__organization__graph__child .name {
    margin: 0;
    border-left: solid 1px #D3D3D3;
    padding: 0.71vw 0 0.71vw 2.86vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .about__organization__graph__child .name {
    padding: 10px 0 10px 40px;
    font-size: 16px;
  }
}

.action__list {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .action__list {
    margin: 10.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .action__list {
    margin: 150px 0 0;
  }
}
.action__list__child {
  margin: 0 0 4rem;
}
@media (min-width: 900px) {
  .action__list__child {
    display: flex;
    margin: 0 0 4.29vw;
  }
}
@media (min-width: 1400px) {
  .action__list__child {
    margin: 0 0 60px;
  }
}
.action__list__child:last-child {
  margin: 0;
}
@media (min-width: 900px) {
  .action__list__child__pic {
    width: 25.43vw;
  }
}
@media (min-width: 1400px) {
  .action__list__child__pic {
    width: 356px;
  }
}
.action__list__child__txt {
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .action__list__child__txt {
    margin: 0 0 0 2.57vw;
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .action__list__child__txt {
    margin: 0 0 0 36px;
  }
}
.action__list__child__txt .ttl {
  transform: rotate(0.05deg);
  font-size: 2rem;
}
@media (min-width: 900px) {
  .action__list__child__txt .ttl {
    line-height: 1.5;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .action__list__child__txt .ttl {
    font-size: 28px;
  }
}
.action__list__child__txt .txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 1rem 0 0;
}
@media (min-width: 900px) {
  .action__list__child__txt .txt {
    font-size: 1.14vw;
    margin: 1.79vw 0 0;
  }
}
@media (min-width: 1400px) {
  .action__list__child__txt .txt {
    font-size: 16px;
    margin: 25px 0 0;
  }
}
.action__list__child__txt .action_link {
  transform: rotate(0.05deg);
  position: relative;
  display: inline-block;
  padding: 0 0 0 1.6rem;
  margin: 2rem 0 0;
  font-size: 1.4rem;
  color: #69BA00;
}
.action__list__child__txt .action_link::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-color: #69BA00 #69BA00 transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 0.3rem;
}
@media (min-width: 900px) {
  .action__list__child__txt .action_link {
    padding: 0 0 0 1.43vw;
    margin: 2.14vw 0 0;
    font-size: 1.29vw;
  }
  .action__list__child__txt .action_link::before {
    width: 0.57vw;
    height: 0.57vw;
    top: 30%;
  }
}
@media (min-width: 1400px) {
  .action__list__child__txt .action_link {
    padding: 0 0 0 20px;
    margin: 30px 0 0;
    font-size: 18px;
  }
  .action__list__child__txt .action_link::before {
    width: 8px;
    height: 8px;
    top: 30%;
  }
}

.c-contents_ttl {
  text-align: center;
}
.c-contents_ttl .mark {
  width: 1.5rem;
}
.c-contents_ttl .english {
  transform: rotate(0.05deg);
  display: inline-block;
  color: #65C100;
  font-size: 1.2rem;
  padding: 0 0 0 1rem;
}
.c-contents_ttl .txt {
  transform: rotate(0.05deg);
  display: inline-block;
  padding: 1.5rem 0 0;
  font-weight: 500;
  font-size: 3.4rem;
}
@media (min-width: 900px) {
  .c-contents_ttl .mark {
    width: 1.43vw;
  }
  .c-contents_ttl .english {
    font-size: 1vw;
    padding: 0 0 0 0.71vw;
  }
  .c-contents_ttl .txt {
    padding: 0.71vw 0 0;
    font-size: 3.29vw;
  }
}
@media (min-width: 1400px) {
  .c-contents_ttl .mark {
    width: 20px;
  }
  .c-contents_ttl .english {
    font-size: 14px;
    padding: 0 0 0 10px;
  }
  .c-contents_ttl .txt {
    padding: 10px 0 0;
    font-size: 46px;
  }
}

.c-green_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: #65C100;
  color: #fff !important;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.4rem;
  transform: rotate(0.05deg);
}
.c-green_btn::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  right: 3rem;
}
@media (min-width: 900px) {
  .c-green_btn {
    width: 25.43vw;
    height: 65px;
    border-radius: 50px;
    font-size: 1.29vw;
    margin: 0 auto;
    transition: 0.3s;
  }
  .c-green_btn::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
  }
  .c-green_btn:hover {
    background: #fff;
    border: solid 1px #65C100;
    color: #65C100 !important;
  }
  .c-green_btn:hover::after {
    border-color: #65C100 #65C100 transparent transparent;
  }
}
@media (min-width: 1400px) {
  .c-green_btn {
    width: 356px;
    height: 65px;
    font-size: 18px;
  }
  .c-green_btn::after {
    width: 8px;
    height: 8px;
    right: 25px;
  }
}

.c-d_green_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: #0D7100;
  color: #fff !important;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.4rem;
  transform: rotate(0.05deg);
}
.c-d_green_btn::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  right: 3rem;
}
@media (min-width: 900px) {
  .c-d_green_btn {
    width: 25.43vw;
    height: 65px;
    border-radius: 50px;
    font-size: 1.29vw;
    margin: 0 auto;
    transition: 0.3s;
  }
  .c-d_green_btn::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
  }
  .c-d_green_btn:hover {
    background: #fff;
    border: solid 1px #0D7100;
    color: #0D7100 !important;
  }
  .c-d_green_btn:hover::after {
    border-color: #0D7100 #0D7100 transparent transparent;
  }
}
@media (min-width: 1400px) {
  .c-d_green_btn {
    width: 356px;
    height: 65px;
    font-size: 18px;
  }
  .c-d_green_btn::after {
    width: 8px;
    height: 8px;
    right: 25px;
  }
}

.c-page__ttl {
  margin: 5rem calc(50% - 50vw) 0;
}
@media (min-width: 900px) {
  .c-page__ttl {
    margin: 6.36vw calc(50% - 50vw) 0;
  }
}
@media (min-width: 1400px) {
  .c-page__ttl {
    margin: 89px calc(50% - 50vw) 0;
  }
}
.c-page__ttl__bg {
  position: relative;
  background: #EEF8E2;
}
@media (min-width: 900px) {
  .c-page__ttl__bg {
    background: linear-gradient(0deg, rgb(255, 255, 255) 80%, rgb(238, 248, 226) 80%);
  }
}
.c-page__ttl__bg::before {
  content: "";
  display: block;
  width: 100%;
  height: 6rem;
  background: #EEF8E2;
  border-radius: 0 0 100% 100%;
  position: absolute;
  bottom: -3rem;
  z-index: 0;
}
@media (min-width: 900px) {
  .c-page__ttl__bg::before {
    height: 19.29vw;
    border-radius: 0 0 50% 50%;
    bottom: -7.14vw;
  }
}
@media (min-width: 1400px) {
  .c-page__ttl__bg::before {
    height: 270px;
    bottom: -100px;
  }
}
.c-page__ttl__txt {
  transform: rotate(0.05deg);
  position: relative;
  text-align: center;
  padding: 6rem 2rem 0;
}
@media (min-width: 900px) {
  .c-page__ttl__txt {
    padding: 5.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .c-page__ttl__txt {
    padding: 80px 0 0;
  }
}
.c-page__ttl__txt .mark {
  width: 1.5rem;
}
.c-page__ttl__txt .english {
  display: inline-block;
  color: #65C100;
  font-size: 1.2rem;
  padding: 0 0 0 1rem;
}
.c-page__ttl__txt .txt {
  display: inline-block;
  padding: 0.5rem 0 0;
  font-weight: 500;
  line-height: 1.5;
  font-size: 3.3rem;
}
@media (min-width: 900px) {
  .c-page__ttl__txt .mark {
    width: 1.43vw;
  }
  .c-page__ttl__txt .english {
    font-size: 1vw;
    padding: 0 0 0 10px;
  }
  .c-page__ttl__txt .txt {
    padding: 10px 0 0;
    font-size: 3.29vw;
  }
}
@media (min-width: 1400px) {
  .c-page__ttl__txt .mark {
    width: 20px;
  }
  .c-page__ttl__txt .english {
    font-size: 14px;
    padding: 0 0 0 10px;
  }
  .c-page__ttl__txt .txt {
    padding: 10px 0 0;
    font-size: 46px;
  }
}

.c-page__dg_ttl {
  margin: 5rem calc(50% - 50vw) 0;
}
@media (min-width: 900px) {
  .c-page__dg_ttl {
    margin: 6.36vw calc(50% - 50vw) 0;
  }
}
@media (min-width: 1400px) {
  .c-page__dg_ttl {
    margin: 89px calc(50% - 50vw) 0;
  }
}
.c-page__dg_ttl__bg {
  position: relative;
  background: #0D7100;
}
@media (min-width: 900px) {
  .c-page__dg_ttl__bg {
    background: linear-gradient(0deg, rgb(255, 255, 255) 80%, rgb(13, 113, 0) 80%);
  }
}
.c-page__dg_ttl__bg::before {
  content: "";
  display: block;
  width: 100%;
  height: 6rem;
  background: #0D7100;
  border-radius: 0 0 100% 100%;
  position: absolute;
  bottom: -3rem;
  z-index: 0;
}
@media (min-width: 900px) {
  .c-page__dg_ttl__bg::before {
    height: 270px;
    border-radius: 0 0 50% 50%;
    bottom: -100px;
  }
}
@media (min-width: 1400px) {
  .c-page__dg_ttl__bg::before {
    height: 270px;
    bottom: -100px;
  }
}
.c-page__dg_ttl__txt {
  transform: rotate(0.05deg);
  position: relative;
  text-align: center;
  padding: 6rem 2rem 0;
}
@media (min-width: 900px) {
  .c-page__dg_ttl__txt {
    padding: 5.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .c-page__dg_ttl__txt {
    padding: 80px 0 0;
  }
}
.c-page__dg_ttl__txt .mark {
  width: 1.5rem;
}
.c-page__dg_ttl__txt .english {
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
  padding: 0 0 0 1rem;
}
.c-page__dg_ttl__txt .txt {
  display: inline-block;
  color: #fff;
  padding: 0.5rem 0 0;
  font-weight: 500;
  line-height: 1.5;
  font-size: 3.4rem;
}
@media (min-width: 900px) {
  .c-page__dg_ttl__txt .mark {
    width: 1.43vw;
  }
  .c-page__dg_ttl__txt .english {
    font-size: 1vw;
    padding: 0 0 0 10px;
  }
  .c-page__dg_ttl__txt .txt {
    padding: 10px 0 0;
    font-size: 3.29vw;
  }
}
@media (min-width: 1400px) {
  .c-page__dg_ttl__txt .mark {
    width: 20px;
  }
  .c-page__dg_ttl__txt .english {
    font-size: 14px;
    padding: 0 0 0 10px;
  }
  .c-page__dg_ttl__txt .txt {
    padding: 10px 0 0;
    font-size: 46px;
  }
}

.form__contents {
  margin: 11rem 0 0;
}
@media (min-width: 900px) {
  .form__contents {
    margin: 15vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .form__contents {
    margin: 210px auto 0;
    max-width: 748px;
  }
}
.form__contents__ttl {
  transform: rotate(0.05deg);
  text-align: center;
  font-size: 2.6rem;
}
@media (min-width: 900px) {
  .form__contents__ttl {
    font-size: 2.57vw;
  }
}
@media (min-width: 1400px) {
  .form__contents__ttl {
    font-size: 36px;
  }
}
.form__contents__txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .form__contents__txt {
    font-size: 1.14vw;
    margin: 2.86vw 0 0;
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .form__contents__txt {
    font-size: 16px;
    margin: 40px 0 0;
  }
}
.form__contents__complete_ttl {
  transform: rotate(0.05deg);
  text-align: center;
  font-size: 2.6rem;
  color: #65C100;
}
@media (min-width: 900px) {
  .form__contents__complete_ttl {
    font-size: 2.57vw;
  }
}
@media (min-width: 1400px) {
  .form__contents__complete_ttl {
    font-size: 36px;
  }
}
.form__contents__complete_txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .form__contents__complete_txt {
    text-align: center;
    font-size: 1.14vw;
    margin: 2.86vw 0 0;
  }
}
@media (min-width: 1400px) {
  .form__contents__complete_txt {
    font-size: 16px;
    margin: 40px 0 0;
  }
}
.form__contents__content_check {
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .form__contents__content_check {
    margin: 8.57vw 0 0;
  }
}
@media (min-width: 1400px) {
  .form__contents__content_check {
    margin: 120px 0 0;
  }
}
.form__contents__content_check .recruit__contents__child {
  padding: 2rem 0;
  margin: 0;
  border-bottom: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .form__contents__content_check .recruit__contents__child {
    padding: 2.14vw 0;
  }
}
@media (min-width: 1400px) {
  .form__contents__content_check .recruit__contents__child {
    padding: 30px 0;
  }
}
.form__contents__content_check .recruit__contents__child .input_field.flex_column {
  padding: 0;
}
.form__contents__content_check .recruit__contents__child .ttl .icon {
  display: none;
}
.form__contents__content_check .recruit__contents__child .ttl {
  color: #AAAAAA;
  padding: 0;
}
.form__contents__content_check .recruit__contents__child .input_field {
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .form__contents__content_check .recruit__contents__child .input_field {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .form__contents__content_check .recruit__contents__child .input_field {
    font-size: 16px;
  }
}
.form__contents__content_check .recruit__contents__child .input_field .annotation {
  display: none;
}
.form__contents__content_check .contact__contents__child {
  padding: 2rem 0;
  margin: 0;
  border-bottom: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .form__contents__content_check .contact__contents__child {
    padding: 2.14vw 0;
  }
}
@media (min-width: 1400px) {
  .form__contents__content_check .contact__contents__child {
    padding: 30px 0;
  }
}
.form__contents__content_check .contact__contents__child .ttl .icon {
  display: none;
}
.form__contents__content_check .contact__contents__child .ttl {
  color: #AAAAAA;
  padding: 0;
}
.form__contents__content_check .contact__contents__child .input_field {
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .form__contents__content_check .contact__contents__child .input_field {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .form__contents__content_check .contact__contents__child .input_field {
    font-size: 16px;
  }
}
.form__contents input[type=text] {
  width: 100%;
  font-size: 1.3rem;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
@media (min-width: 900px) {
  .form__contents input[type=text] {
    font-size: 1.14vw;
    border-radius: 2.14vw;
    padding: 0.36vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .form__contents input[type=text] {
    font-size: 16px;
    border-radius: 30px;
    padding: 5px 20px;
  }
}
.form__contents input[type=email] {
  width: 100%;
  font-size: 1.3rem;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
@media (min-width: 900px) {
  .form__contents input[type=email] {
    font-size: 1.14vw;
    border-radius: 2.14vw;
    padding: 0.36vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .form__contents input[type=email] {
    font-size: 16px;
    border-radius: 30px;
    padding: 5px 20px;
  }
}
.form__contents textarea {
  width: 100%;
  font-size: 1.3rem;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
@media (min-width: 900px) {
  .form__contents textarea {
    font-size: 1.14vw;
    border-radius: 1.43vw;
    padding: 0.71vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .form__contents textarea {
    font-size: 16px;
    border-radius: 20px;
    padding: 10px 20px;
  }
}
.form__contents span {
  line-height: 1;
}
.form__contents input[type=email] {
  width: 100%;
}
.form__contents input[type=submit] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: #65C100;
  color: #fff !important;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.4rem;
  border: none;
}
.form__contents input[type=submit]::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  right: 3rem;
}
@media (min-width: 900px) {
  .form__contents input[type=submit] {
    width: 25.43vw;
    height: 65px;
    border-radius: 50px;
    font-size: 1.29vw;
    margin: 0 auto;
    transition: 0.3s;
  }
  .form__contents input[type=submit]::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
  }
  .form__contents input[type=submit]:hover {
    background: #fff;
    border: solid 1px #65C100;
    color: #65C100 !important;
  }
  .form__contents input[type=submit]:hover::after {
    border-color: #65C100 #65C100 transparent transparent;
  }
}
.form__contents .mwform-checkbox-field label, .form__contents .mwform-radio-field label {
  display: block;
}

.pager_list {
  display: flex;
  justify-content: center;
  margin: 5rem auto 0;
}
@media (min-width: 900px) {
  .pager_list {
    margin: 5vw auto 0;
  }
}
@media (min-width: 1400px) {
  .pager_list {
    margin: 70px auto 0;
  }
}
.pager_list .wp-pagenavi {
  display: flex;
  align-items: center;
  gap: 0 1rem;
  font-size: 1.3rem;
  font-weight: 400;
}
@media (min-width: 900px) {
  .pager_list .wp-pagenavi {
    gap: 0 0.71vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .pager_list .wp-pagenavi {
    gap: 0 10px;
    font-size: 16px;
  }
}
.pager_list .wp-pagenavi .current {
  transform: rotate(0.05deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #65C100;
  color: #fff;
  border: none;
  font-weight: 400;
}
@media (min-width: 900px) {
  .pager_list .wp-pagenavi .current {
    width: 3.57vw;
    height: 3.57vw;
  }
}
@media (min-width: 1400px) {
  .pager_list .wp-pagenavi .current {
    width: 50px;
    height: 50px;
  }
}
.pager_list .wp-pagenavi .page, .pager_list .wp-pagenavi .nextpostslink, .pager_list .wp-pagenavi .previouspostslink {
  transform: rotate(0.05deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #EEF8E2;
  color: #65C100;
  border: none;
}
@media (min-width: 900px) {
  .pager_list .wp-pagenavi .page, .pager_list .wp-pagenavi .nextpostslink, .pager_list .wp-pagenavi .previouspostslink {
    width: 3.57vw;
    height: 3.57vw;
    transition: 0.3s;
  }
  .pager_list .wp-pagenavi .page:hover, .pager_list .wp-pagenavi .nextpostslink:hover, .pager_list .wp-pagenavi .previouspostslink:hover {
    background: #65C100;
    color: #fff;
  }
  .pager_list .wp-pagenavi .page:hover .previous_arrow::before, .pager_list .wp-pagenavi .nextpostslink:hover .previous_arrow::before, .pager_list .wp-pagenavi .previouspostslink:hover .previous_arrow::before {
    border-color: transparent transparent #fff #fff;
  }
  .pager_list .wp-pagenavi .page:hover .next_arrow::before, .pager_list .wp-pagenavi .nextpostslink:hover .next_arrow::before, .pager_list .wp-pagenavi .previouspostslink:hover .next_arrow::before {
    border-color: #fff #fff transparent transparent;
  }
}
@media (min-width: 1400px) {
  .pager_list .wp-pagenavi .page, .pager_list .wp-pagenavi .nextpostslink, .pager_list .wp-pagenavi .previouspostslink {
    width: 50px;
    height: 50px;
  }
}
.pager_list .wp-pagenavi .previouspostslink .previous_arrow {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
}
.pager_list .wp-pagenavi .previouspostslink .previous_arrow::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: transparent transparent #65C100 #65C100;
  transform: rotate(45deg);
}
@media (min-width: 900px) {
  .pager_list .wp-pagenavi .previouspostslink .previous_arrow::before {
    width: 0.57vw;
    height: 0.57vw;
  }
}
@media (min-width: 1400px) {
  .pager_list .wp-pagenavi .previouspostslink .previous_arrow::before {
    width: 8px;
    height: 8px;
  }
}
.pager_list .wp-pagenavi .nextpostslink .next_arrow {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
}
.pager_list .wp-pagenavi .nextpostslink .next_arrow::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: #65C100 #65C100 transparent transparent;
  transform: rotate(45deg);
}
@media (min-width: 900px) {
  .pager_list .wp-pagenavi .nextpostslink .next_arrow::before {
    width: 0.57vw;
    height: 0.57vw;
  }
}
@media (min-width: 1400px) {
  .pager_list .wp-pagenavi .nextpostslink .next_arrow::before {
    width: 8px;
    height: 8px;
  }
}
.pager_list .wp-pagenavi .extend {
  transform: rotate(0.05deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 4rem;
  border-radius: 50%;
  background: #fff;
  color: #AAAAAA;
  border: none;
}
@media (min-width: 900px) {
  .pager_list .wp-pagenavi .extend {
    width: 1.79vw;
    height: 3.57vw;
  }
}
@media (min-width: 1400px) {
  .pager_list .wp-pagenavi .extend {
    width: 25px;
    height: 50px;
  }
}

.common__contents {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .common__contents {
    margin: 10.71vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .common__contents {
    margin: 150px auto 0;
    max-width: 748px;
  }
}

.privacypolicy__txt {
  line-height: 1.5;
  font-feature-settings: "palt";
  margin: 5rem 0;
  font-size: 1.4rem;
}
.privacypolicy__txt .ttl {
  font-weight: bold;
}
.privacypolicy__txt .txt01 {
  text-indent: -2.5em;
  padding-left: 3em;
  display: inline-block;
}
.privacypolicy__txt .txt02 {
  text-indent: -1.8em;
  padding-left: 6em;
  display: inline-block;
}
@media (min-width: 900px) {
  .privacypolicy__txt {
    line-height: 2;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .privacypolicy__txt {
    font-size: 16px;
  }
}

.contact__contents {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .contact__contents {
    margin: 10.71vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents {
    margin: 150px auto 0;
    max-width: 748px;
  }
}
.contact__contents__ttl {
  transform: rotate(0.05deg);
  line-height: 1.5;
  font-size: 2.6rem;
  text-align: center;
}
@media (min-width: 900px) {
  .contact__contents__ttl {
    font-size: 2.57vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents__ttl {
    font-size: 36px;
  }
}
.contact__contents__txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .contact__contents__txt {
    text-align: center;
    margin: 2.86vw 0 0;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents__txt {
    margin: 40px 0 0;
    font-size: 16px;
  }
}
.contact__contents__contact_ttl {
  transform: rotate(0.05deg);
  text-align: center;
  font-size: 2rem;
  border-bottom: solid 1px #D3D3D3;
  margin: 6rem 0 0;
  padding: 0 0 1rem;
}
@media (min-width: 900px) {
  .contact__contents__contact_ttl {
    margin: 8.57vw 0 0;
    font-size: 2vw;
    padding: 0 0 1.43vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents__contact_ttl {
    margin: 120px 0 0;
    font-size: 28px;
    padding: 0 0 20px;
  }
}
.contact__contents__wrap {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .contact__contents__wrap {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .contact__contents__wrap {
    margin: 60px 0 0;
  }
}
.contact__contents__child {
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 0;
}
.contact__contents__child:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .contact__contents__child {
    flex-direction: row;
    align-items: flex-start;
    margin: 2.14vw 0 0;
  }
}
@media (min-width: 1400px) {
  .contact__contents__child {
    margin: 30px 0 0;
  }
}
.contact__contents__child .ttl {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .contact__contents__child .ttl {
    width: 40%;
    font-size: 1.14vw;
    padding: 0.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .contact__contents__child .ttl {
    font-size: 16px;
    padding: 10px 0 0;
  }
}
.contact__contents__child .ttl .icon {
  transform: rotate(0.05deg);
  display: inline-block;
  color: #D60000;
  margin: 0 0 0 1rem;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .contact__contents__child .ttl .icon {
    margin: 0 0 0 1.07vw;
    font-size: 1vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents__child .ttl .icon {
    margin: 0 0 0 15px;
    font-size: 14px;
  }
}
.contact__contents__child .input_field {
  margin: 1.5rem 0 0;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .contact__contents__child .input_field {
    margin: 0;
    width: 60%;
    font-size: 1.14vw;
  }
  .contact__contents__child .input_field.flex_column {
    display: flex;
    gap: 0 5vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents__child .input_field {
    font-size: 16px;
  }
  .contact__contents__child .input_field.flex_column {
    gap: 0 70px;
  }
}
.contact__contents__consent {
  margin: 2rem auto 0;
  display: flex;
  justify-content: center;
}
@media (min-width: 900px) {
  .contact__contents__consent {
    margin: 3.57vw auto 0;
  }
}
@media (min-width: 1400px) {
  .contact__contents__consent {
    margin: 50px auto 0;
  }
}
.contact__contents__consent a {
  color: #65C100 !important;
}
.contact__contents__submit {
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .contact__contents__submit {
    margin: 4.29vw auto 0;
  }
}
@media (min-width: 1400px) {
  .contact__contents__submit {
    margin: 60px auto 0;
  }
}
.contact__contents input[type=text] {
  width: 100%;
  font-size: medium;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
.contact__contents input[type=text]::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .contact__contents input[type=text] {
    font-size: 1.14vw;
    border-radius: 2.14vw;
    padding: 0.36vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents input[type=text] {
    font-size: 16px;
    border-radius: 30px;
    padding: 5px 20px;
  }
}
.contact__contents input[type=email] {
  width: 100%;
  font-size: medium;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
.contact__contents input[type=email]::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .contact__contents input[type=email] {
    font-size: 1.14vw;
    border-radius: 2.14vw;
    padding: 0.36vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents input[type=email] {
    font-size: 16px;
    border-radius: 30px;
    padding: 5px 20px;
  }
}
.contact__contents textarea {
  width: 100%;
  font-size: medium;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
.contact__contents textarea::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .contact__contents textarea {
    font-size: 1.14vw;
    border-radius: 1.43vw;
    padding: 0.71vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .contact__contents textarea {
    font-size: 16px;
    border-radius: 20px;
    padding: 10px 20px;
  }
}
.contact__contents span {
  line-height: 1;
}
.contact__contents input[type=email] {
  width: 100%;
}
.contact__contents input[type=submit] {
  transform: rotate(0.05deg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: #65C100;
  color: #fff !important;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.4rem;
  border: none;
}
.contact__contents input[type=submit]::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  right: 3rem;
}
@media (min-width: 900px) {
  .contact__contents input[type=submit] {
    width: 25.43vw;
    height: 65px;
    border-radius: 50px;
    font-size: 1.29vw;
    margin: 0 auto;
    transition: 0.3s;
  }
  .contact__contents input[type=submit]::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
  }
  .contact__contents input[type=submit]:hover {
    background: #fff;
    border: solid 1px #65C100;
    color: #65C100 !important;
  }
  .contact__contents input[type=submit]:hover::after {
    border-color: #65C100 #65C100 transparent transparent;
  }
}
@media (min-width: 1400px) {
  .contact__contents input[type=submit] {
    width: 356px;
    height: 65px;
    font-size: 18px;
  }
  .contact__contents input[type=submit]::after {
    width: 8px;
    height: 8px;
    right: 25px;
  }
}
.contact__contents .mwform-checkbox-field label, .contact__contents .mwform-radio-field label {
  display: block;
}

.information__category_list {
  margin: 8rem 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}
@media (min-width: 900px) {
  .information__category_list {
    margin: 10.71vw 0 0;
    gap: 0 2.57vw;
  }
}
@media (min-width: 1400px) {
  .information__category_list {
    margin: 150px 0 0;
    gap: 0 36px;
  }
}
.information__category_list__child {
  width: 14.7rem;
}
@media (min-width: 900px) {
  .information__category_list__child {
    width: 18.43vw;
  }
}
@media (min-width: 1400px) {
  .information__category_list__child {
    width: 258px;
  }
}
.information__category_list__child__link {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  color: #65C100;
  text-decoration: none;
  font-size: 1.3rem;
  height: 4rem;
  border-radius: 50px;
}
.information__category_list__child__link:visited {
  color: #65C100;
}
.information__category_list__child__link.is-current {
  background: #65C100;
  color: #fff;
}
@media (min-width: 900px) {
  .information__category_list__child__link {
    font-size: 1.14vw;
    height: 3.57vw;
    transition: 0.3s;
  }
  .information__category_list__child__link:hover {
    color: #fff;
    background: #65C100;
  }
  .information__category_list__child__link.is-current:hover {
    color: #fff;
  }
}
@media (min-width: 1400px) {
  .information__category_list__child__link {
    font-size: 16px;
    height: 50px;
  }
}
.information__list {
  margin: 4rem 0 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .information__list {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .information__list {
    margin: 60px 0 0;
  }
}
.information__list__child {
  padding: 2rem 0;
  border-bottom: solid 1px #D3D3D3;
}
.information__list__child:first-child {
  border-top: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .information__list__child {
    padding: 2.14vw 0;
  }
}
@media (min-width: 1400px) {
  .information__list__child {
    padding: 30px 0;
  }
}
@media (min-width: 900px) {
  .information__list__child__link {
    display: flex;
    align-items: center;
  }
}
.information__list__child__link .list_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.information__list__child__link .list_wrap .date {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .information__list__child__link .list_wrap .date {
    margin: 0 0 0 2.86vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .information__list__child__link .list_wrap .date {
    margin: 0 0 0 40px;
    font-size: 16px;
  }
}
.information__list__child__link .list_wrap .term {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  color: #65C100;
  border-radius: 50px;
  width: 14rem;
  height: 2rem;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .information__list__child__link .list_wrap .term {
    margin: 0 0 0 4.29vw;
    width: 11.43vw;
    height: 2.07vw;
    font-size: 1vw;
  }
}
@media (min-width: 1400px) {
  .information__list__child__link .list_wrap .term {
    margin: 0 0 0 60px;
    width: 160px;
    height: 29px;
    font-size: 14px;
  }
}
.information__list__child__link .link {
  transform: rotate(0.05deg);
  line-height: 1.5;
  display: inline-block;
  margin: 1.4rem 0 0;
  font-size: 1.3rem;
}
.information__list__child__link .link:visited {
  color: #65C100 !important;
}
@media (min-width: 900px) {
  .information__list__child__link .link {
    transition: 0.3s;
    margin: 0 0 0 2.5vw;
    font-size: 1.14vw;
  }
  .information__list__child__link .link:hover {
    opacity: 0.6;
    color: #65C100;
  }
}
@media (min-width: 1400px) {
  .information__list__child__link .link {
    margin: 0 0 0 35px;
    font-size: 16px;
  }
}

.information_detail {
  margin: 5rem 0 0;
}
@media (min-width: 900px) {
  .information_detail {
    margin: 6.36vw 0 0;
  }
}
@media (min-width: 1400px) {
  .information_detail {
    margin: 89px 0 0;
  }
}
.information_detail__ttl {
  transform: rotate(0.05deg);
  margin: 6rem 0 0;
  line-height: 1.5;
  font-size: 2.6rem;
  padding: 0 0 2rem;
  border-bottom: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .information_detail__ttl {
    max-width: 53.43vw;
    margin: 8.57vw auto 0;
    font-size: 2.57vw;
    padding: 0 0 2.5vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__ttl {
    max-width: 748px;
    margin: 120px auto 0;
    font-size: 36px;
    padding: 0 0 35px;
  }
}
.information_detail__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 0;
}
@media (min-width: 900px) {
  .information_detail__top {
    max-width: 53.43vw;
    margin: 1.43vw auto 0;
    justify-content: flex-start;
    gap: 0 3.93vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__top {
    max-width: 748px;
    margin: 20px auto 0;
    gap: 0 55px;
  }
}
.information_detail__top .date {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .information_detail__top .date {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__top .date {
    font-size: 16px;
  }
}
.information_detail__top .category {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: #EEF8E2;
  color: #65C100;
  border-radius: 50px;
  width: 14rem;
  height: 2rem;
  font-size: 1.2rem;
}
@media (max-width: 899px) {
  .information_detail__top .category {
    margin: 0 0 0 0.5rem;
  }
}
@media (min-width: 900px) {
  .information_detail__top .category {
    width: 11.43vw;
    height: 2.07vw;
    font-size: 1vw;
    transition: 0.3s;
  }
  .information_detail__top .category:hover {
    background: #65C100;
    color: #fff;
  }
}
@media (min-width: 1400px) {
  .information_detail__top .category {
    width: 160px;
    height: 29px;
    font-size: 14px;
  }
}
.information_detail__contents {
  margin: 6rem 0 0;
  padding: 0 0 6rem;
  border-bottom: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .information_detail__contents {
    max-width: 53.43vw;
    margin: 8.57vw auto 0;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents {
    max-width: 748px;
    margin: 120px auto 0;
    padding: 0 0 120px;
  }
}
.information_detail__contents h1 {
  transform: rotate(0.05deg);
  line-height: 1.5;
  margin: 4rem 0 0;
  font-size: 2rem;
}
.information_detail__contents h1:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .information_detail__contents h1 {
    margin: 4.29vw 0 0;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents h1 {
    margin: 60px 0 0;
    font-size: 28px;
  }
}
.information_detail__contents h2 {
  transform: rotate(0.05deg);
  line-height: 1.5;
  margin: 4rem 0 0;
  font-size: 1.8rem;
}
.information_detail__contents h2:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .information_detail__contents h2 {
    margin: 1.79vw 0 0;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents h2 {
    margin: 60px 0 0;
    font-size: 25px;
  }
}
.information_detail__contents h3 {
  transform: rotate(0.05deg);
  line-height: 1.5;
  margin: 4rem 0 0;
  font-size: 1.5rem;
}
.information_detail__contents h3:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .information_detail__contents h3 {
    margin: 1.64vw 0 0;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents h3 {
    margin: 60px 0 0;
    font-size: 23px;
  }
}
.information_detail__contents p {
  transform: rotate(0.05deg);
  line-height: 2;
  margin: 1rem 0 0;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .information_detail__contents p {
    margin: 1.43vw 0 0;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents p {
    margin: 20px 0 0;
    font-size: 16px;
  }
}
.information_detail__contents .annotation {
  transform: rotate(0.05deg);
  position: relative;
  color: #AAAAAA;
  line-height: 2;
  margin: 1rem 0 0;
  font-size: 1.1rem;
  padding: 0 0 0 1.2em;
}
.information_detail__contents .annotation::before {
  content: "※";
  display: block;
  position: absolute;
  left: 0;
}
@media (min-width: 900px) {
  .information_detail__contents .annotation {
    margin: 0.86vw 0 0;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents .annotation {
    margin: 20px 0 0;
    font-size: 12px;
  }
}
.information_detail__contents img {
  display: block;
  margin: 4rem 0 0;
}
.information_detail__contents img:first-child {
  margin: 0 auto;
}
@media (min-width: 900px) {
  .information_detail__contents img {
    width: 39.43vw;
    margin: 1.43vw auto 0;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents img {
    width: 552px;
    margin: 20px auto 0;
  }
}
.information_detail__contents a {
  transform: rotate(0.05deg);
  position: relative;
  display: inline-block;
  color: #65C100;
  padding: 0 0 0 1.6rem;
  font-size: 1.4rem;
}
.information_detail__contents a::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-color: #65C100 #65C100 transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 35%;
}
.information_detail__contents a:visited {
  color: #65C100;
}
@media (min-width: 900px) {
  .information_detail__contents a {
    padding: 0 0 0 1.43vw;
    font-size: 1.29vw;
  }
  .information_detail__contents a::before {
    width: 0.57vw;
    height: 0.57vw;
    top: 40%;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents a {
    padding: 0 0 0 20px;
    font-size: 18px;
  }
  .information_detail__contents a::before {
    width: 8px;
    height: 8px;
    top: 40%;
  }
}
.information_detail__contents ul {
  margin: 4rem 0 0;
}
.information_detail__contents ul:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .information_detail__contents ul {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents ul {
    margin: 60px 0 0;
  }
}
.information_detail__contents ul li {
  transform: rotate(0.05deg);
  position: relative;
  line-height: 2;
  font-size: 1.3rem;
  margin: 0 0 1rem;
  padding-left: 1.3em;
}
.information_detail__contents ul li:last-child {
  margin: 0;
}
.information_detail__contents ul li::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  background: #65C100;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 20%;
}
@media (min-width: 900px) {
  .information_detail__contents ul li {
    font-size: 1.14vw;
    margin: 0 0 0.71vw;
    padding-left: 1.21vw;
  }
  .information_detail__contents ul li::before {
    width: 0.71vw;
    height: 0.71vw;
    top: 35%;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents ul li {
    font-size: 16px;
    margin: 0 0 10px;
    padding-left: 17px;
  }
  .information_detail__contents ul li::before {
    width: 10px;
    height: 10px;
  }
}
.information_detail__contents ol {
  margin: 4rem 0 0;
  counter-reset: item;
  list-style-type: none;
}
.information_detail__contents ol:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .information_detail__contents ol {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents ol {
    margin: 60px 0 0;
  }
}
.information_detail__contents ol li {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 0 0 1rem;
  text-indent: -1.3em;
  padding-left: 1.3em;
}
.information_detail__contents ol li:last-child {
  margin: 0;
}
.information_detail__contents ol li:before {
  counter-increment: item;
  content: counter(item) ".";
  padding-right: 0.5em;
  font-weight: bold;
  color: #65C100;
}
@media (min-width: 900px) {
  .information_detail__contents ol li {
    font-size: 1.14vw;
    margin: 0 0 0.71vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__contents ol li {
    font-size: 16px;
    margin: 0 0 10px;
  }
}
.information_detail__next_prev_btn {
  display: flex;
  justify-content: center;
  gap: 0 6rem;
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn {
    gap: 0 8.57vw;
    margin: 2.14vw 0 0;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn {
    gap: 0 120px;
    margin: 30px 0 0;
  }
}
.information_detail__next_prev_btn__child {
  position: relative;
}
.information_detail__next_prev_btn__child a {
  text-decoration: none;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 0 1rem;
  transition: 0.3s;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn__child a {
    gap: 0 0.71vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn__child a {
    gap: 0 10px;
  }
}
.information_detail__next_prev_btn__child a:visited {
  color: #65C100 !important;
}
.information_detail__next_prev_btn__child a:hover .circle_next {
  background: #fff;
  border: solid 1px #65C100;
}
.information_detail__next_prev_btn__child a:hover .circle_next::before {
  border-color: transparent transparent #65C100 #65C100;
}
.information_detail__next_prev_btn__child a:hover .circle_prev {
  background: #fff;
  border: solid 1px #65C100;
}
.information_detail__next_prev_btn__child a:hover .circle_prev::before {
  border-color: #65C100 #65C100 transparent transparent;
}
.information_detail__next_prev_btn__child:first-child a::after {
  content: "";
  display: block;
  width: 1px;
  height: 2rem;
  background: #D3D3D3;
  position: absolute;
  right: -3rem;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn__child:first-child a::after {
    height: 2.14vw;
    right: -4.29vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn__child:first-child a::after {
    height: 30px;
    right: -60px;
  }
}
.information_detail__next_prev_btn__child .txt {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn__child .txt {
    font-size: 1.29vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn__child .txt {
    font-size: 18px;
  }
}
.information_detail__next_prev_btn__child .pic {
  width: 4rem;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn__child .pic {
    width: 3.57vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn__child .pic {
    width: 50px;
  }
}
.information_detail__next_prev_btn__child .circle_next {
  position: relative;
  transition: 0.3s;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #65C100;
}
.information_detail__next_prev_btn__child .circle_next::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: transparent transparent #fff #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 40%;
  right: 35%;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn__child .circle_next {
    width: 3.57vw;
    height: 3.57vw;
  }
  .information_detail__next_prev_btn__child .circle_next::before {
    width: 0.57vw;
    height: 0.57vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn__child .circle_next {
    width: 50px;
    height: 50px;
  }
  .information_detail__next_prev_btn__child .circle_next::before {
    width: 8px;
    height: 8px;
  }
}
.information_detail__next_prev_btn__child .circle_prev {
  position: relative;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #65C100;
}
.information_detail__next_prev_btn__child .circle_prev::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  top: 40%;
  right: 43%;
}
@media (min-width: 900px) {
  .information_detail__next_prev_btn__child .circle_prev {
    width: 3.57vw;
    height: 3.57vw;
  }
  .information_detail__next_prev_btn__child .circle_prev::before {
    width: 0.57vw;
    height: 0.57vw;
  }
}
@media (min-width: 1400px) {
  .information_detail__next_prev_btn__child .circle_prev {
    width: 50px;
    height: 50px;
  }
  .information_detail__next_prev_btn__child .circle_prev::before {
    width: 8px;
    height: 8px;
  }
}
.information_detail__btn {
  display: flex;
  flex-direction: column;
  margin: 6rem 0 0;
  gap: 2rem 0;
}
@media (min-width: 900px) {
  .information_detail__btn {
    margin: 8.57vw 0 0;
    flex-direction: row;
    justify-content: center;
    gap: 0 2.14vw;
  }
}
@media (min-width: 900px) {
  .information_detail__btn {
    margin: 120px 0 0;
    gap: 0 30px;
  }
}
@media (min-width: 900px) {
  .information_detail__btn__child {
    margin: 0;
  }
  .information_detail__btn__child:last-child {
    margin: 0;
  }
}

.information_category__ttl {
  margin: 5rem0 0;
}
@media (min-width: 900px) {
  .information_category__ttl {
    margin: 89px 0 0;
  }
}
.information_category__ttl__txt {
  text-align: center;
  padding: 6rem 2rem 0;
}
@media (min-width: 900px) {
  .information_category__ttl__txt {
    padding: 80px 0 0;
  }
}
.information_category__ttl__txt .txt {
  transform: rotate(0.05deg);
  display: inline-block;
  padding: 0.5rem 0 0;
  font-weight: 500;
  line-height: 1.5;
  font-size: 3.2rem;
}
@media (min-width: 900px) {
  .information_category__ttl__txt .txt {
    padding: 10px 0 0;
    font-size: 3.29vw;
  }
}
.information_category__category_list {
  margin: 4rem 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}
@media (min-width: 900px) {
  .information_category__category_list {
    margin: 4.29vw 0 0;
    gap: 0 2.57vw;
  }
}
@media (min-width: 1400px) {
  .information_category__category_list {
    margin: 60px 0 0;
    gap: 0 36px;
  }
}
.information_category__category_list__child {
  width: 14.7rem;
}
@media (min-width: 900px) {
  .information_category__category_list__child {
    width: 18.43vw;
  }
}
@media (min-width: 1400px) {
  .information_category__category_list__child {
    width: 258px;
  }
}
.information_category__category_list__child__link {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  color: #65C100;
  text-decoration: none;
  font-size: 1.3rem;
  height: 4rem;
  border-radius: 50px;
}
.information_category__category_list__child__link:visited {
  color: #65C100;
}
.information_category__category_list__child__link.is-current {
  background: #65C100;
  color: #fff;
}
.information_category__category_list__child__link.current {
  background: #65C100;
  color: #fff;
}
@media (min-width: 900px) {
  .information_category__category_list__child__link {
    font-size: 1.14vw;
    height: 3.57vw;
    transition: 0.3s;
  }
  .information_category__category_list__child__link:hover {
    color: #fff;
    background: #65C100;
  }
  .information_category__category_list__child__link.is-current:hover {
    color: #fff;
  }
}
@media (min-width: 1400px) {
  .information_category__category_list__child__link {
    font-size: 16px;
    height: 50px;
  }
}
.information_category__list {
  margin: 4rem 0 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .information_category__list {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .information_category__list {
    margin: 60px 0 0;
  }
}
.information_category__list__child {
  padding: 2rem 0;
  border-bottom: solid 1px #D3D3D3;
}
.information_category__list__child:first-child {
  border-top: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .information_category__list__child {
    padding: 2.14vw 0;
  }
}
@media (min-width: 1400px) {
  .information_category__list__child {
    padding: 30px 0;
  }
}
@media (min-width: 900px) {
  .information_category__list__child__link {
    display: flex;
    align-items: center;
  }
}
.information_category__list__child__link .list_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.information_category__list__child__link .list_wrap .date {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .information_category__list__child__link .list_wrap .date {
    margin: 0 0 0 2.86vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .information_category__list__child__link .list_wrap .date {
    margin: 0 0 0 40px;
    font-size: 16px;
  }
}
.information_category__list__child__link .list_wrap .term {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  color: #65C100;
  border-radius: 50px;
  width: 14rem;
  height: 2rem;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .information_category__list__child__link .list_wrap .term {
    margin: 0 0 0 4.29vw;
    width: 11.43vw;
    height: 2.07vw;
    font-size: 1vw;
  }
}
@media (min-width: 1400px) {
  .information_category__list__child__link .list_wrap .term {
    margin: 0 0 0 60px;
    width: 160px;
    height: 29px;
    font-size: 14px;
  }
}
.information_category__list__child__link .link {
  transform: rotate(0.05deg);
  line-height: 1.5;
  display: inline-block;
  margin: 1.4rem 0 0;
  font-size: 1.3rem;
}
.information_category__list__child__link .link:visited {
  color: #65C100 !important;
}
@media (min-width: 900px) {
  .information_category__list__child__link .link {
    transition: 0.3s;
    margin: 0 0 0 2.5vw;
    font-size: 1.14vw;
  }
  .information_category__list__child__link .link:hover {
    opacity: 0.6;
    color: #65C100;
  }
}
@media (min-width: 1400px) {
  .information_category__list__child__link .link {
    margin: 0 0 0 35px;
    font-size: 16px;
  }
}
.information_category__btn {
  display: flex;
  flex-direction: column;
  margin: 6rem 0 0;
}
@media (min-width: 900px) {
  .information_category__btn {
    margin: 8.57vw 0 0;
    flex-direction: row;
    justify-content: center;
    gap: 0 2.14vw;
  }
}
@media (min-width: 900px) {
  .information_category__btn {
    margin: 120px 0 0;
    gap: 0 30px;
  }
}
.information_category__btn__child:last-child {
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .information_category__btn__child {
    margin: 0;
  }
  .information_category__btn__child:last-child {
    margin: 0;
  }
}

.member_top__login {
  margin: 9rem 0 0;
}
@media (min-width: 900px) {
  .member_top__login {
    margin: 12.14vw 0 0;
  }
}
@media (min-width: 1400px) {
  .member_top__login {
    margin: 170px 0 0;
  }
}
.member_top__login #error_message {
  margin: 0 0 3rem;
}
@media (min-width: 900px) {
  .member_top__login #error_message {
    margin: 0 0 2.14vw;
  }
}
@media (min-width: 1400px) {
  .member_top__login #error_message {
    margin: 0 0 30px;
  }
}
.member_top__login #error_message p {
  text-align: center;
  color: #D60000;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .member_top__login #error_message p {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .member_top__login #error_message p {
    font-size: 16px;
  }
}
.member_top__login__input {
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
}
@media (min-width: 900px) {
  .member_top__login__input {
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .member_top__login__input {
    gap: 20px 0;
  }
}
.member_top__login__input__child {
  display: flex;
  align-items: center;
  background: #EEF8E2;
  border-radius: 1rem;
  height: 6rem;
  border: solid 1px rgba(101, 193, 0, 0.2);
}
@media (min-width: 900px) {
  .member_top__login__input__child {
    height: 4.29vw;
    width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .member_top__login__input__child {
    height: 60px;
    width: 748px;
  }
}
.member_top__login__input__child .txt {
  transform: rotate(0.05deg);
  position: relative;
  color: #65C100;
  font-size: 1.3rem;
  padding: 0 2rem;
  width: 35%;
}
.member_top__login__input__child .txt::after {
  content: "";
  display: block;
  width: 1px;
  height: 4rem;
  background: #65C100;
  position: absolute;
  right: 1rem;
  top: -1.3rem;
}
@media (min-width: 900px) {
  .member_top__login__input__child .txt {
    width: 30%;
    font-size: 1.14vw;
    padding: 0 3.93vw;
  }
  .member_top__login__input__child .txt::after {
    height: 2.86vw;
    right: 2.86vw;
    top: -0.57vw;
  }
}
@media (min-width: 1400px) {
  .member_top__login__input__child .txt {
    font-size: 16px;
    padding: 0 55px;
  }
  .member_top__login__input__child .txt::after {
    height: 40px;
    right: 40px;
    top: -8px;
  }
}
.member_top__login__input__child .frame {
  width: 65%;
}
.member_top__login__input__child .frame input[type=text] {
  border: none;
  background: #EEF8E2 !important;
  border-radius: 3px;
  padding: 3px;
  width: 20rem;
  font-size: medium;
}
.member_top__login__input__child .frame input[type=text]::placeholder {
  color: #D3D3D3;
}
.member_top__login__input__child .frame input[type=password] {
  border: none;
  background: #EEF8E2 !important;
  border-radius: 3px;
  padding: 3px;
  width: 20rem;
  font-size: medium;
}
.member_top__login__input__child .frame input[type=password]::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .member_top__login__input__child .frame input[type=text] {
    width: 100%;
    font-size: 1.14vw;
  }
  .member_top__login__input__child .frame input[type=password] {
    width: 100%;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .member_top__login__input__child .frame input[type=text] {
    width: 500px;
    font-size: 16px;
  }
  .member_top__login__input__child .frame input[type=password] {
    width: 500px;
    font-size: 16px;
  }
}
.member_top__login__forgetmenot {
  transform: rotate(0.05deg);
  margin: 3rem auto 0;
  text-align: center;
}
@media (min-width: 900px) {
  .member_top__login__forgetmenot {
    margin: 2.86vw auto 0;
  }
}
@media (min-width: 1400px) {
  .member_top__login__forgetmenot {
    margin: 40px auto 0;
  }
}
.member_top__login__forgetmenot input[type=checkbox] {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(101, 193, 0, 0.2);
  background: #EEF8E2;
  vertical-align: -2px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.member_top__login__forgetmenot input[type=checkbox]:checked:before {
  position: absolute;
  top: 1px;
  left: 4px;
  transform: rotate(50deg);
  width: 5px;
  height: 9px;
  border-right: 2px solid #65C100;
  border-bottom: 2px solid #65C100;
  content: "";
}
.member_top__login__btn {
  transform: rotate(0.05deg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: #0D7100 !important;
  border: none !important;
  color: #fff !important;
  text-decoration: none;
  border-radius: 50px !important;
  font-size: 1.4rem;
  margin: 3rem auto 0;
}
.member_top__login__btn::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #fff #fff transparent transparent !important;
  transform: rotate(45deg);
  position: absolute;
  right: 3rem;
}
.member_top__login__btn input[type=submit] {
  color: #fff !important;
}
@media (min-width: 900px) {
  .member_top__login__btn {
    width: 25.43vw;
    height: 4.64vw;
    border-radius: 50px;
    font-size: 1.29vw;
    margin: 0 auto;
    transition: 0.3s;
    margin: 2.86vw auto 0;
  }
  .member_top__login__btn::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
  }
  .member_top__login__btn:hover {
    background: #fff !important;
    border: solid 1px #0D7100 !important;
    color: #0D7100 !important;
  }
  .member_top__login__btn:hover::after {
    border-color: #0D7100 #0D7100 transparent transparent !important;
  }
  .member_top__login__btn:hover input[type=submit] {
    color: #0D7100 !important;
  }
}
@media (min-width: 1400px) {
  .member_top__login__btn {
    width: 356px;
    height: 65px;
    font-size: 18px;
    margin: 40px auto 0;
  }
  .member_top__login__btn::after {
    width: 8px;
    height: 8px;
    right: 25px;
  }
}
.member_top__login__btn button[type=submit] {
  border: none;
  border-color: transparent;
  border-radius: 0;
  background: no-repeat;
  line-height: 1;
  padding: 0;
  font-size: 1.4rem;
  width: 100%;
  height: 6rem;
  border-radius: 50px !important;
  color: #fff !important;
}
@media (min-width: 900px) {
  .member_top__login__btn button[type=submit] {
    font-size: 1.29vw;
    width: 25.43vw;
    height: 4.64vw;
  }
  .member_top__login__btn button[type=submit]:hover {
    color: #0D7100 !important;
  }
}
@media (min-width: 1400px) {
  .member_top__login__btn button[type=submit] {
    font-size: 18px;
    width: 356px;
    height: 65px;
  }
}
.member_top__login__annotation {
  transform: rotate(0.05deg);
  color: #AAAAAA;
  text-indent: -1em;
  padding: 0 0 0 1em;
  line-height: 2;
  font-size: 1.1rem;
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .member_top__login__annotation {
    text-align: center;
    font-size: 12px;
    margin: 3rem 0 0;
  }
}
@media (min-width: 1400px) {
  .member_top__login__annotation {
    text-align: center;
    font-size: 12px;
    margin: 40px 0 0;
  }
}
.member_top__ttl {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  border-bottom: solid 3px #0D7100;
  color: #0D7100;
  border-radius: 1rem 1rem 0 0;
  margin: 9rem 0 0;
  height: 5.3rem;
  font-size: 1.4rem;
}
@media (min-width: 900px) {
  .member_top__ttl {
    border-radius: 0.71vw 0.71vw 0 0;
    margin: 12.14vw 0 0;
    height: 4.5vw;
    font-size: 1.29vw;
  }
}
@media (min-width: 1400px) {
  .member_top__ttl {
    border-radius: 10px 10px 0 0;
    margin: 170px 0 0;
    height: 63px;
    font-size: 18px;
  }
}
.member_top__list {
  display: flex;
  flex-direction: column;
}
.member_top__list__child {
  padding: 2rem 0;
  border-bottom: solid 1px #D3D3D3;
}
.member_top__list__child:first-child {
  border-top: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .member_top__list__child {
    padding: 2.14vw 0;
  }
}
@media (min-width: 1400px) {
  .member_top__list__child {
    padding: 30px 0;
  }
}
@media (min-width: 900px) {
  .member_top__list__child__link {
    display: flex;
    align-items: center;
  }
}
.member_top__list__child__link .list_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.member_top__list__child__link .list_wrap .date {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .member_top__list__child__link .list_wrap .date {
    margin: 0 0 0 2.86vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .member_top__list__child__link .list_wrap .date {
    margin: 0 0 0 40px;
    font-size: 16px;
  }
}
.member_top__list__child__link .list_wrap .term {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  color: #65C100;
  border-radius: 50px;
  width: 14rem;
  height: 2rem;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .member_top__list__child__link .list_wrap .term {
    margin: 0 0 0 4.29vw;
    width: 11.43vw;
    height: 2.07vw;
    font-size: 1vw;
  }
}
@media (min-width: 1400px) {
  .member_top__list__child__link .list_wrap .term {
    margin: 0 0 0 60px;
    width: 160px;
    height: 29px;
    font-size: 14px;
  }
}
.member_top__list__child__link .link {
  transform: rotate(0.05deg);
  line-height: 1.5;
  display: inline-block;
  margin: 1.4rem 0 0;
  font-size: 1.3rem;
}
.member_top__list__child__link .link:visited {
  color: #65C100 !important;
}
@media (min-width: 900px) {
  .member_top__list__child__link .link {
    transition: 0.3s;
    margin: 0 0 0 2.5vw;
    font-size: 1.14vw;
  }
  .member_top__list__child__link .link:hover {
    opacity: 0.6;
    color: #65C100;
  }
}
@media (min-width: 1400px) {
  .member_top__list__child__link .link {
    margin: 0 0 0 35px;
    font-size: 16px;
  }
}
.member_top__logout_btn {
  margin: 5rem auto 0;
}
@media (min-width: 900px) {
  .member_top__logout_btn {
    margin: 80px auto 0;
  }
}

.member_detail {
  margin: 9rem 0 0;
}
@media (min-width: 900px) {
  .member_detail {
    margin: 13.57vw 0 0;
  }
}
@media (min-width: 1400px) {
  .member_detail {
    margin: 190px 0 0;
  }
}
.member_detail__ttl {
  transform: rotate(0.05deg);
  margin: 6rem 0 0;
  line-height: 1.5;
  font-size: 2.6rem;
  padding: 0 0 2rem;
  border-bottom: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .member_detail__ttl {
    max-width: 53.43vw;
    margin: 8.57vw auto 0;
    font-size: 2.57vw;
    padding: 0 0 2.5vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__ttl {
    max-width: 748px;
    margin: 120px auto 0;
    font-size: 36px;
    padding: 0 0 35px;
  }
}
.member_detail__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 0;
}
@media (min-width: 900px) {
  .member_detail__top {
    max-width: 53.43vw;
    margin: 1.43vw auto 0;
    justify-content: flex-start;
    gap: 0 3.93vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__top {
    max-width: 748px;
    margin: 20px auto 0;
    gap: 0 55px;
  }
}
.member_detail__top .date {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .member_detail__top .date {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__top .date {
    font-size: 16px;
  }
}
.member_detail__top .category {
  transform: rotate(0.05deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF8E2;
  color: #65C100;
  border-radius: 50px;
  width: 14rem;
  height: 2rem;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .member_detail__top .category {
    width: 11.43vw;
    height: 2.07vw;
    font-size: 1vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__top .category {
    width: 160px;
    height: 29px;
    font-size: 14px;
  }
}
.member_detail__contents {
  margin: 6rem 0 0;
  padding: 0 0 6rem;
  border-bottom: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .member_detail__contents {
    max-width: 53.43vw;
    margin: 8.57vw auto 0;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents {
    max-width: 748px;
    margin: 120px auto 0;
    padding: 0 0 120px;
  }
}
.member_detail__contents h1 {
  transform: rotate(0.05deg);
  line-height: 1.5;
  margin: 4rem 0 0;
  font-size: 2rem;
}
.member_detail__contents h1:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .member_detail__contents h1 {
    margin: 4.29vw 0 0;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents h1 {
    margin: 60px 0 0;
    font-size: 28px;
  }
}
.member_detail__contents h2 {
  transform: rotate(0.05deg);
  line-height: 1.5;
  margin: 4rem 0 0;
  font-size: 1.8rem;
}
.member_detail__contents h2:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .member_detail__contents h2 {
    margin: 1.79vw 0 0;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents h2 {
    margin: 60px 0 0;
    font-size: 25px;
  }
}
.member_detail__contents h3 {
  transform: rotate(0.05deg);
  line-height: 1.5;
  margin: 4rem 0 0;
  font-size: 1.5rem;
}
.member_detail__contents h3:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .member_detail__contents h3 {
    margin: 1.64vw 0 0;
    font-size: 2vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents h3 {
    margin: 60px 0 0;
    font-size: 23px;
  }
}
.member_detail__contents p {
  transform: rotate(0.05deg);
  line-height: 2;
  margin: 1rem 0 0;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .member_detail__contents p {
    margin: 1.43vw 0 0;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents p {
    margin: 20px 0 0;
    font-size: 16px;
  }
}
.member_detail__contents .annotation {
  transform: rotate(0.05deg);
  position: relative;
  color: #AAAAAA;
  line-height: 2;
  margin: 1rem 0 0;
  font-size: 1.1rem;
  padding: 0 0 0 1.2em;
}
.member_detail__contents .annotation::before {
  content: "※";
  display: block;
  position: absolute;
  left: 0;
}
@media (min-width: 900px) {
  .member_detail__contents .annotation {
    margin: 0.86vw 0 0;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents .annotation {
    margin: 20px 0 0;
    font-size: 12px;
  }
}
.member_detail__contents img {
  display: block;
  margin: 4rem 0 0;
}
.member_detail__contents img:first-child {
  margin: 0 auto;
}
@media (min-width: 900px) {
  .member_detail__contents img {
    width: 39.43vw;
    margin: 1.43vw auto 0;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents img {
    width: 552px;
    margin: 20px auto 0;
  }
}
.member_detail__contents a {
  transform: rotate(0.05deg);
  position: relative;
  display: inline-block;
  color: #65C100;
  padding: 0 0 0 1.6rem;
  font-size: 1.4rem;
}
.member_detail__contents a::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-color: #65C100 #65C100 transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 35%;
}
.member_detail__contents a:visited {
  color: #65C100;
}
@media (min-width: 900px) {
  .member_detail__contents a {
    padding: 0 0 0 1.43vw;
    font-size: 1.29vw;
  }
  .member_detail__contents a::before {
    width: 0.57vw;
    height: 0.57vw;
    top: 40%;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents a {
    padding: 0 0 0 20px;
    font-size: 18px;
  }
  .member_detail__contents a::before {
    width: 8px;
    height: 8px;
    top: 40%;
  }
}
.member_detail__contents ul {
  margin: 4rem 0 0;
}
.member_detail__contents ul:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .member_detail__contents ul {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents ul {
    margin: 60px 0 0;
  }
}
.member_detail__contents ul li {
  transform: rotate(0.05deg);
  position: relative;
  line-height: 2;
  font-size: 1.3rem;
  margin: 0 0 1rem;
  padding-left: 1.3em;
}
.member_detail__contents ul li:last-child {
  margin: 0;
}
.member_detail__contents ul li::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  background: #65C100;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 20%;
}
@media (min-width: 900px) {
  .member_detail__contents ul li {
    font-size: 1.14vw;
    margin: 0 0 0.71vw;
    padding-left: 1.21vw;
  }
  .member_detail__contents ul li::before {
    width: 0.71vw;
    height: 0.71vw;
    top: 35%;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents ul li {
    font-size: 16px;
    margin: 0 0 10px;
    padding-left: 17px;
  }
  .member_detail__contents ul li::before {
    width: 10px;
    height: 10px;
  }
}
.member_detail__contents ol {
  margin: 4rem 0 0;
  counter-reset: item;
  list-style-type: none;
}
.member_detail__contents ol:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .member_detail__contents ol {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents ol {
    margin: 60px 0 0;
  }
}
.member_detail__contents ol li {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 0 0 1rem;
  text-indent: -1.3em;
  padding-left: 1.3em;
}
.member_detail__contents ol li:last-child {
  margin: 0;
}
.member_detail__contents ol li:before {
  counter-increment: item;
  content: counter(item) ".";
  padding-right: 0.5em;
  font-weight: bold;
  color: #65C100;
}
@media (min-width: 900px) {
  .member_detail__contents ol li {
    font-size: 1.14vw;
    margin: 0 0 0.71vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__contents ol li {
    font-size: 16px;
    margin: 0 0 10px;
  }
}
.member_detail__next_prev_btn {
  display: flex;
  justify-content: center;
  gap: 0 6rem;
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn {
    gap: 0 8.57vw;
    margin: 2.14vw 0 0;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn {
    gap: 0 120px;
    margin: 30px 0 0;
  }
}
.member_detail__next_prev_btn__child {
  position: relative;
}
.member_detail__next_prev_btn__child a {
  text-decoration: none;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 0 1rem;
  transition: 0.3s;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn__child a {
    gap: 0 0.71vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn__child a {
    gap: 0 10px;
  }
}
.member_detail__next_prev_btn__child a:visited {
  color: #65C100 !important;
}
.member_detail__next_prev_btn__child a:hover .circle_next {
  background: #fff;
  border: solid 1px #65C100;
}
.member_detail__next_prev_btn__child a:hover .circle_next::before {
  border-color: transparent transparent #65C100 #65C100;
}
.member_detail__next_prev_btn__child a:hover .circle_prev {
  background: #fff;
  border: solid 1px #65C100;
}
.member_detail__next_prev_btn__child a:hover .circle_prev::before {
  border-color: #65C100 #65C100 transparent transparent;
}
.member_detail__next_prev_btn__child:first-child a::after {
  content: "";
  display: block;
  width: 1px;
  height: 2rem;
  background: #D3D3D3;
  position: absolute;
  right: -3rem;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn__child:first-child a::after {
    height: 2.14vw;
    right: -4.29vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn__child:first-child a::after {
    height: 30px;
    right: -60px;
  }
}
.member_detail__next_prev_btn__child .txt {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn__child .txt {
    font-size: 1.29vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn__child .txt {
    font-size: 18px;
  }
}
.member_detail__next_prev_btn__child .pic {
  width: 4rem;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn__child .pic {
    width: 3.57vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn__child .pic {
    width: 50px;
  }
}
.member_detail__next_prev_btn__child .circle_next {
  position: relative;
  transition: 0.3s;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #65C100;
}
.member_detail__next_prev_btn__child .circle_next::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: transparent transparent #fff #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 40%;
  right: 35%;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn__child .circle_next {
    width: 3.57vw;
    height: 3.57vw;
  }
  .member_detail__next_prev_btn__child .circle_next::before {
    width: 0.57vw;
    height: 0.57vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn__child .circle_next {
    width: 50px;
    height: 50px;
  }
  .member_detail__next_prev_btn__child .circle_next::before {
    width: 8px;
    height: 8px;
  }
}
.member_detail__next_prev_btn__child .circle_prev {
  position: relative;
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #65C100;
}
.member_detail__next_prev_btn__child .circle_prev::before {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-radius: 2px;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  top: 40%;
  right: 43%;
}
@media (min-width: 900px) {
  .member_detail__next_prev_btn__child .circle_prev {
    width: 3.57vw;
    height: 3.57vw;
  }
  .member_detail__next_prev_btn__child .circle_prev::before {
    width: 0.57vw;
    height: 0.57vw;
  }
}
@media (min-width: 1400px) {
  .member_detail__next_prev_btn__child .circle_prev {
    width: 50px;
    height: 50px;
  }
  .member_detail__next_prev_btn__child .circle_prev::before {
    width: 8px;
    height: 8px;
  }
}
.member_detail__btn {
  display: flex;
  flex-direction: column;
  margin: 6rem 0 0;
}
@media (min-width: 900px) {
  .member_detail__btn {
    margin: 8.57vw 0 0;
    flex-direction: row;
    justify-content: center;
    gap: 0 2.14vw;
  }
}
@media (min-width: 900px) {
  .member_detail__btn {
    margin: 120px 0 0;
    gap: 0 30px;
  }
}
.member_detail__btn__child:last-child {
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .member_detail__btn__child {
    margin: 0;
  }
  .member_detail__btn__child:last-child {
    margin: 0;
  }
}
.member_detail__login_btn__wrap {
  margin: 12rem 0 0;
}
@media (min-width: 900px) {
  .member_detail__login_btn__wrap {
    margin: 8.57vw 0 0;
  }
}
@media (min-width: 900px) {
  .member_detail__login_btn__wrap {
    margin: 220px 0 0;
  }
}
.member_detail__login_btn__btn {
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .member_detail__login_btn__btn {
    margin: 8.57vw 0 0;
    flex-direction: row;
    justify-content: center;
    gap: 0 2.14vw;
  }
}
@media (min-width: 900px) {
  .member_detail__login_btn__btn {
    margin: 120px 0 0;
    gap: 0 30px;
  }
}
.member_detail__login_btn__txt {
  transform: rotate(0.05deg);
  text-align: center;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .member_detail__login_btn__txt {
    font-size: 1.43vw;
  }
}
@media (min-width: 900px) {
  .member_detail__login_btn__txt {
    font-size: 20px;
  }
}
.member_detail__login_btn__child:last-child {
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .member_detail__login_btn__child {
    margin: 0;
  }
  .member_detail__login_btn__child:last-child {
    margin: 0;
  }
}

.nursery_list__map {
  margin: 8rem calc(50% - 50vw) 0;
}
@media (min-width: 900px) {
  .nursery_list__map {
    margin: 10.71vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__map {
    margin: 150px auto 0;
    max-width: 748px;
  }
}
.nursery_list__map__wrap {
  margin: 0 calc(50% - 50vw) 0;
  width: 100%;
  height: 25rem;
}
@media (min-width: 900px) {
  .nursery_list__map__wrap {
    margin: 0 auto;
  }
}
.nursery_list__list {
  margin: 6rem 0 0;
}
@media (min-width: 900px) {
  .nursery_list__list {
    margin: 8.57vw 0 0;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list {
    margin: 120px 0 0;
  }
}
.nursery_list__list__child {
  padding: 2rem 0;
  border-bottom: solid 1px #D3D3D3;
}
.nursery_list__list__child:first-child {
  border-top: solid 1px #D3D3D3;
}
@media (min-width: 900px) {
  .nursery_list__list__child {
    display: flex;
    padding: 2.86vw 0;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child {
    padding: 40px 0;
  }
}
.nursery_list__list__child__pic img {
  width: 100%;
  height: 21rem;
  object-fit: cover;
}
@media (min-width: 900px) {
  .nursery_list__list__child__pic {
    width: 25.43vw;
  }
  .nursery_list__list__child__pic img {
    width: 25.43vw;
    height: 16.93vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__pic {
    width: 356px;
  }
  .nursery_list__list__child__pic img {
    width: 356px;
    height: 237px;
  }
}
.nursery_list__list__child__detail {
  padding: 2rem 0 0;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail {
    padding: 0 0 0 2.5vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail {
    padding: 0 0 0 35px;
  }
}
.nursery_list__list__child__detail .nursery_name {
  transform: rotate(0.05deg);
  line-height: 1.5;
  font-size: 2rem;
}
.nursery_list__list__child__detail .nursery_name a {
  color: #65C100 !important;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_name {
    font-size: 2vw;
  }
  .nursery_list__list__child__detail .nursery_name a {
    display: inline-block;
    transform: rotate(0.05deg);
    transition: 0.3s;
  }
  .nursery_list__list__child__detail .nursery_name a:hover {
    opacity: 0.6;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_name {
    font-size: 28px;
  }
}
.nursery_list__list__child__detail .nursery_address {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
  margin: 1.3rem 0;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_address {
    margin: 2.5vw 0 1.79vw;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_address {
    margin: 35px 0 25px;
    font-size: 16px;
  }
}
.nursery_list__list__child__detail .nursery_tel {
  display: flex;
}
.nursery_list__list__child__detail .nursery_tel .txt {
  transform: rotate(0.05deg);
  color: #AAAAAA;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_tel .txt {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_tel .txt {
    font-size: 16px;
  }
}
.nursery_list__list__child__detail .nursery_tel .detail {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
  margin: 0 0 0 1.5rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_tel .detail {
    font-size: 1.14vw;
    margin: 0 0 0 2.14vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_tel .detail {
    margin: 0 0 0 35px;
    font-size: 16px;
  }
}
.nursery_list__list__child__detail .nursery_fax {
  display: flex;
  margin: 0.5rem 0 0;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_fax {
    margin: 0.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_fax {
    margin: 10px 0 0;
  }
}
.nursery_list__list__child__detail .nursery_fax .txt {
  transform: rotate(0.05deg);
  color: #AAAAAA;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_fax .txt {
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_fax .txt {
    font-size: 16px;
  }
}
.nursery_list__list__child__detail .nursery_fax .detail {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
  margin: 0 0 0 1.5rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_fax .detail {
    font-size: 1.14vw;
    margin: 0 0 0 2.14vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_fax .detail {
    margin: 0 0 0 35px;
    font-size: 16px;
  }
}
.nursery_list__list__child__detail .nursery_icon_list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;
  padding: 0 2rem 0 0;
  margin: 2rem 0 0;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_icon_list {
    gap: 0 2.14vw;
    padding: 0;
    margin: 2.86vw 0 0;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_icon_list {
    gap: 0 30px;
    margin: 40px 0 0;
  }
}
.nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child {
  width: 50%;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child {
    width: auto;
  }
}
.nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon01 img {
  width: 7.7rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon01 img {
    width: 6.36vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon01 img {
    width: 89px;
  }
}
.nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon02 img {
  width: 12.7rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon02 img {
    width: 10.5vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon02 img {
    width: 147px;
  }
}
.nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon03 img {
  width: 8.9rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon03 img {
    width: 7.43vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon03 img {
    width: 104px;
  }
}
.nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon04 img {
  width: 11.4rem;
}
@media (min-width: 900px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon04 img {
    width: 9.5vw;
  }
}
@media (min-width: 1400px) {
  .nursery_list__list__child__detail .nursery_icon_list .nursery_icon_list_child.icon04 img {
    width: 133px;
  }
}

.recruit__contents {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .recruit__contents {
    margin: 10.71vw auto 0;
    max-width: 53.43vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents {
    margin: 150px auto 0;
    max-width: 748px;
  }
}
.recruit__contents__ttl {
  transform: rotate(0.05deg);
  line-height: 1.5;
  font-size: 2.6rem;
  text-align: center;
}
@media (min-width: 900px) {
  .recruit__contents__ttl {
    font-size: 2.57vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__ttl {
    font-size: 36px;
  }
}
.recruit__contents__txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.3rem;
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .recruit__contents__txt {
    text-align: center;
    margin: 2.86vw 0 0;
    font-size: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__txt {
    margin: 40px 0 0;
    font-size: 16px;
  }
}
.recruit__contents__contact_ttl {
  transform: rotate(0.05deg);
  text-align: center;
  font-size: 2rem;
  border-bottom: solid 1px #D3D3D3;
  margin: 6rem 0 0;
  padding: 0 0 1rem;
}
@media (min-width: 900px) {
  .recruit__contents__contact_ttl {
    margin: 8.57vw 0 0;
    font-size: 2vw;
    padding: 0 0 1.43vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__contact_ttl {
    margin: 120px 0 0;
    font-size: 28px;
    padding: 0 0 20px;
  }
}
.recruit__contents__wrap {
  display: flex;
  flex-direction: column;
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .recruit__contents__wrap {
    margin: 4.29vw 0 0;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__wrap {
    margin: 60px 0 0;
  }
}
.recruit__contents__child {
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 0;
}
.recruit__contents__child:first-child {
  margin: 0;
}
@media (min-width: 900px) {
  .recruit__contents__child {
    flex-direction: row;
    align-items: flex-start;
    margin: 2.14vw 0 0;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__child {
    margin: 30px 0 0;
  }
}
.recruit__contents__child .ttl {
  transform: rotate(0.05deg);
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .recruit__contents__child .ttl {
    width: 40%;
    font-size: 1.14vw;
    padding: 0.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__child .ttl {
    font-size: 16px;
    padding: 10px 0 0;
  }
}
.recruit__contents__child .ttl .icon {
  transform: rotate(0.05deg);
  display: inline-block;
  color: #D60000;
  margin: 0 0 0 1rem;
  font-size: 1.2rem;
}
@media (min-width: 900px) {
  .recruit__contents__child .ttl .icon {
    margin: 0 0 0 1.07vw;
    font-size: 1vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__child .ttl .icon {
    margin: 0 0 0 15px;
    font-size: 14px;
  }
}
.recruit__contents__child .input_field {
  margin: 1.5rem 0 0;
  font-size: 1.3rem;
}
@media (min-width: 900px) {
  .recruit__contents__child .input_field {
    margin: 0;
    width: 60%;
    font-size: 1.14vw;
  }
  .recruit__contents__child .input_field.flex_column {
    display: flex;
    gap: 0 5vw;
    padding: 0.71vw 0 0;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__child .input_field {
    font-size: 16px;
  }
  .recruit__contents__child .input_field.flex_column {
    gap: 0 70px;
    padding: 10px 0 0;
  }
}
.recruit__contents__child .input_field .annotation {
  transform: rotate(0.05deg);
  text-indent: -1em;
  padding: 0 0 0 1em;
  color: #AAAAAA;
  line-height: 1.5;
  font-size: 1.1rem;
}
.recruit__contents__child .input_field .annotation.top {
  margin: 1rem 0 0;
}
@media (min-width: 900px) {
  .recruit__contents__child .input_field .annotation {
    font-size: 12px;
  }
  .recruit__contents__child .input_field .annotation.top {
    margin: 10px 0 0;
  }
}
.recruit__contents__consent {
  margin: 2rem auto 0;
  display: flex;
  justify-content: center;
}
@media (min-width: 900px) {
  .recruit__contents__consent {
    margin: 3.57vw auto 0;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__consent {
    margin: 50px auto 0;
  }
}
.recruit__contents__consent a {
  color: #65C100 !important;
}
.recruit__contents__submit {
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .recruit__contents__submit {
    margin: 4.29vw auto 0;
  }
}
@media (min-width: 1400px) {
  .recruit__contents__submit {
    margin: 60px auto 0;
  }
}
.recruit__contents input[type=text] {
  width: 100%;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
  font-size: medium;
}
.recruit__contents input[type=text]::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .recruit__contents input[type=text] {
    font-size: 1.14vw;
    border-radius: 2.14vw;
    padding: 0.36vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents input[type=text] {
    font-size: 16px;
    border-radius: 30px;
    padding: 5px 20px;
  }
}
.recruit__contents input[type=email] {
  width: 100%;
  font-size: medium;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
.recruit__contents input[type=email]::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .recruit__contents input[type=email] {
    font-size: 1.14vw;
    border-radius: 2.14vw;
    padding: 0.36vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents input[type=email] {
    font-size: 16px;
    border-radius: 30px;
    padding: 5px 20px;
  }
}
.recruit__contents textarea {
  width: 100%;
  font-size: medium;
  background: #EEF8E2;
  border: solid 1px rgba(101, 193, 0, 0.2);
  border-radius: 2rem;
  padding: 0.5rem 1.3rem;
}
.recruit__contents textarea::placeholder {
  color: #D3D3D3;
}
@media (min-width: 900px) {
  .recruit__contents textarea {
    font-size: 1.14vw;
    border-radius: 1.43vw;
    padding: 0.71vw 1.43vw;
  }
}
@media (min-width: 1400px) {
  .recruit__contents textarea {
    font-size: 16px;
    border-radius: 20px;
    padding: 10px 20px;
  }
}
.recruit__contents span {
  line-height: 1;
}
.recruit__contents input[type=email] {
  width: 100%;
}
.recruit__contents input[type=submit] {
  transform: rotate(0.05deg);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6rem;
  background: #65C100;
  color: #fff !important;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.4rem;
  border: none;
}
.recruit__contents input[type=submit]::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #fff #fff transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  right: 3rem;
}
@media (min-width: 900px) {
  .recruit__contents input[type=submit] {
    width: 25.43vw;
    height: 65px;
    border-radius: 50px;
    font-size: 1.29vw;
    margin: 0 auto;
    transition: 0.3s;
  }
  .recruit__contents input[type=submit]::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
  }
  .recruit__contents input[type=submit]:hover {
    background: #fff;
    border: solid 1px #65C100;
    color: #65C100 !important;
  }
  .recruit__contents input[type=submit]:hover::after {
    border-color: #65C100 #65C100 transparent transparent;
  }
}
@media (min-width: 1400px) {
  .recruit__contents input[type=submit] {
    width: 356px;
    height: 65px;
    font-size: 18px;
  }
  .recruit__contents input[type=submit]::after {
    width: 8px;
    height: 8px;
    right: 25px;
  }
}
.recruit__contents .mwform-checkbox-field label, .recruit__contents .mwform-radio-field label {
  display: block;
}

.top #splash {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  background: #fff;
  text-align: center;
  color: #fff;
}
.top #splash_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top #splash_logo .pic {
  width: 4rem;
}
@media (min-width: 900px) {
  .top #splash_logo .pic {
    width: 4.29vw;
  }
}
@media (min-width: 1400px) {
  .top #splash_logo .pic {
    width: 60px;
  }
}
.top__top_contents {
  background: url(../images/top_contents_bg_sp.png) no-repeat top center;
  background-size: cover;
  margin: 27.5rem calc(50% - 50vw) 0;
  padding: 31.5rem calc(50vw - 50%) 8rem;
  height: auto;
}
@media (min-width: 900px) {
  .top__top_contents {
    margin: 31vw calc(50% - 50vw) 0;
    padding: 19vw 0 8rem;
    background: url(../images/top_contents_bg_pc.png) no-repeat top center;
    background-size: cover;
  }
}
.top__top_contents__kv {
  margin: 0 calc(50% - 50vw) 0;
  position: absolute;
  top: 9rem;
}
@media (min-width: 900px) {
  .top__top_contents__kv {
    top: 8.57vw;
  }
}
@media (min-width: 1400px) {
  .top__top_contents__kv {
    top: 120px;
  }
}
.top__top_contents__kv__copy {
  width: 10rem;
  margin: 0 auto;
}
@media (min-width: 900px) {
  .top__top_contents__kv__copy {
    width: 11vw;
    margin: 6.93vw 0 0 70.21vw;
  }
  .top__top_contents__kv__copy img {
    width: 11vw;
  }
}
.top__top_contents__kv__pic {
  width: 33.5rem;
  margin: 4rem 0 0 -3rem;
}
@media (min-width: 900px) {
  .top__top_contents__kv__pic {
    width: 60.43vw;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  .top__top_contents__kv__pic img {
    width: 60.43vw;
    object-fit: cover;
    image-rendering: -webkit-optimize-contrast;
  }
}
.top__top_contents__kv .scrolldown1 {
  position: absolute;
  right: -1.5rem;
  top: 41.5rem;
  height: 12.7rem;
}
@media (min-width: 900px) {
  .top__top_contents__kv .scrolldown1 {
    top: 29.86vw;
    height: 144px;
    right: -16vw;
  }
}
.top__top_contents__kv .scrolldown1 span {
  position: absolute;
  left: 0;
  top: 0;
  color: #65C100;
  font-size: 1.3rem;
  letter-spacing: 0.05em;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media (min-width: 900px) {
  .top__top_contents__kv .scrolldown1 span {
    font-size: 1vw;
  }
}
.top__top_contents__kv .scrolldown1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0.5rem;
  width: 2px;
  height: 6rem;
  background: #65C100;
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}
@media (min-width: 900px) {
  .top__top_contents__kv .scrolldown1::after {
    height: 5.21vw;
    left: 0.5vw;
    animation: pathmove_pc 1.4s ease-in-out infinite;
  }
}
@keyframes pathmove {
  0% {
    height: 0;
    top: 4rem;
    opacity: 0;
  }
  30% {
    height: 4rem;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 7rem;
    opacity: 0;
  }
}
@keyframes pathmove_pc {
  0% {
    height: 0;
    top: 3vw;
    opacity: 0;
  }
  30% {
    height: 5.21vw;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 5.21vw;
    opacity: 0;
  }
}
.top__top_contents__lead {
  margin: 7.5rem 0 0;
}
@media (min-width: 900px) {
  .top__top_contents__lead {
    margin: 11.43vw auto 0;
    max-width: 84.29vw;
    padding: 0 1.43vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1400px) {
  .top__top_contents__lead {
    margin: 160px auto 0;
    max-width: 1180px;
    padding: 0 20px;
  }
}
.top__top_contents__lead__txt .txt {
  transform: rotate(0.05deg);
  line-height: 2;
  font-size: 1.4rem;
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .top__top_contents__lead__txt .txt {
    font-size: 1.29vw;
    margin: 2.14vw 0 0;
  }
  .top__top_contents__lead__txt .txt.top {
    margin: 0;
  }
}
@media (min-width: 900px) {
  .top__top_contents__lead__txt .txt {
    font-size: 18px;
    margin: 30px 0 0;
  }
}
.top__top_contents__lead__txt .list {
  margin: 3rem 0 0;
}
@media (min-width: 900px) {
  .top__top_contents__lead__txt .list {
    margin: 2.14vw 0 0;
  }
}
@media (min-width: 1400px) {
  .top__top_contents__lead__txt .list {
    margin: 30px 0 0;
  }
}
.top__top_contents__lead__txt .list .detail {
  line-height: 2;
  font-size: 1.4rem;
  padding: 0 0 0 2rem;
  position: relative;
}
.top__top_contents__lead__txt .list .detail::before {
  content: "";
  display: block;
  background: url(../images/top_list_mark.png) no-repeat center;
  background-size: contain;
  width: 1.1rem;
  height: 0.7rem;
  position: absolute;
  left: -0.1rem;
  top: 1rem;
}
@media (min-width: 900px) {
  .top__top_contents__lead__txt .list .detail {
    font-size: 1.29vw;
    padding: 0 0 0 1.64vw;
  }
  .top__top_contents__lead__txt .list .detail::before {
    width: 1vw;
    height: 0.57vw;
    left: -0.1vw;
    top: 1.14vw;
  }
}
@media (min-width: 1400px) {
  .top__top_contents__lead__txt .list .detail {
    font-size: 18px;
    padding: 0 0 0 23px;
  }
  .top__top_contents__lead__txt .list .detail::before {
    width: 20px;
    height: 10px;
    left: -3px;
    top: 14px;
  }
}
.top__top_contents__lead__map {
  width: 26.4rem;
  margin: 4rem auto 0;
}
@media (min-width: 900px) {
  .top__top_contents__lead__map {
    width: 24.36vw;
    margin: 0 0 0 5.71vw;
  }
}
@media (min-width: 1400px) {
  .top__top_contents__lead__map {
    width: 340px;
    margin: 0 0 0 80px;
  }
}
.top__top_contents__information {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .top__top_contents__information {
    margin: 160px 0 0;
  }
}
.top__top_contents__information__post {
  background: #fff;
  margin: 4rem 0 0;
  padding: 3rem 2rem;
  border-radius: 2rem 2rem 2rem 0;
}
@media (min-width: 900px) {
  .top__top_contents__information__post {
    max-width: 1140px;
    padding: 60px;
    margin: 60px auto 0;
  }
}
.top__top_contents__information__post__list {
  border-bottom: dashed 2px #D3D3D3;
  padding: 2rem 0;
}
.top__top_contents__information__post__list:first-child {
  padding: 0 0 2rem;
}
.top__top_contents__information__post__list:last-child {
  border-bottom: none;
  padding: 2rem 0 0;
}
.top__top_contents__information__post__list .link {
  display: block;
  padding: 1.5rem 0 0;
  color: #65C100;
}
@media (min-width: 900px) {
  .top__top_contents__information__post__list {
    display: flex;
    padding: 20px 0;
  }
  .top__top_contents__information__post__list:first-child {
    padding: 0 0 20px;
  }
  .top__top_contents__information__post__list:last-child {
    padding: 20px 0 0;
  }
  .top__top_contents__information__post__list .date {
    transform: rotate(0.05deg);
    line-height: 2;
  }
  .top__top_contents__information__post__list .link {
    display: inline-block;
    transform: rotate(0.05deg);
    padding: 0 0 0 48px;
    line-height: 2;
    transition: 0.3s;
  }
  .top__top_contents__information__post__list .link:hover {
    opacity: 0.5;
  }
}
.top__top_contents__information__link {
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .top__top_contents__information__link {
    margin: 60px 0 0;
  }
}
.top__pic {
  margin: 0 calc(50% - 50vw);
}
.top__pic img {
  width: 100%;
}
.top__action {
  margin: 8rem calc(50% - 50vw) 0;
  padding: 0 calc(50vw - 50%);
}
@media (min-width: 900px) {
  .top__action {
    margin: 11.43vw auto 0;
    padding: 0;
  }
}
@media (min-width: 1400px) {
  .top__action {
    margin: 160px auto 0;
    padding: 0;
  }
}
.top__action__txt {
  transform: rotate(0.05deg);
  text-align: center;
  margin: 4rem 0 0;
  line-height: 2;
  font-size: 1.4rem;
}
@media (min-width: 900px) {
  .top__action__txt {
    margin: 4.29vw 0 0;
    font-size: 1.29vw;
  }
}
@media (min-width: 1400px) {
  .top__action__txt {
    margin: 60px 0 0;
    font-size: 18px;
  }
}
.top__action__pic {
  margin: 4rem calc(50% - 50vw) 0;
}
.top__action__pic img {
  width: 100%;
}
@media (min-width: 900px) {
  .top__action__pic {
    margin: 4.29vw auto 0;
  }
}
@media (min-width: 1400px) {
  .top__action__pic {
    margin: 60px auto 0;
  }
}
.top__action__link {
  margin: 4rem 0 0;
}
@media (min-width: 900px) {
  .top__action__link {
    margin: 4.29vw auto 0;
  }
}
@media (min-width: 1400px) {
  .top__action__link {
    margin: 60px auto 0;
  }
}
.top__link {
  margin: 8rem 0 0;
}
@media (min-width: 900px) {
  .top__link {
    margin: 11.43vw 0 0;
  }
}
@media (min-width: 1400px) {
  .top__link {
    margin: 160px 0 0;
  }
}
.top__link__content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .top__link__content {
    flex-direction: row;
    justify-content: center;
  }
}
.top__link__content__link {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #65C100 !important;
  margin: 0 0 4rem;
  transition: 0.3s;
}
@media (min-width: 900px) {
  .top__link__content__link {
    flex-direction: row;
    margin: 0 2.5vw 0 0;
    border: solid 2px #fff;
    border-radius: 1.43vw;
  }
  .top__link__content__link:hover {
    border: solid 2px #65C100;
  }
}
@media (min-width: 1400px) {
  .top__link__content__link {
    margin: 0 35px 0 0;
    border-radius: 20px;
  }
}
.top__link__content__link:last-child {
  margin: 0;
}
.top__link__content__link .pic img {
  border-radius: 2rem 2rem 0 0;
  width: 100%;
}
@media (min-width: 900px) {
  .top__link__content__link .pic img {
    border-radius: 1.3vw 0 0 1.3vw;
    width: 17.14vw;
  }
}
@media (min-width: 1400px) {
  .top__link__content__link .pic img {
    border-radius: 18px 0 0 18px;
    width: 240px;
  }
}
.top__link__content__link .txt {
  transform: rotate(0.05deg);
  position: relative;
  padding: 2rem;
  background: #EEF8E2;
  font-size: 1.4rem;
  border-radius: 0 0 2rem 2rem;
}
.top__link__content__link .txt::after {
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1px;
  border: 2px solid;
  border-color: #65C100 #65C100 transparent transparent;
  transform: rotate(45deg);
  position: absolute;
  right: 2rem;
  top: 40%;
}
@media (min-width: 900px) {
  .top__link__content__link .txt {
    display: flex;
    align-items: center;
    padding: 0 0 0 40px;
    font-size: 1.29vw;
    border-radius: 0 1.43vw 1.43vw 0;
    width: 22.29vw;
  }
  .top__link__content__link .txt::after {
    width: 0.57vw;
    height: 0.57vw;
    right: 2vw;
    top: 50%;
  }
}
@media (min-width: 1400px) {
  .top__link__content__link .txt {
    padding: 0 0 0 40px;
    font-size: 18px;
    border-radius: 0 20px 20px 0;
    width: 312px;
  }
  .top__link__content__link .txt::after {
    width: 8px;
    height: 8px;
    right: 25px;
  }
}

.clear {
  clear: both;
}

br.clear {
  font-size: 1px;
  line-height: 100%;
  clear: both;
}

div.clear {
  line-height: 100%;
  clear: both;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
}

.imgfix {
  font-size: 1px;
  line-height: 1px;
}

@media (max-width: 899px) {
  .hide-sp {
    display: none !important;
  }
}
@media (min-width: 900px) {
  .hide-pc {
    display: none !important;
  }
}
.u-palt {
  font-feature-settings: "palt";
}

.u-justify {
  text-align: justify;
  text-justify: character;
}

.u-responsive-frame {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.u-responsive-frame video,
.u-responsive-frame iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mr-48 {
  margin-right: 48px;
}

.ml-48 {
  margin-left: 48px;
}

.mt-56 {
  margin-top: 56px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mr-56 {
  margin-right: 56px;
}

.ml-56 {
  margin-left: 56px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mr-64 {
  margin-right: 64px;
}

.ml-64 {
  margin-left: 64px;
}

.mt-72 {
  margin-top: 72px;
}

.mb-72 {
  margin-bottom: 72px;
}

.mr-72 {
  margin-right: 72px;
}

.ml-72 {
  margin-left: 72px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mt-88 {
  margin-top: 88px;
}

.mb-88 {
  margin-bottom: 88px;
}

.mr-88 {
  margin-right: 88px;
}

.ml-88 {
  margin-left: 88px;
}

.mt-96 {
  margin-top: 96px;
}

.mb-96 {
  margin-bottom: 96px;
}

.mr-96 {
  margin-right: 96px;
}

.ml-96 {
  margin-left: 96px;
}