@use "foundation" as *;

.top {

  //ローディングアニメーション
  #splash {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0;
    background:#fff;
    text-align:center;
    color:#fff;
  }
  #splash_logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .pic {
      width: 4rem;
      @include pc {
        width: 4.29vw;
      }
      @include pc_ls {
        width: 60px;
      }
    }
  }

  &__top_contents {
    background: url(../images/top_contents_bg_sp.png) no-repeat top center;
    background-size: cover;
    margin: 27.5rem calc(50% - 50vw) 0;
    padding: 31.5rem calc(50vw - 50%) 8rem;
    height: auto;
    @include pc {
      margin: 31vw calc(50% - 50vw) 0;
      padding: 19vw 0 8rem;
      background: url(../images/top_contents_bg_pc.png) no-repeat top center;
      background-size: cover;
    }
    @include pc_ls {
      //margin: 460px calc(50% - 50vw) 0;
      //padding: 260px 0 160px;
    }

    &__kv {
      margin: 0 calc(50% - 50vw) 0;
      position: absolute;
      top: 9rem;
      @include pc {
        top: 8.57vw;
      }
      @include pc_ls {
        top: 120px;
      }
      &__copy {
        width: 10rem;
        margin: 0 auto;
        @include pc {
          width: 11vw;
          margin: 6.93vw 0 0 70.21vw;
          img {
            width: 11vw;
          }
        }
      }
      &__pic {
        width: 33.5rem;
        margin: 4rem 0 0 -3rem;
        @include pc {
          width: 60.43vw;
          margin: 0;
          position: absolute;
          left: 0;
          top: 0;
          img {
            width: 60.43vw;
            object-fit: cover;
            image-rendering: -webkit-optimize-contrast;
          }
        }
      }

      .scrolldown1{
        position: absolute;
        right: -1.5rem;
        top: 41.5rem;
        height: 12.7rem;
        @include pc {
          top: 29.86vw;
          height: 144px;
          right: -16vw;
        }
        span{
          position: absolute;
          left:0;
          top: 0;
          color: #65C100;
          font-size: 1.3rem;
          letter-spacing: 0.05em;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          @include pc {
            font-size: 1vw;
          }
        }
        &::after{
          content: "";
          position: absolute;
          top: 0;
          left: 0.5rem;
          width: 2px;
          height: 6rem;
          background: #65C100;
          animation: pathmove 1.4s ease-in-out infinite;
          opacity:0;
          @include pc {
            height: 5.21vw;
            left: 0.5vw;
            animation: pathmove_pc 1.4s ease-in-out infinite;
          }
        }
      }
      @keyframes pathmove{
        0%{ height:0; top: 4rem; opacity: 0; }
        30%{ height: 4rem; opacity: 1; }
        100%{ height: 0; top: 7rem; opacity: 0; }
      }
      @keyframes pathmove_pc{
        0%{ height:0; top: 3vw; opacity: 0; }
        30%{ height: 5.21vw; opacity: 1; }
        100%{ height: 0; top: 5.21vw; opacity: 0; }
      }
    }

    &__lead {
      margin: 7.5rem 0 0;
      @include pc {
        margin: 11.43vw auto 0;
        max-width: 84.29vw;
        padding: 0 1.43vw;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include pc_ls {
        margin: 160px auto 0;
        max-width: 1180px;
        padding: 0 20px;
      }
      &__txt {
        .txt {
          transform: rotate(0.05deg);
          line-height: 2;
          font-size: 1.4rem;
          margin: 3rem 0 0;
          @include pc {
            font-size: 1.29vw;
            margin: 2.14vw 0 0;
            &.top {
              margin: 0;
            }
          }
          @include pc {
            font-size: 18px;
            margin: 30px 0 0;
          }
        }
        .list {
          margin: 3rem 0 0;
          @include pc {
            margin: 2.14vw  0 0;
          }
          @include pc_ls {
            margin: 30px 0 0;
          }
          .detail {
            line-height: 2;
            font-size: 1.4rem;
            padding: 0 0 0 2rem;
            position: relative;
            &::before {
              content: "";
              display: block;
              background: url(../images/top_list_mark.png)no-repeat center;
              background-size: contain;
              width: 1.1rem;
              height: 0.7rem;
              position: absolute;
              left: -0.1rem;
              top: 1rem;
            }
            @include pc {
              font-size: 1.29vw;
              padding: 0 0 0 1.64vw;
              &::before {
                width: 1vw;
                height: 0.57vw;
                left: -0.1vw;
                top: 1.14vw;
              }
            }
            @include pc_ls {
              font-size: 18px;
              padding: 0 0 0 23px;
              &::before {
                width: 20px;
                height: 10px;
                left: -3px;
                top: 14px;
              }
            }
          }
        }
      }
      &__map {
        width: 26.4rem;
        margin: 4rem auto 0;
        @include pc {
          width: 24.36vw;
          margin: 0 0 0 5.71vw;
        }
        @include pc_ls {
          width: 340px;
          margin: 0 0 0 80px;
        }
      }
    }

    &__information {
      margin: 8rem 0 0;
      @include pc {
        margin: 160px 0 0;
      }
      &__post {
        background: #fff;
        margin: 4rem 0 0;
        padding: 3rem 2rem;
        border-radius: 2rem 2rem 2rem 0;
        @include pc {
          max-width: 1140px;
          padding: 60px;
          margin: 60px auto 0;
        }

        &__list {
          border-bottom: dashed 2px #D3D3D3;
          padding: 2rem 0;
          &:first-child { padding: 0 0 2rem;}
          &:last-child { border-bottom: none; padding: 2rem 0 0;}
          .date {}
          .link {
            display: block;
            padding: 1.5rem 0 0;
            color: #65C100;
            .txt {}
          }

          @include pc {
            display: flex;
            padding: 20px 0;
            &:first-child { padding: 0 0 20px;}
            &:last-child { padding: 20px 0 0;}

            .date {
              transform: rotate(0.05deg);
              line-height: 2;
            }
            .link {
              display: inline-block;
              transform: rotate(0.05deg);
              padding: 0 0 0 48px;
              line-height: 2;
              transition: 0.3s;
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }

      &__link {
        margin: 4rem 0 0;
        @include pc {
          margin: 60px 0 0;
        }
      }
    }

  }

  &__pic {
    margin: 0 calc(50% - 50vw);
    img {
      width: 100%;
    }
  }

  &__action {
    margin: 8rem calc(50% - 50vw) 0;
    padding: 0 calc(50vw - 50%);
    @include pc {
      margin: 11.43vw auto 0;
      padding: 0;
    }
    @include pc_ls {
      margin: 160px auto 0;
      padding: 0;
    }

    &__txt {
      transform: rotate(0.05deg);
      text-align: center;
      margin: 4rem 0 0;
      line-height: 2;
      font-size: 1.4rem;
      @include pc {
        margin: 4.29vw 0 0;
        font-size: 1.29vw;
      }
      @include pc_ls {
        margin: 60px 0 0;
        font-size: 18px;
      }
    }

    &__pic {
      margin: 4rem calc(50% - 50vw) 0;
      img {
        width: 100%;
      }
      @include pc {
        margin: 4.29vw auto 0;
      }
      @include pc_ls {
        margin: 60px auto 0;
      }
    }

    &__link {
      margin: 4rem 0 0;
      @include pc {
        margin: 4.29vw auto 0;
      }
      @include pc_ls {
        margin: 60px auto 0;
      }
    }
  }

  &__link {
    margin: 8rem 0 0;
    @include pc {
      margin: 11.43vw 0 0;
    }
    @include pc_ls {
      margin: 160px 0 0;
    }
    &__content {
      display: flex;
      flex-direction: column;
      @include pc {
        flex-direction: row;
        justify-content: center;
      }
      &__link {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: #65C100 !important;
        margin: 0 0 4rem;
        transition: 0.3s;
        @include pc {
          flex-direction: row;
          margin: 0 2.5vw 0 0;
          border: solid 2px #fff;
          border-radius: 1.43vw;
          &:hover {
            border: solid 2px #65C100;
          }
        }
        @include pc_ls {
          margin: 0 35px 0 0;
          border-radius: 20px;
        }
        &:last-child { margin: 0; }

        .pic {
          img {
            border-radius: 2rem 2rem 0 0;
            width: 100%;
            @include pc {
              border-radius: 1.3vw 0 0 1.3vw;
              width: 17.14vw;
            }
            @include pc_ls {
              border-radius: 18px 0 0 18px;
              width: 240px;
            }
          }
        }

        .txt {
          transform: rotate(0.05deg);
          position: relative;
          padding: 2rem;
          background: #EEF8E2;
          font-size: 1.4rem;
          border-radius: 0 0 2rem 2rem;
          &::after {
            content: "";
            display: block;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 1px;
            border: 2px solid;
            border-color: #65C100 #65C100 transparent transparent;
            transform: rotate(45deg);
            position: absolute;
            right: 2rem;
            top: 40%;
          }
          @include pc {
            display: flex;
            align-items: center;
            padding: 0 0 0 40px;
            font-size: 1.29vw;
            border-radius: 0 1.43vw 1.43vw 0;
            width: 22.29vw;
            &::after {
              width: 0.57vw;
              height: 0.57vw;
              right: 2vw;
              top: 50%;
            }
          }
          @include pc_ls {
            padding: 0 0 0 40px;
            font-size: 18px;
            border-radius: 0 20px 20px 0;
            width: 312px;
            &::after {
              width: 8px;
              height: 8px;
              right: 25px;
            }
          }
        }
      }
    }
  }
}