@use "foundation" as *;

@include sp {
  .hide-sp { display: none!important;}
}
@include pc {
  .hide-pc { display: none!important;}
}

.u-palt {
  font-feature-settings: "palt";
}

.u-justify {
  text-align: justify;
  text-justify: character;
}

.u-responsive-frame {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  video,
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
