.c-scroll-top {
  position: fixed;
  right: 1rem;
  bottom: 5rem;

  a {
    display: block;
    padding: 1em;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 50%;
    text-decoration: none;
    cursor: pointer;
  }
}