@use "foundation" as *;

.l-footer {
  margin-top: 14rem;
  @include pc {
    margin-top: 20vw;
  }
  @include pc_ls {
    margin-top: 280px;
  }
  &__bg {
    position: relative;
    background: #EEF8E2;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include pc {
      background: linear-gradient(0deg, rgba(238,248,226,1) 80%, rgba(255,255,255,1) 80%);
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 6rem;
      background: #EEF8E2;
      border-radius: 100% 100% 0 0;
      position: absolute;
      top: -3rem;
      z-index: 0;
      @include pc {
        height: 17.14vw;
        border-radius: 50% 50% 0 0;
        top: -3.57vw;
      }
      @include pc_ls {
        height: 240px;
        top: -50px;
      }
    }
  }
  &__logo {
    position: relative;
    width: 19rem;
    @include pc {
      width: 20.36vw;
      transition: 0.3s;
      &:hover {
        opacity: 0.5;
      }
    }
    @include pc-ls {
      width: 285px;
    }
  }

  &__link_list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0 0;
    @include pc {
      flex-direction: row;
      margin: 4.29vw 0 0;
    }
    @include pc_ls {
      margin: 60px 0 0;
    }
    &__link {
      margin: 0 0 2.5rem;
      @include pc { margin: 0 30px 0 0 ;}
      &:last-child { margin: 0; }

      a {
        transform: rotate(0.05deg);
        display: inline-block;
        color: #464646 !important;
        font-size: 1.2rem;
        text-decoration: none;
        text-align: center;
        @include pc {
          font-size: 1vw;
          transition: 0.3s;
          &:hover {
            color: #65C100 !important;
          }
        }
        @include pc-ls {
          font-size: 14px;
        }
      }
    }
  }

  &__link_btn {
    position: relative;
    margin: 4rem 0 0;
    @include pc {
      margin: 2.86vw 0 0;
      display: flex;
      justify-content: center;
    }
    @include pc_ls {
      margin: 40px 0 0;
    }
    &__link {
      margin: 0 0 2rem;
      &:last-child { margin: 0; }
      @include pc { margin: 0; }

      a {
        transform: rotate(0.05deg);
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 31.5rem;
        height: 6rem;
        border-radius: 50px;
        font-size: 1.4rem;
        @include pc {
          width: 18.43vw;
          height: 65px;
          font-size: 1vw;
          transition: 0.3s;
          &:hover {
            opacity: 0.5;
          }
        }
        @include pc-ls {
          width: 258px;
          font-size: 14px;
        }
      }
      .contact {
        background: #fff;
        color: #65C100;
        border: solid 1px #65C100;
        .mail_icon {
          display: inline-block;
          width: 2rem;
          margin: 0 1rem 0 0;
        }
        @include pc {
          margin: 0 2.14vw 0 0;
          .mail_icon {
            width: 1.43vw;
            margin: 0 0.71vw 0 0;
          }
        }
        @include pc_ls {
          margin: 0 30px 0 0;
          .mail_icon {
            width: 20px;
            margin: 0 10px 0 0;
          }
        }
      }
      .member {
        background: #0D7100;
        color: #fff;
      }
    }
  }

  &__copyright {
    margin: 8rem calc(50% - 50vw) 0;
    padding: 1.2rem calc(50vw - 50%);
    background: #0D7100;
    display: block;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #fff;
    @include pc {
      margin: 80px calc(50% - 50vw) 0;
      padding: 20px calc(50vw - 50%);
      font-size: 0.86vw;
    }
    @include pc-ls {
      font-size: 12px;
    }
    &__txt {
      transform: rotate(0.05deg);
    }
  }
}
