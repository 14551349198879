@use "foundation" as *;

//メンバーページトップ
.member_top {

    &__login {
        margin: 9rem 0 0;
        @include pc {
            margin: 12.14vw 0 0;
        }
        @include pc_ls {
            margin: 170px 0 0;
        }

        #error_message {
            margin: 0 0 3rem;
            @include pc {
                margin: 0 0 2.14vw;
            }
            @include pc_ls {
                margin: 0 0 30px;
            }
            p {
                text-align: center;
                color: #D60000;
                font-size: 1.3rem;
                @include pc {
                    font-size: 1.14vw;
                }
                @include pc_ls {
                    font-size: 16px;
                }
            }
        }

        &__input {
            display: flex;
            flex-direction: column;
            gap: 2rem 0;
            @include pc {
                align-items: center;
            }
            @include pc_ls {
                gap: 20px 0;
            }
            &__child {
                display: flex;
                align-items: center;
                //gap: 0 2.6rem;
                background: #EEF8E2;
                border-radius: 1rem;
                height: 6rem;
                border: solid 1px rgba($color: #65C100, $alpha: .2);
                @include pc {
                    height: 4.29vw;
                    width: 53.43vw;
                }
                @include pc_ls {
                    height: 60px;
                    width: 748px;
                }
                .txt {
                    transform: rotate(0.05deg);
                    position: relative;
                    color: #65C100;
                    font-size: 1.3rem;
                    padding: 0 2rem;
                    width: 35%;
                    &::after {
                        content: "";
                        display: block;
                        width: 1px;
                        height: 4rem;
                        background: #65C100;
                        position: absolute;
                        right: 1rem;
                        top: -1.3rem;
                    }
                    @include pc {
                        width: 30%;
                        font-size: 1.14vw;
                        padding: 0 3.93vw;
                        &::after {
                            height: 2.86vw;
                            right: 2.86vw;
                            top: -0.57vw;
                        }
                    }
                    @include pc_ls {
                        font-size: 16px;
                        padding: 0 55px;
                        &::after {
                            height: 40px;
                            right: 40px;
                            top: -8px;
                        }
                    }
                }
                .frame {
                    width: 65%;
                    input[type="text"] {
                        border: none;
                        background: #EEF8E2 !important;
                        border-radius: 3px;
                        padding: 3px;
                        width: 20rem;
                        font-size: medium;
                        &::placeholder {
                            color: #D3D3D3;
                        }
                    }
                    input[type="password"] {
                        border: none;
                        background: #EEF8E2 !important;
                        border-radius: 3px;
                        padding: 3px;
                        width: 20rem;
                        font-size: medium;
                        &::placeholder {
                            color: #D3D3D3;
                        }
                    }
                    @include pc {
                        input[type="text"] {
                            width: 100%;
                            font-size: 1.14vw;
                        }
                        input[type="password"] {
                            width: 100%;
                            font-size: 1.14vw;
                        }
                    }
                    @include pc_ls {
                        input[type="text"] {
                            width: 500px;
                            font-size: 16px;
                        }
                        input[type="password"] {
                            width: 500px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &__forgetmenot {
            transform: rotate(0.05deg);
            margin: 3rem auto 0;
            text-align: center;
            @include pc {
                margin: 2.86vw auto 0;
            }
            @include pc_ls {
                margin: 40px auto 0;
            }
            input[type="checkbox"] {
                position: relative;
                width: 16px;
                height: 16px;
                border: 1px solid rgba($color: #65C100, $alpha: .2);
                background: #EEF8E2;
                vertical-align: -2px;
                border-radius: 4px;
                -webkit-appearance: none;
                -moz-appearance: none;
                        appearance: none;
            }
            input[type="checkbox"]:checked:before {
                position: absolute;
                top: 1px;
                left: 4px;
                transform: rotate(50deg);
                width: 5px;
                height: 9px;
                border-right: 2px solid #65C100;
                border-bottom: 2px solid #65C100;
                content: '';
            }
        }

        &__btn {
            transform: rotate(0.05deg);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6rem;
            background: #0D7100 !important;
            border: none !important;
            color: #fff !important;
            text-decoration: none;
            border-radius: 50px !important;
            font-size: 1.4rem;
            margin: 3rem auto 0;
            &::after {
                content: "";
                display: block;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 1px;
                border: 2px solid;
                border-color: #fff #fff transparent transparent !important;
                transform: rotate(45deg);
                position: absolute;
                right: 3rem;
            }
            input[type="submit"] {
                color: #fff !important;
            }

            @include pc {
                width: 25.43vw;
                height: 4.64vw;
                border-radius: 50px;
                font-size: 1.29vw;
                margin: 0 auto;
                transition: 0.3s;
                margin: 2.86vw auto 0;
                &::after {
                    width: 0.57vw;
                    height: 0.57vw;
                    right: 2vw;
                }
                &:hover {
                    background: #fff !important;
                    border: solid 1px #0D7100 !important; 
                    color: #0D7100 !important;
                    &::after {
                        border-color: #0D7100 #0D7100 transparent transparent !important;
                    }
                    input[type="submit"] {
                        color: #0D7100 !important;
                    }
                }
            }
            @include pc_ls {
                width: 356px;
                height: 65px;
                font-size: 18px;
                margin: 40px auto 0;
                &::after {
                    width: 8px;
                    height: 8px;
                    right: 25px;
                }
            }

            button[type="submit"] {
                border: none;
                border-color: transparent;
                border-radius: 0;
                background: no-repeat;
                line-height: 1;
                padding: 0;
                font-size: 1.4rem;
                width: 100%;
                height: 6rem;
                border-radius: 50px !important;
                color: #fff !important;
                @include pc {
                    font-size: 1.29vw;
                    width: 25.43vw;
                    height: 4.64vw;
                    &:hover {
                        color: #0D7100 !important;
                    }
                }
                @include pc_ls {
                    font-size: 18px;
                    width: 356px;
                    height: 65px;
                }
            }
        }

        &__annotation {
            transform: rotate(0.05deg);
            color: #AAAAAA;
            text-indent: -1em;
            padding: 0 0 0 1em;
            line-height: 2;
            font-size: 1.1rem;
            margin: 3rem 0 0;
            @include pc {
                text-align: center;
                font-size: 12px;
                margin: 3rem 0 0;
            }
            @include pc_ls {
                text-align: center;
                font-size: 12px;
                margin: 40px 0 0;
            }
        }
    }

    &__ttl {
        transform: rotate(0.05deg);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EEF8E2;
        border-bottom: solid 3px #0D7100;
        color: #0D7100;
        border-radius: 1rem 1rem 0 0;
        margin: 9rem 0 0;
        height: 5.3rem;
        font-size: 1.4rem;
        @include pc {
            border-radius: 0.71vw 0.71vw 0 0;
            margin: 12.14vw 0 0;
            height: 4.5vw;
            font-size: 1.29vw;
        }
        @include pc_ls {
            border-radius: 10px 10px 0 0;
            margin: 170px 0 0;
            height: 63px;
            font-size: 18px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        &__child {
            padding: 2rem 0;
            border-bottom: solid 1px #D3D3D3;
            &:first-child { border-top: solid 1px #D3D3D3;}
            @include pc {
                padding: 2.14vw 0;
            }
            @include pc_ls {
                padding: 30px 0;
            }
            &__link {
                @include pc {
                    display: flex;
                    align-items: center;
                }
                .list_wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .date {
                        transform: rotate(0.05deg);
                        font-size: 1.3rem;
                        @include pc {
                            margin: 0 0 0 2.86vw;
                            font-size: 1.14vw;
                        }
                        @include pc_ls {
                            margin: 0 0 0 40px;
                            font-size: 16px;
                        }
                    }
                    .term {
                        transform: rotate(0.05deg);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #EEF8E2;
                        color: #65C100;
                        border-radius: 50px;
                        width: 14rem;
                        height: 2rem;
                        font-size: 1.2rem;
                        @include pc {
                            margin:  0 0 0 4.29vw;
                            width: 11.43vw;
                            height: 2.07vw;
                            font-size: 1vw;
                        }
                        @include pc_ls {
                            margin:  0 0 0 60px;
                            width: 160px;
                            height: 29px;
                            font-size: 14px;
                        }
                    }
                }
                .link {
                    transform: rotate(0.05deg);
                    line-height: 1.5;
                    display: inline-block;
                    margin: 1.4rem 0 0;
                    font-size: 1.3rem;
                    &:visited {
                        color: #65C100 !important;
                    }
                    @include pc {
                        transition: .3s;
                        margin: 0 0 0 2.5vw;
                        font-size: 1.14vw;
                        &:hover {
                            opacity: .6;
                            color: #65C100;
                        }
                    }
                    @include pc_ls {
                        margin: 0 0 0 35px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &__logout_btn {
        margin: 5rem auto 0;
        @include pc {
            margin: 80px auto 0;
        }
    }
}


//メンバーページ記事詳細
.member_detail {
    margin: 9rem 0 0;
    @include pc {
        margin: 13.57vw 0 0;
    }
    @include pc_ls {
        margin: 190px 0 0;
    }

    &__ttl {
        transform: rotate(0.05deg);
        margin: 6rem 0 0;
        line-height: 1.5;
        font-size: 2.6rem;
        padding: 0 0 2rem;
        border-bottom: solid 1px #D3D3D3;
        @include pc {
            max-width: 53.43vw;
            margin: 8.57vw auto 0;
            font-size: 2.57vw;
            padding: 0 0 2.5vw;
        }
        @include pc_ls {
            max-width: 748px;
            margin: 120px auto 0;
            font-size: 36px;
            padding: 0 0 35px;
        }
    }
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0 0;
        @include pc {
            max-width: 53.43vw;
            margin: 1.43vw auto 0;
            justify-content: flex-start;
            gap: 0 3.93vw;
        }
        @include pc_ls {
            max-width: 748px;
            margin: 20px auto 0;
            gap: 0 55px;
        }
        .date {
            transform: rotate(0.05deg);
            font-size: 1.3rem;
            @include pc {
                font-size: 1.14vw;
            }
            @include pc_ls {
                font-size: 16px;
            }
        }
        .category {
            transform: rotate(0.05deg);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #EEF8E2;
            color: #65C100;
            border-radius: 50px;
            width: 14rem;
            height: 2rem;
            font-size: 1.2rem;
            @include pc {
                width: 11.43vw;
                height: 2.07vw;
                font-size: 1vw;
            }
            @include pc_ls {
                width: 160px;
                height: 29px;
                font-size: 14px;
            }
        }
    }

    &__contents {
        margin: 6rem 0 0;
        padding: 0 0 6rem;
        border-bottom: solid 1px #D3D3D3;
        @include pc {
            max-width: 53.43vw;
            margin: 8.57vw auto 0;
        }
        @include pc_ls {
            max-width: 748px;
            margin: 120px auto 0;
            padding: 0 0 120px;
        }

        h1 {
            transform: rotate(0.05deg);
            line-height: 1.5;
            margin: 4rem 0 0;
            font-size: 2rem;
            &:first-child { margin: 0;}
            @include pc {
                margin: 4.29vw 0 0;
                font-size: 2vw;
            }
            @include pc_ls {
                margin: 60px 0 0;
                font-size: 28px;
            }
        }
        h2 {
            transform: rotate(0.05deg);
            line-height: 1.5;
            margin: 4rem 0 0;
            font-size: 1.8rem;
            &:first-child { margin: 0;}
            @include pc {
                margin: 1.79vw 0 0;
                font-size: 2vw;
            }
            @include pc_ls {
                margin: 60px 0 0;
                font-size: 25px;
            }
        }
        h3 {
            transform: rotate(0.05deg);
            line-height: 1.5;
            margin: 4rem 0 0;
            font-size: 1.5rem;
            &:first-child { margin: 0;}
            @include pc {
                margin: 1.64vw 0 0;
                font-size: 2vw;
            }
            @include pc_ls {
                margin: 60px 0 0;
                font-size: 23px;
            }
        }

        p {
            transform: rotate(0.05deg);
            line-height: 2;
            margin: 1rem 0 0;
            font-size: 1.3rem;
            @include pc {
                margin: 1.43vw 0 0;
                font-size: 1.14vw;
            }
            @include pc_ls {
                margin: 20px 0 0;
                font-size: 16px;
            }
        }
        .annotation {
            transform: rotate(0.05deg);
            position: relative;
            color: #AAAAAA;
            line-height: 2;
            margin: 1rem 0 0;
            font-size: 1.1rem;
            padding: 0 0 0 1.2em;
            &::before {
                content: "※";
                display: block;
                position: absolute;
                left: 0;
            }
            @include pc {
                margin: 0.86vw 0 0;
                font-size: 1.14vw;
            }
            @include pc_ls {
                margin: 20px 0 0;
                font-size: 12px;
            }
        }

        img {
            display: block;
            margin: 4rem 0 0;
            &:first-child { margin: 0 auto;}
            @include pc {
                width: 39.43vw;
                margin: 1.43vw auto 0;
            }
            @include pc_ls {
                width: 552px;
                margin: 20px auto 0;
            }
        }

        a {
            transform: rotate(0.05deg);
            position: relative;
            display: inline-block;
            color: #65C100;
            padding: 0 0 0 1.6rem;
            font-size: 1.4rem;
            &::before {
                content: "";
                display: block;
                width: .8rem;
                height: .8rem;
                border: 2px solid;
                border-color: #65C100 #65C100 transparent transparent;
                transform: rotate(45deg);
                position: absolute;
                left: 0;
                top: 35%;
            }
            &:visited {
                color: #65C100;
            }
            @include pc {
                padding: 0 0 0 1.43vw;
                font-size: 1.29vw;
                &::before {
                    width: 0.57vw;
                    height: 0.57vw;
                    top: 40%;
                }
            }
            @include pc_ls {
                padding: 0 0 0 20px;
                font-size: 18px;
                &::before {
                    width: 8px;
                    height: 8px;
                    top: 40%;
                }
            }
        }

        ul {
            margin: 4rem 0 0;
            &:first-child { margin: 0;}
            @include pc {
                margin: 4.29vw 0 0;
            }
            @include pc_ls {
                margin: 60px 0 0;
            }
            li {
                transform: rotate(0.05deg);
                position: relative;
                line-height: 2;
                font-size: 1.3rem;
                margin: 0 0 1rem;
                padding-left: 1.3em;
                &:last-child { margin: 0; }
                &::before {
                    content: "";
                    display: block;
                    width: .8rem;
                    height: .8rem;
                    background: #65C100;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 20%;
                }
                @include pc {
                    font-size: 1.14vw;
                    margin: 0 0 0.71vw;
                    padding-left: 1.21vw;
                    &::before {
                        width: 0.71vw;
                        height: 0.71vw;
                        top: 35%;
                    }
                }
                @include pc_ls {
                    font-size: 16px;
                    margin: 0 0 10px;
                    padding-left: 17px;
                    &::before {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        ol {
            margin: 4rem 0 0;
            &:first-child { margin: 0;}
            counter-reset: item;
            list-style-type: none;
            @include pc {
                margin: 4.29vw 0 0;
            }
            @include pc_ls {
                margin: 60px 0 0;
            }
            li {
                transform: rotate(0.05deg);
                line-height: 2;
                font-size: 1.3rem;
                margin: 0 0 1rem;
                text-indent: -1.3em;
                padding-left: 1.3em;
                &:last-child { margin: 0; }
                &:before {
                    counter-increment: item;
                    content: counter(item)'.';
                    padding-right: .5em;
                    font-weight: bold;
                    color: #65C100;
                }
                @include pc {
                    font-size: 1.14vw;
                    margin: 0 0 0.71vw;
                }
                @include pc_ls {
                    font-size: 16px;
                    margin: 0 0 10px;
                }
            }
        }
    }

    &__next_prev_btn {
        display: flex;
        justify-content: center;
        gap: 0 6rem;
        margin: 2rem 0 0;
        @include pc {
            gap: 0 8.57vw;
            margin: 2.14vw 0 0;
        }
        @include pc_ls {
            gap: 0 120px;
            margin: 30px 0 0;
        }
        &__child {
            position: relative;
            a {
                text-decoration: none;
                display: flex;
                text-decoration: none;
                align-items: center;
                gap: 0 1rem;
                transition: .3s;
                @include pc {
                    gap: 0 0.71vw;
                }
                @include pc_ls {
                    gap: 0 10px;
                }
                &:visited {
                    color: #65C100 !important;
                }
                &:hover {
                    .circle_next {
                        background: #fff;
                        border: solid 1px #65C100;
                        &::before {
                            border-color: transparent transparent #65C100 #65C100;
                        }
                    }
                    .circle_prev {
                        background: #fff;
                        border: solid 1px #65C100;
                        &::before {
                            border-color: #65C100 #65C100 transparent transparent;
                        }
                    }
                }
            }
            &:first-child {
                a {
                    &::after {
                        content: "";
                        display: block;
                        width: 1px;
                        height: 2rem;
                        background: #D3D3D3;
                        position: absolute;
                        right: -3rem;
                        @include pc {
                            height: 2.14vw;
                            right: -4.29vw;
                        }
                        @include pc_ls {
                            height: 30px;
                            right: -60px;
                        }
                    }
                }
            }
            .txt {
                transform: rotate(0.05deg);
                font-size: 1.3rem;
                @include pc {
                    font-size: 1.29vw;
                }
                @include pc_ls {
                    font-size: 18px;
                }
            }
            .pic {
                width: 4rem;
                @include pc {
                    width: 3.57vw;
                }
                @include pc_ls {
                    width: 50px;
                }
            }
            .circle_next {
                position: relative;
                transition: .3s;
                display: block;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background: #65C100;
                &::before {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 2px solid;
                    border-radius: 2px;
                    border-color: transparent transparent #fff #fff;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 40%;
                    right: 35%;
                }
                @include pc {
                    width: 3.57vw;
                    height: 3.57vw;
                    &::before {
                        width: 0.57vw;
                        height: 0.57vw;
                    }
                }
                @include pc_ls {
                    width: 50px;
                    height: 50px;
                    &::before {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
            .circle_prev {
                position: relative;
                display: block;
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                background: #65C100;
                &::before {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 2px solid;
                    border-radius: 2px;
                    border-color: #fff #fff transparent transparent;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 40%;
                    right: 43%;
                }
                @include pc {
                    width: 3.57vw;
                    height: 3.57vw;
                    &::before {
                        width: 0.57vw;
                        height: 0.57vw;
                    }
                }
                @include pc_ls {
                    width: 50px;
                    height: 50px;
                    &::before {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    &__btn {
        display: flex;
        flex-direction: column;
        margin: 6rem 0 0;
        @include pc {
            margin: 8.57vw 0 0;
            flex-direction: row;
            justify-content: center;
            gap: 0 2.14vw;
        }
        @include pc {
            margin: 120px 0 0;
            gap: 0 30px;
        }
        &__child {
            &:last-child {
                margin: 2rem 0 0;
            }
            @include pc {
                margin: 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &__login_btn {
        
        &__wrap {
            margin: 12rem 0 0;
            @include pc {
                margin: 8.57vw 0 0;
            }
            @include pc {
                margin: 220px 0 0;
            }
        }

        &__btn {
            display: flex;
            flex-direction: column;
            @include pc {
                margin: 8.57vw 0 0;
                flex-direction: row;
                justify-content: center;
                gap: 0 2.14vw;
            }
            @include pc {
                margin: 120px 0 0;
                gap: 0 30px;
            }
        }

        &__txt {
            transform: rotate(0.05deg);
            text-align: center;
            font-size: 1.2rem;
            @include pc {
                font-size: 1.43vw;
            }
            @include pc {
                font-size: 20px;
            }
        }

        &__child {
            &:last-child {
                margin: 2rem 0 0;
            }
            @include pc {
                margin: 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}