@use "foundation" as *;

/*-----------------------------------------------------------
    pc menu
-----------------------------------------------------------*/
@include pc {
    .sp-tab_menu { display: none;}
    .pc_menu {
        display: block;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &__logo {
                width: 17.14vw;
                a {
                    transition: opacity 0.3s;
                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
            &__menu {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                &__top {
                    &__list {
                        display: flex;
                        align-items: center;
                        &__link {
                            margin: 0 30px 0 0;
                            &:last-child { margin: 0;}
                            .link {
                                display: inline-block;
                                transform: rotate(0.05deg);
                                text-decoration: none;
                                transition: opacity 0.3s;
                                &:hover {
                                    opacity: 0.5;
                                }
                                &.contact {
                                    color: #69BA00;
                                    font-size: 1vw;
                                    &.icon_link {
                                        display: flex;
                                        align-items: center;
                                    }
                                    .mail_icon {
                                        width: 1.43vw;
                                        display: inline-block;
                                        margin: 0 0.71vw 0 0;
                                    }
                                }
                                &.logout {
                                    color: #69BA00;
                                    font-size: 1vw;
                                }
                                &.member {
                                    background: #0D7100;
                                    color: #fff;
                                    padding: 10px 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 0 0 10px 10px;
                                    font-size: 0.93vw;
                                }
                            }
                        }
                    }
                }
                &__bottom {
                    margin: 20px 0 0;
                    &__list {
                        display: flex;
                        padding: 0 0 20px;
                        &__link {
                            margin: 0 30px 0 0;
                            &:last-child { margin: 0;}
                            .link {
                                display: inline-block;
                                transform: rotate(0.05deg);
                                color: #464646;
                                text-decoration: none;
                                transition: color 0.3s;
                                font-size: 1vw;
                                &:hover {
                                    color: #65C100;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include pc-ls {
    .pc_menu {
        &__content {
            &__logo {
                width: 240px;
            }
            &__menu {
                &__top {
                    &__list {
                        &__link {
                            .link {
                                &.contact {
                                    font-size: 14px;
                                    .mail_icon {
                                        width: 20px;
                                        margin: 0 10px 0 0;
                                    }
                                }
                                &.member {
                                    padding: 10px 20px;
                                    font-size: 13px;
                                }
                                &.logout {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                &__bottom {
                    &__list {
                        &__link {
                            .link {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*-----------------------------------------------------------
    sp-tab menu
-----------------------------------------------------------*/
@include sp-tab {
    .pc_menu { display: none;}

    .sp-tab_menu {
        display: block;
        &__top {
            display: flex;
            align-items: center;
            position: fixed;
            top: 0;
            background: #fff;
            height: 5rem;
            width: 100%;
            z-index: 100;
            padding: 0 3rem;
            &__logo {
                width: 19rem;
            }
        }
    }



    .menu-checkbox {
        display: none;
    }
    .drawer-menu {
        position: fixed;
        padding: 5rem 0 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transform: translateX(100%);
        transition: all 0.5s ease-in-out 0s;
        background-color: #65C100;
        z-index: 20;
        overflow: auto;
        visibility: hidden;
    }

    .drawer-menu-list {
        display: flex;
        flex-direction: column;
    }

    .drawer-menu-item {
        padding: 2rem;
        border-bottom: solid 1px #fff;
    }

    .drawer-menu__link_btn {
        margin: 2rem auto 0;
        padding: 0 0 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__link {
            margin: 0 0 2rem;
            &:last-child { margin: 0; }

            a {
                transform: rotate(0.05deg);
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                width: 31.5rem;
                height: 6rem;
                border-radius: 50px;
                font-size: 1.4rem;
            }
            .contact {
                background: #fff;
                color: #65C100;
                border: solid 1px #65C100;
                .mail_icon {
                    display: inline-block;
                    width: 2rem;
                    margin: 0 1rem 0 0;
                }
            }
            .member {
                background: #0D7100;
                color: #fff;
            }
        }
    }

    .drawer-menu-item-link {
        transform: rotate(0.05deg);
        display: block;
        text-align: center;
        font-size: 1.8rem;
        color: #fff;
        text-decoration: none;
        &:visited { color: #fff; }
        .english_ttl {
            transform: rotate(0.05deg);
            color: #0D7100;
            padding: 0 0 0.7rem;
            display: inline-block;
            font-size: 1.3rem;
        }
    }

    .menu-checkbox:checked ~ .drawer-menu {
        transform: translateX(0);
        visibility: visible;
    }
    .drawer-icon {
        cursor: pointer;
        position: fixed;
        top: 1.5rem;
        right: 2rem;
        width: 4rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        z-index: 101;
        background-color: #fff;
    }

    .drawer-icon span,
    .drawer-icon span:before,
    .drawer-icon span:after {
        content: '';
        display: block;
        height: 2px;
        width: 4rem;
        border-radius: 3px;
        background-color: #65C100;
        position: absolute;
        transition: all 0.5s ease-in-out 0s;
    }

    .drawer-icon span {
        background-color: rgba(255, 255, 255, 0);
    }

    .drawer-icon span::before {
        bottom: -0.5rem;
    }

    .drawer-icon span::after {
        top: 1.5rem;
    }

    .menu-checkbox:checked ~ .drawer-icon {
        background-color: transparent;
    }

    .menu-checkbox:checked ~ .drawer-icon span {
        background-color: rgba(255, 255, 255, 0);
    }

    .menu-checkbox:checked ~ .drawer-icon span::before {
        bottom: -0.7rem;
        transform: rotate(-25deg);
        background-color: #65C100;
    }

    .menu-checkbox:checked ~ .drawer-icon span::after {
        top: 0.7rem;
        transform: rotate(25deg);
        background-color: #65C100;
    }


}
@include tab {
    .sp-tab_menu__top {
        height: 60px;
        padding: 0 9vw;
    }
    .sp-tab_menu__top__logo {
        width: 19.1vw;
    }

    .drawer-menu {
        padding: 60px 0 0;
    }
    .drawer-menu-item {
        padding: 0 0 4vw;
        border-bottom: none;
        width: 50%;
        &:last-child {
            padding: 0;
        }
    }
    .drawer-menu-list {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5vw 9vw 0;
    }
    .drawer-menu-item-link {
        text-align: left;
        font-size: 1.8vw;
        .english_ttl {
            padding: 0 0 0.7vw;
            font-size: 1.2vw;
        }
    }

    .drawer-menu__link_btn {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 50px auto 0;
        padding: 0 9vw;
    }
    .drawer-menu__link_btn__link {
        margin: 0 0 0 0;
        width: 50%;
        a {
            width: 25.2vw;
            height: 46px;
            border-radius: 50px;
            font-size: 1.3vw;
        }
        .contact {
            .mail_icon {
                display: inline-block;
                width: 1.43vw;
                margin: 0 0.71vw 0 0;
            }
        }
    }

    .drawer-icon {
        width: 4vw;
        height: 2vw;
        top: 20px;
        right: 70px;
    }

    .drawer-icon span, .drawer-icon span:before, .drawer-icon span:after {
        height: 2px;
        width: 4vw;
    }

    .drawer-icon span::before {
        bottom: -0.5vw;
    }
    .drawer-icon span::after {
        top: 1.5vw;
    }
}
@include sp {
    .pc_menu { display: none;}

    .sp-tab_menu {
        display: block;
        &__top {
            display: flex;
            align-items: center;
            position: fixed;
            top: 0;
            background: #fff;
            height: 5rem;
            width: 100%;
            z-index: 100;
            padding: 0 3rem;
            &__logo {
                width: 19rem;
            }
        }
    }



    .menu-checkbox {
        display: none;
    }
    .drawer-menu {
        position: fixed;
        padding: 5rem 0 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transform: translateX(100%);
        transition: all 0.5s ease-in-out 0s;
        background-color: #65C100;
        z-index: 20;
        overflow: auto;
        visibility: hidden;
    }

    .drawer-menu-list {
        display: flex;
        flex-direction: column;
    }

    .drawer-menu-item {
        padding: 2rem;
        border-bottom: solid 1px #fff;
    }

    .drawer-menu__link_btn {
        margin: 2rem auto 0;
        padding: 0 0 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__link {
            margin: 0 0 2rem;
            &:last-child { margin: 0; }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                width: 31.5rem;
                height: 6rem;
                border-radius: 50px;
                font-size: 1.4rem;
            }
            .contact {
                background: #fff;
                color: #65C100;
                border: solid 1px #65C100;
                .mail_icon {
                    display: inline-block;
                    width: 2rem;
                    margin: 0 1rem 0 0;
                }
            }
            .member {
                background: #0D7100;
                color: #fff;
            }
        }
    }

    .drawer-menu-item-link {
        display: block;
        text-align: center;
        font-size: 1.8rem;
        color: #fff;
        text-decoration: none;
        &:visited { color: #fff; }
        .english_ttl {
            color: #0D7100;
            padding: 0 0 0.7rem;
            display: inline-block;
            font-size: 1.3rem;
        }
    }

    .menu-checkbox:checked ~ .drawer-menu {
        transform: translateX(0);
        visibility: visible;
    }
    .drawer-icon {
        cursor: pointer;
        position: fixed;
        top: 1.5rem;
        right: 2rem;
        width: 4rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        z-index: 101;
        background-color: #fff;
    }

    .drawer-icon span,
    .drawer-icon span:before,
    .drawer-icon span:after {
        content: '';
        display: block;
        height: 2px;
        width: 4rem;
        border-radius: 3px;
        background-color: #65C100;
        position: absolute;
        transition: all 0.5s ease-in-out 0s;
    }

    .drawer-icon span {
        background-color: rgba(255, 255, 255, 0);
    }

    .drawer-icon span::before {
        bottom: -0.5rem;
    }

    .drawer-icon span::after {
        top: 1.5rem;
    }

    .menu-checkbox:checked ~ .drawer-icon {
        background-color: transparent;
    }

    .menu-checkbox:checked ~ .drawer-icon span {
        background-color: rgba(255, 255, 255, 0);
    }

    .menu-checkbox:checked ~ .drawer-icon span::before {
        bottom: -0.7rem;
        transform: rotate(-25deg);
        background-color: #65C100;
    }

    .menu-checkbox:checked ~ .drawer-icon span::after {
        top: 0.7rem;
        transform: rotate(25deg);
        background-color: #65C100;
    }


}