@use "foundation" as *;

.all_wrapper {
  overflow: hidden;
}

.l-container {
  margin-right: 3rem;
  margin-left: 3rem;
  @include pc {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
