@use "foundation" as *;

.action {
    &__list {
        margin: 8rem 0 0;
        @include pc {
            margin: 10.71vw 0 0;
        }
        @include pc_ls {
            margin: 150px 0 0;
        }
        &__child {
            margin: 0 0 4rem;
            @include pc {
                display: flex;
                margin: 0 0 4.29vw;
            }
            @include pc_ls {
                margin: 0 0 60px;
            }
            &:last-child { margin: 0; }
            &__pic {
                @include pc {
                    width: 25.43vw;
                }
                @include pc_ls {
                    width: 356px;
                }
            }
            &__txt {
                margin: 2rem 0 0;
                @include pc {
                    margin: 0 0 0 2.57vw;
                    width: 70%;
                }
                @include pc_ls {
                    margin: 0 0 0 36px;
                }
                .ttl {
                    transform: rotate(0.05deg);
                    font-size: 2rem;
                    @include pc {
                        line-height: 1.5;
                        font-size: 2vw;
                    }
                    @include pc_ls {
                        font-size: 28px;
                    }
                }
                .txt {
                    transform: rotate(0.05deg);
                    line-height: 2;
                    font-size: 1.3rem;
                    margin: 1rem 0 0;
                    @include pc {
                        font-size: 1.14vw;
                        margin: 1.79vw 0 0;
                    }
                    @include pc_ls {
                        font-size: 16px;
                        margin: 25px 0 0;
                    }
                }
                .action_link {
                    transform: rotate(0.05deg);
                    position: relative;
                    display: inline-block;
                    padding: 0 0 0 1.6rem;
                    margin: 2rem 0 0;
                    font-size: 1.4rem;
                    color: #69BA00;
                    &::before {
                        content: "";
                        display: block;
                        width: .8rem;
                        height: .8rem;
                        border: 2px solid;
                        border-color: #69BA00 #69BA00 transparent transparent;
                        transform: rotate(45deg);
                        position: absolute;
                        left: 0;
                        top: 0.3rem;
                    }
                    @include pc {
                        padding: 0 0 0 1.43vw;
                        margin: 2.14vw 0 0;
                        font-size: 1.29vw;
                        &::before {
                            width: 0.57vw;
                            height: 0.57vw;
                            top: 30%;
                        }
                    }
                    @include pc_ls {
                        padding: 0 0 0 20px;
                        margin: 30px 0 0;
                        font-size: 18px;
                        &::before {
                            width: 8px;
                            height: 8px;
                            top: 30%;
                        }
                    }
                }
            }
        }
    }
}